import { PlusIcon } from '@heroicons/react/20/solid'
import React, {useState} from "react";
import { createSessionWithoutDispatch } from "../sessioncreator/SessionCreatorActions";
import {Button} from "../primitives/button";
import {CompanyConfigContext} from "../../CompanyConfigContextProvider";
import {Modal} from "../primitives/modal";
import errorIcon from "../../assets/error-icon.svg";
import {useNavigate} from "react-router-dom";

interface PlaceholderMetricsProps {
    handleSkip: () => void;
}

const PlaceholderMetrics: React.FC<PlaceholderMetricsProps> = (props: PlaceholderMetricsProps) => {
    const companyConfig = React.useContext(CompanyConfigContext);
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false)
    const navigate = useNavigate();

    return (
        <>
            <Modal
                open={isWarningModalOpen}
                onClose={setIsWarningModalOpen}
                className={"absolute top-[60px] left-1/2 transform -translate-x-1/2 max-w-[400px] rounded-0.625rem p-0 overflow-y-auto max-h-calc(100vh - 120px)"}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className={"p-4 "}>
                    <div className={"flex flex-row items-start mb-5"}>
                        <div className={"shrink min-h-6 min-w-6 pt-0.5 mr-2.5"}><img alt={"erroricon"}
                                                                                     src={`${errorIcon}`}/></div>
                        <p className={"text-xl font-medium"}>Are you sure you want to skip your initial sessions?</p>
                    </div>
                    <div className={"text-slate-600 text-xs mb-4"}>
                        If you skip these sessions, your recommendations may take longer to calibrate. Additionally, your dashboard metrics may initially be incomplete.
                    </div>
                    <div className={"flex flex-row justify-end gap-2"}>
                        <Button plain onClick={() => setIsWarningModalOpen(false)}> Cancel
                        </Button>
                        <Button
                            color={"dangerAngry"}
                            onClick={props.handleSkip}
                        >Confirm
                        </Button>
                    </div>
                </div>
            </Modal>
            <div className={"flex flex-col min-w-[692px] mt-6 mx-8"}>
                <div
                    className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <p className="mt-2 text-black text-2xl font-extrabold font-inter-tight">Your first session awaits!</p>
                    <p className="mt-2 text-stone-400 text-sm font-normal font-inter leading-tight">Your dashboard is eager to show your progress! But first, it needs some data. <br/>
                        Start answering questions and watch this space light up with your metrics.</p>
                    <div className="mt-6 flex flex-row gap-x-4 justify-center">
                        <Button
                            color={"primary"}
                            onClick={async () => {
                                await createSessionWithoutDispatch(navigate)
                                window.location.href = "/current_session"
                            }}
                            type="button"
                        >
                            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true"/>
                            Create session
                        </Button>
                        {companyConfig.allow_users_to_skip_placeholders &&
                            <Button color={"secondary"} onClick={()=>setIsWarningModalOpen(true)}>Skip initial sessions?</Button>
                        }
                    </div>
                </div>
            </div>
        </>
    )

}

export default PlaceholderMetrics;