import React, {useContext, useEffect, useState} from "react";
import SettingsPage from "../components/account/SettingsPage";
import axios from "axios";
import LoadingBar from "../components/primitives/LoadingBar";
import {Button} from "../components/primitives/button";
import {Modal} from "../components/primitives/modal";
import {CompanyConfigContext} from "../CompanyConfigContextProvider";
import {
    BoltIcon,
    BookOpenIcon,
    ChatBubbleOvalLeftEllipsisIcon,
    ClockIcon, LinkIcon, LockClosedIcon, MapPinIcon,
    PencilIcon,
    PlayIcon,
    Squares2X2Icon
} from "@heroicons/react/16/solid";
import useRedirectToPurchasePage from "../utils/useRedirectToPurchasePage";
import PoweredByHatchery from "../components/fixedelements/PoweredByHatchery";

function getRemainingQuestionCredits(setRemainingQuestionCredits: React.Dispatch<React.SetStateAction<number>>) {
    axios.get("/user_remaining_question_credits")
        .then(response => {
            if (response.data.question_credits > 100) {
                setRemainingQuestionCredits(100)
            } else {
                setRemainingQuestionCredits(response.data.question_credits)
            }
        }).catch(error => {
            console.log(error)
    });
}

function getUserSubscriptionDetails(setSubscriptionStatus: React.Dispatch<React.SetStateAction<boolean | null>>, setRemainingQuestionCredits: React.Dispatch<React.SetStateAction<number>>) {
    axios.get("/user_has_active_subscription_request")
        .then(response => {
            if (response.data.has_active_subscription === true) {
                setSubscriptionStatus(true);
            } else {
                getRemainingQuestionCredits(setRemainingQuestionCredits);
                setSubscriptionStatus(false);
            }
            getRemainingQuestionCredits(setRemainingQuestionCredits);
        }).catch(error => {
            console.log(error)
    });
}


function Sidebar() {
    const [showSettings, setShowSettings] = useState(false);
    // Todo: unify this with the navbar call so it isnt done twice
    const [subscriptionStatus, setSubscriptionStatus] = useState<boolean>(null);
    const [remainingQuestionCredits, setRemainingQuestionCredits] = useState(0);
    const companyConfig = useContext(CompanyConfigContext);

    useEffect(() => {
        getUserSubscriptionDetails(setSubscriptionStatus, setRemainingQuestionCredits);
    }, []);

    const navigation = [
        {
            name: 'Dashboard',
            href: '/dashboard',
            icon: <Squares2X2Icon className={"size-4"}/>,
            current: true,
            comingSoon: false,
            premium: false,
        },
        {name: 'Writer', href: '/writing_tool/vault', icon: <PencilIcon className={"size-4"}/>, current: false, comingSoon: false, premium: companyConfig.premium_only_writing_tool,},
        {name: 'Exams', href: '/exams', icon: <ClockIcon className={"size-4"}/>, current: false, comingSoon: false, premium: false,},
        {
            name: 'Videos',
            href: '/content_portal',
            icon: <PlayIcon className={"size-4"}/>,
            current: false,
            comingSoon: false,
            premium: false,
        },
        // { name: 'Blueprint', href: '/blueprint', icon: blueprintIcon, iconActive: blueprintIcon, iconDisabled: blueprintIconDisabled, current: false, comingSoon: true, premium: false, },

        // { name: 'Events', href: '/events', icon: eventIcon, iconActive: eventIcon, iconDisabled: eventsIconDisabled, current: false, comingSoon: true, premium: false, },
    ]
    if (!companyConfig.disable_community) {
        navigation.push(
            {
                name: 'Community',
                href: '/community',
                icon: <ChatBubbleOvalLeftEllipsisIcon className={"size-4"}/>,
                current: false,
                comingSoon: false,
                premium: false,
            },
        );
    }

    const footer = [
        { name: 'Invite a Friend', href: () => setShowSettings(true), icon: <LinkIcon className={"size-4"}/> },
    ];

    if (companyConfig.company_name === "Hatchery") {
        footer.push(
            {
                name: 'Roadmap', href: () => {
                    window.open("https://hatchery.canny.io", "_blank");
                }, icon: <MapPinIcon className={'size-4'}/>
            },
            {
                name: 'Blog', href: () => {
                    window.open("https://hatchery.com.au/blog", "_blank");
                }, icon: <BookOpenIcon className={'size-4'}/>
            }
        );
    }
    const currentPath = window.location.pathname;
    const redirectToPurchasePage = useRedirectToPurchasePage();

    return (
        <aside>
            <Modal open={showSettings} onClose={setShowSettings} size={"5xl"}
                   className="absolute top-[60px] left-1/2 transform -translate-x-1/2 rounded-lg p-0 overflow-y-auto max-h-calc(100vh - 120px)">
                <SettingsPage activeTab={1} setOpenModal={setShowSettings}/>
            </Modal>
            <div className={`h-full w-[256px] flex flex-col pt-6 border-r border-borderOpaque`}>
                <div role={"list"} className={"flex-grow px-2 pb-6 flex flex-col gap-y-1"}>
                    {navigation.map((item) => (
                        <div key={item.name}
                            className={`
                            ${((item.premium && subscriptionStatus === false) || item.comingSoon) ? (item.comingSoon ? "text-contentStateDisabled" : "text-contentStateDisabled bg-backgroundStateDisabled") :
                                currentPath === item.href ?
                                    "text-contentPrimary bg-backgroundTertiary" :
                                    "text-contentPrimary cursor-pointer hover:bg-backgroundSecondary"}
                            flex flex-row rounded-lg px-2 py-1 group gap-x-1.5
                            text-sm font-normal font-Inter items-center
                        `}
                            onClick={() => !item.comingSoon && !(item.premium && subscriptionStatus === false) ? window.location.href = item.href : null}
                        >
                            <div
                                className={`flex items-center justify-center ${item.comingSoon || (item.premium && subscriptionStatus === false) ? 'text-contentStateDisabled' :
                                    currentPath === item.href
                                        ? 'text-contentStateDisabled group-hover:text-contentPrimary'
                                        : 'text-contentStateDisabled group-hover:text-contentPrimary'}`
                                }
                            >
                                {item.icon}
                            </div>
                            <div>{item.name}</div>
                            {item.premium && subscriptionStatus === false ? (
                                    <div className="ml-auto">
                                        <LockClosedIcon className={"size-4"}/>
                                    </div>
                                )
                                :
                                item.comingSoon && (
                                    <div className="ml-auto">
                                        <div className="bg-backgroundStateDisabled flex items-center px-1.5 rounded">
                                            <div className="text-contentStateDisabled font-SFPro font-bold text-3xs">
                                                COMING SOON
                                            </div>
                                        </div>
                                    </div>
                            )}
                        </div>
                    ))}
                </div>
                <div className={"flex-shrink mt-auto"}>
                    <div className={`px-2 ${subscriptionStatus === false ? 'block' : 'hidden' }`}>
                        <div
                            className={"mb-2 p-2 flex-col flex gap-y-3 font-inter rounded text-contentPrimary bg-backgroundTertiary"}>
                            <div className={"flex-col flex gap-3 rounded"}>
                                <div className={"flex flex-col p-2 rounded bg-backgroundPrimary"}>
                                    <div className={"grid grid-cols-2 items-center "}>
                                        <p className={"justify-start text-xs font-semibold flex"}>Free questions</p>
                                        <p className={"justify-end font-normal text-2xs flex"}>{remainingQuestionCredits} remaining</p>
                                    </div>
                                    <div className={"mt-1"}>
                                        <LoadingBar progress={remainingQuestionCredits} className={'bg-primaryA h-1.5'}/>
                                    </div>
                                </div>

                                <div className={"text-contentPrimary font-inter text-2xs"}>
                                    <div className={"flex-row flex items-center"}>
                                        <div className={"flex h-4 w-4 justify-center items-center"}>
                                            <BoltIcon className={'size-3'}/>
                                        </div>
                                        <div className={"flex-col flex"}>
                                            <p className={"font-semibold"}>Supercharge your preparation</p>
                                        </div>
                                    </div>
                                    <div className={"flex-row flex"}>
                                        <div className={"items-start flex h-4 w-4"}></div>
                                        <div className={"flex-col flex"}>
                                                <p>Unlock everything on {companyConfig.company_name}</p>
                                        </div>
                                    </div>
                                </div>
                                <Button className={"bg-backgroundPrimary"}
                                        outline
                                        onClick={redirectToPurchasePage}>
                                    <p className={"justify-start text-xs font-semibold flex"}>Upgrade</p>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={"border-t border-borderOpaque"}>
                        <div className={"px-2 pt-6 pb-3 flex flex-col gap-y-1"}>
                            {footer.map((item) => (
                                <div key={item.name}
                                    className={"group text-contentPrimary hover:bg-backgroundSecondary flex flex-row cursor-pointer " +
                                        "px-2 py-1 text-sm font-normal font-Inter items-center rounded-lg gap-x-1.5"}
                                    onClick={item.href}
                                >
                                    <div className={"text-contentStateDisabled group-hover:text-contentPrimary"}>
                                        {item.icon}
                                    </div>
                                    <div>{item.name}</div>
                                </div>
                            ))}
                            <div className={'flex justify-center items-baseline text-2xs pt-1'}>
                                <PoweredByHatchery/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    );
}

export default Sidebar;
