import axios from "axios";
import { toastOnError } from "../../utils/Utils";
import { ThunkAction } from "redux-thunk";
import { NavigateFunction } from "react-router-dom";

interface SessionDetails {
    topic: string;
    size: number;
    content_area: string;
    time_limit: number;
}

// eslint-disable-next-line
type ThunkResult<R> = ThunkAction<R, RootState, undefined, any>;

export const createSession = (
    sessionDetails: SessionDetails
): ThunkResult<Promise<boolean>> => async (dispatch) => {
    try {
        console.log(sessionDetails);
        const response = await axios.post("/api/v1/learningsessions/", sessionDetails);
        dispatch({
            type: "CREATE_SESSION",
            payload: response.data,
        });
        return true;
    } catch (error) {
        console.error("Error creating session:", error.response);
        if (error.response?.data?.detail) {
            console.error("Detail:", error.response.data.detail);
        } else if (error.response?.status === 404) {
            toastOnError("No questions found. Hatchery team has been notified");
        } else {
            toastOnError(error);
        }
        return false;
    }
};

const requestRecommendation = async () => {
    try {
        const response = await axios.get("/request_recommendation");
        return response.data.reason;
    } catch (error) {
        console.error("Error requesting recommendation:", error);
        toastOnError("Error requesting recommendation");
        return null;
    }
};

const applyRecommendation = async () => {
    try {
        await axios.get("/apply_recommendation");
    } catch (error) {
        console.error("Error applying recommendation:", error);
        toastOnError("Error applying recommendation");
    }
};

export const createSessionWithoutDispatch = async (navigate: NavigateFunction) => {
    const recommendation = await requestRecommendation();
    await applyRecommendation();
    console.log("Recommendation:", recommendation);
    navigate("/current_session"); // Directly use navigate here
};

export const goToActiveSession = (navigate: NavigateFunction) => {
    return (dispatch: never) => {

        navigate('/current_session');
    };
};
