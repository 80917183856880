import React, {
    createContext,
    useState,
    useContext,
    ReactNode,
    FC,
    useEffect,
    useMemo,
    ComponentType
} from 'react';

interface NavbarContextType {
    navbarContent: ReactNode;
    setNavbarContent: (content: ReactNode) => void;
    hideSidebar: boolean;
    setHideSidebar: (hide: boolean) => void;
}

interface SidebarVisibilityContextType {
    hideSidebar: boolean;
    setHideSidebar: (hide: boolean) => void;
}

type UseNavbarConfigBase = {
    hideOnUnmount?: boolean;
}

type UseNavbarConfigWithComponent<P = NonNullable<unknown>> = UseNavbarConfigBase & {
    component: ComponentType<P>;
    props?: P;
}

const NavbarContext = createContext<NavbarContextType>({
    navbarContent: null,
    setNavbarContent: () => {},
    hideSidebar: false,
    setHideSidebar: () => {}
});

const SidebarVisibilityContext = createContext<SidebarVisibilityContextType>({
    hideSidebar: false,
    setHideSidebar: () => {}
});

export const useNavbar = () => {
    const context = useContext(NavbarContext);
    if (!context) {
        throw new Error('useNavbar must be used within a NavbarProvider');
    }
    return context;
};

export const useSidebarVisibility = () => {
    const context = useContext(SidebarVisibilityContext);
    if (!context) {
        throw new Error('useSidebarVisibility must be used within a NavbarProvider');
    }
    return context;
};

export const useNavbarContent = <P extends object = NonNullable<unknown>>(
    config: UseNavbarConfigWithComponent<P>
) => {
    const { setNavbarContent } = useNavbar();

    const memoizedContent = useMemo(() => {
        const { component: Component, props = {} as P } = config;
        return <Component {...props} />;
    }, [config.component, config.props]);

    useEffect(() => {
        setNavbarContent(memoizedContent);
        return () => {
            if (config.hideOnUnmount !== false) {
                setNavbarContent(null);
            }
        };
    }, [memoizedContent, config.hideOnUnmount, setNavbarContent]);
};

interface NavbarProviderProps {
    children: ReactNode;
}

export const NavbarProvider: FC<NavbarProviderProps> = ({ children }) => {
    const [navbarContent, setNavbarContent] = useState<ReactNode>(null);
    const [hideSidebar, setHideSidebar] = useState(false);

    const navbarValue = useMemo(
        () => ({
            navbarContent,
            setNavbarContent,
            hideSidebar,
            setHideSidebar,
        }),
        [navbarContent, hideSidebar]
    );

    const sidebarValue = useMemo(
        () => ({
            hideSidebar,
            setHideSidebar,
        }),
        [hideSidebar]
    );

    return (
        <NavbarContext.Provider value={navbarValue}>
            <SidebarVisibilityContext.Provider value={sidebarValue}>
                {children}
            </SidebarVisibilityContext.Provider>
        </NavbarContext.Provider>
    );
};

export { NavbarContext, SidebarVisibilityContext };