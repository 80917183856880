import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useElements, useStripe, CardElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import { getEnv } from "../../App";
import { Button } from "../primitives/button";
import { Input } from "../primitives/input";
import { useToast } from "../ui/use-toast";
import {useNavigate} from "react-router-dom";

const stripePromise = getEnv() === "prod"
    ? loadStripe("pk_live_51JJZRyDy2lk4mUPBHNBj7Uf2rWXQwTqXLfX3rjkFkqCmUvGwV5CuLV6VgDMQ0ebf64B9qN5dBDbf53OG9loMXeit002DTcIO75")
    : loadStripe('pk_test_51JJZRyDy2lk4mUPBMgOldQzrULWLduQrxcSWTwW5WzZzwwcZCoGR1QrsYyxBo5WyG8EzLqi2WtLFzVgV8Vu0u34G00eoZtjXyL');

interface CheckoutFormProps {
    clientSecret: string;
}

const CheckoutForm = forwardRef((props: CheckoutFormProps, ref) => {
    const stripe = useStripe();
    const elements = useElements();
    const [paymentStatus, setPaymentStatus] = useState(null);
    CheckoutForm.displayName = 'CheckoutForm';
    const navigate = useNavigate();

    const handleSubmit = async () => {

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const { error } = await stripe.confirmCardPayment(props.clientSecret, {
            payment_method: {
                card: cardElement,
            },
        });

        if (error) {
            console.error(error);
            setPaymentStatus(error);
        } else {
            setPaymentStatus(null);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            window.fbq('track', 'Purchase', { value: 1, currency: 'AUD' });
            navigate('/payment_success')
        }
    };

    useImperativeHandle(ref, () => ({
        handleSubmit,
    }));

    const cardElementOptions = {
        style: {
            base: {
                iconColor: '#A8A29E',
                color: '#30313d',
                fontWeight: '500',
                fontFamily: 'Ideal Sans, system-ui, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                '::placeholder': {
                    color: '#d1d5db',
                },
            },
            invalid: {
                iconColor: '#df1b41',
                color: '#df1b41',
            },
            complete: {
                iconColor: '#28a745',
            },
        },
    };

    return (
        <div className="h-full w-full">
            <form onSubmit={handleSubmit}>
                <p className="text-contentPrimary text-sm font-semibold font-inter leading-tight mb-2">
                    Credit or debit card
                </p>
                <div className={'flex flex-col gap-2'}>
                    <div className="min-w-full px-3 py-1.5 bg-backgroundPrimary rounded-lg border border-borderOpaque h-9">
                        <CardElement options={cardElementOptions} />
                    </div>
                    {paymentStatus?.type === 'validation_error' && (
                        <p className={'text-contentNegative text-sm font-inter leading-tight font-medium'}>
                            {paymentStatus.message}
                        </p>
                    )}
                </div>
                {/*<div>*/}
                {/*    <Button className="mt-3 w-full" type="submit">*/}
                {/*        Pay*/}
                {/*    </Button>*/}
                {/*</div>*/}
            </form>
        </div>
    );
});

interface WrappedCheckoutFormProps {
    price_id: string;
    showCouponInput?: boolean;
    setPercentDiscount?: (discount: number) => void;
}

export const WrappedCheckoutForm = forwardRef((props: WrappedCheckoutFormProps, ref) => {
    const [clientSecret, setClientSecret] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [promoCode, setPromoCode] = useState<string>('');
    const { toast } = useToast();
    WrappedCheckoutForm.displayName = 'WrappedCheckoutForm';

    const createPaymentIntent = async () => {
        try {
            const { data } = await axios.get(`/create_stripe_payment_intent?price_id=${props.price_id}&promo_code=${promoCode}`);
            setClientSecret(data.client_secret);
        } catch (error) {
            toast({
                title: "Error",
                description: "Error creating stripe payment intent",
            });
        }
    };

    useEffect(() => {
        if (loading) {
            createPaymentIntent().then(() => setLoading(false));
        }
    }, [promoCode, loading]);

    const childRef = useRef<{ handleSubmit: () => void }>(null);

    useImperativeHandle(ref, () => ({
        handleSubmit: () => {
            if (childRef.current) {
                childRef.current.handleSubmit();
            }
        },
    }));

    return (
        <>
            {loading ? (
                <div><p>Loading</p></div>
            ) : (
                <div className="flex flex-col h-full w-full gap-2.5">
                    {props.showCouponInput && (
                        <PromoCodeInput setPromoCode={setPromoCode} setLoading={setLoading} setPercentDiscount={props.setPercentDiscount} />
                    )}
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                        <CheckoutForm clientSecret={clientSecret!} ref={childRef} />
                    </Elements>
                </div>
            )}
        </>
    );
});

interface PromoCodeInputProps {
    setPromoCode: (code: string) => void;
    setLoading: (loading: boolean) => void;
    setPercentDiscount: (discount: number) => void;
}

export const PromoCodeInput: React.FC<PromoCodeInputProps> = (props) => {
    const [tempPromoCode, setTempPromoCode] = useState<string | null>(null);
    const [couponInvalid, setCouponInvalid] = useState<boolean>(false);
    const { toast } = useToast();

    const validatePromoCode = async () => {
        if (!tempPromoCode) return;

        try {
            const { data } = await axios.get(`/validate_stripe_coupon?promo_code=${tempPromoCode}`);
            toast({
                title: "Success",
                description: "Coupon applied.",
            });
            props.setPromoCode(tempPromoCode);
            props.setLoading(true);
            props.setPercentDiscount(data.percentDiscount);
        } catch (error) {
            setCouponInvalid(true)
            setTempPromoCode(null);
        }
    };

    return (
        <div className="flex flex-col gap-y-2">
            <p className="text-contentPrimary text-sm font-semibold font-inter leading-tight">
                Insert coupon
            </p>
            <div className={'flex flex-col gap-2'}>
                <div className="flex flex-row gap-x-6 h-9">
                    <Input
                        type="text"
                        placeholder="Coupon code"
                        value={tempPromoCode || ''}
                        onChange={(e) => setTempPromoCode(e.target.value.toUpperCase())}
                    />
                        <Button outline onClick={validatePromoCode} className={'bg-backgroundPrimary'}>
                            Apply
                        </Button>
                    </div>
                {couponInvalid &&
                    <p className={'text-contentNegative text-sm font-inter leading-tight font-medium'}>Invalid coupon</p>
                }
            </div>
        </div>
    );
};
