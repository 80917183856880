import React, {useContext} from 'react'
import UserAvatar from "../account/UserAvatar";
import Divider from "../primitives/Divider";
import {Button} from "../primitives/button";
import {useNavigate} from "react-router-dom";
import {ToggleTag} from "../primitives/ToggleTag";
import {CompanyConfigContext} from "../../CompanyConfigContextProvider";
import {
    ChartBarIcon,
    ClockIcon,
    FingerPrintIcon,
    RocketLaunchIcon
} from "@heroicons/react/16/solid";
import axios from "axios";
import {CardBody, CardContainer, CardItem} from "../ui/3d-card";
import {useQuery} from "@tanstack/react-query";
import IdentifierCard from "./IdentifierCard";
import HistorySection from "./HistorySection";

const DataRoom = () => {

    const {data, isLoading, error} = useQuery({
        queryKey: ['profile'],
        queryFn: async () => {
            const {data} = await axios.get("/my_profile");
            return data.profile as ProfileInformation;
        }
    })

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error fetching profile</div>;

    return (
        <div className={'flex flex-col bg-backgroundTertiary min-h-screen'}>
            <DataRoomNavbar/>
            {!isLoading &&
                <div className={'flex flex-row mt-2 px-6 gap-4 min-h-[358px]'}>
                    <div className={'w-3/4'}>
                        <IdentifierCard school_to_enter={data.school_to_enter} pseudonym={data.pseudonym}/>
                    </div>
                    <div className={'w-1/4'}>
                        <PercentileCard/>
                    </div>
                </div>
            }
            <HistorySection/>
        </div>
    )
}

const DataRoomNavbar = () => {
    const navigate = useNavigate();
    const companyConfig = useContext(CompanyConfigContext);

    return (
        <div className={'flex flex-row px-6 py-3 items-center'}>
            <div className={'flex flex-row justify-start gap-4 items-center'}>
                    <img className="size-7" src={companyConfig.company_logo_navbar} alt="Company Logo"/>
                <p className={'font-ppe-editorial-new-light text-xl text-contentPrimary leading-none pt-1'}>DATAROOM</p>
            </div>
            <div className={'flex flex-row gap-7.5 grow justify-center'}>
                <ToggleTag><FingerPrintIcon className={'size-4'}/>Identifiers</ToggleTag>
                <ToggleTag><RocketLaunchIcon className={'size-4'}/>Journey (Soon)</ToggleTag>
                <ToggleTag><ChartBarIcon className={'size-4'}/>Performance (Soon)</ToggleTag>
                <ToggleTag><ClockIcon className={'size-4'}/>History</ToggleTag>
            </div>
            <div className={'flex flex-row gap-6 justify-end'}>
                <Button color={'primary'} onClick={()=>navigate(-1)}>Leave</Button>
                <Divider type={'vertical'}/>
                <UserAvatar/>
            </div>
        </div>
    )
}

const PercentileCard = () => {
    return (
        <CardContainer className={'w-full h-full'} containerClassName={'h-full'}>
            <CardBody
                className={'p-8 bg-backgroundPrimary text-contentPrimary rounded-[20px] flex flex-col h-full w-full justify-between'}>
                <CardItem translateZ={50} className={'text-2xl font-semibold'}>Overall percentile</CardItem>
                <CardItem translateZ={50} className={'flex flex-col gap-4'}>
                    <p className={'text-8xl font-semibold'}>90</p>
                    <p className={'text-contentSecondary text-base font-normal'}>You’re in the top 10% of learners on
                        this platform.</p>
                </CardItem>
            </CardBody>
        </CardContainer>
    )
}

export default DataRoom
