import React from "react";
import {CardBody, CardContainer, CardItem} from "../ui/3d-card";
import {Bars2Icon, ChevronDoubleDownIcon, ChevronDoubleUpIcon, QuestionMarkCircleIcon} from "@heroicons/react/16/solid";

interface IdentifierCardProps {
    pseudonym: string;
    school_to_enter: {
        id: number;
        name: string;
    }
}

const IdentifierCard: React.FC<IdentifierCardProps> = (props) => {
    return (
        <CardContainer className="w-full h-full" containerClassName={'h-full'}>
            <CardBody className={'p-8 bg-grey-900 text-contentOnColour rounded-[20px] flex flex-col font-inter w-full h-full justify-between'}>
                <div className={'flex flex-row'}>
                    <div className={'flex flex-col gap-2 grow justify-start'}>
                        <p className={'text-contentSecondary font-semibold text-2xl'}>I&apos;ve been expecting
                            you,</p>
                        <CardItem className={'text-[4rem] text-contentOnColour font-semibold'}>@{props.pseudonym}</CardItem>
                    </div>
                    <CardItem
                    >
                        <div
                            className={'flex flex-col gap-2 text-contentSecondary text-base leading-none items-end justify-start'}>
                            <p>2024</p>
                            <p>{props.school_to_enter.name}</p>
                        </div>
                    </CardItem>
                </div>
                <div className={'flex flex-col gap-3'}>
                    <div className={'flex flex-row gap-1 text-contentOnColour'}>
                        <p className={'font-normal text-xs'}>Recent performance</p>
                        <QuestionMarkCircleIcon className={'size-4 cursor-pointer'}/>
                    </div>
                    <div className={'grid grid-cols-4'}>
                        <div className={'flex flex-row gap-2 items-center justify-start'}>
                            <ChevronDoubleUpIcon className={'size-4 text-green-400'}/>
                            Verbal Reasoning
                        </div>
                        <div className={'flex flex-row gap-2 items-center justify-center'}>
                            <ChevronDoubleDownIcon className={'size-4 text-red-400'}/>
                            Quantitative Reasoning
                        </div>
                        <div className={'flex flex-row gap-2 items-center justify-center'}>
                            <ChevronDoubleUpIcon className={'size-4 text-green-400'}/>
                            Mathematics
                        </div>
                        <div className={'flex flex-row gap-2 items-center justify-end'}>
                            <Bars2Icon className={'size-4 text-grey-400'}/>
                            Reading Comprehension
                        </div>
                    </div>
                </div>
            </CardBody>
        </CardContainer>
    )
}

export default IdentifierCard