import React, { useState, useEffect, useContext } from "react";
import {useNavigate, useLocation, Link, NavigateFunction} from "react-router-dom";
import { connect } from "react-redux";
import { login } from "./LoginActions";
import { Button } from "../primitives/button";
import { Input } from "../primitives/input";
import PrivacyPolicy from "../fixedelements/PrivacyPolicy";
import { CompanyConfigContext } from "../../CompanyConfigContextProvider";
import axios from "axios";
import { useToast } from "../ui/use-toast";
import AbsoluteLogo from "../fixedelements/AbsoluteLogo";

interface LoginProps {
    login: (userData: { email: string; password: string }, navigate: NavigateFunction, toast: { (params: { title: string; description: string; }): void }) => void;
    auth: never;
}

const Login: React.FC<LoginProps> = ({ login, auth }) => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [step, setStep] = useState<number>(1);
    const navigate = useNavigate();
    const location = useLocation();
    const companyConfig = useContext(CompanyConfigContext);
    const { toast } = useToast();

    useEffect(() => {
        document.title = `Log in — ${companyConfig.company_name}`;
        const searchParams = new URLSearchParams(location.search);
        const emailPrefill = searchParams.get("email");
        const activationSent = searchParams.get("activation_sent");
        const resetSent = searchParams.get("reset_sent");
        const sessionExpired = searchParams.get("session_expired");
        if (sessionExpired) {
            toast({
                variant: 'destructive',
                title: "Error",
                description: "Your session has expired. Please log in again.",
            });
            // clear the token
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            navigate("/login");
        }
        if (emailPrefill) {
            setEmail(emailPrefill);
        }
        if (activationSent && emailPrefill) {
            toast({
                title: "Success",
                description: `Please check the email address ${emailPrefill} to activate your account.`,
            });
        }
        if (resetSent && emailPrefill) {
            toast({
                title: "Success",
                description: `Please check the email address ${emailPrefill} for instructions to reset your password.`,
            });
        }
        const token = localStorage.getItem("token");
        if (token) {
            verifyToken(token);
        }

        document.body.style.backgroundColor = "#FFFAF1";
        return () => {
            document.body.style.backgroundColor = null;
            document.title = `${companyConfig.company_name}`;
        };
    }, [location.search, companyConfig, navigate, toast]);

    const verifyToken = async (token: string) => {
        try {
            await axios.get("/api/v1/verify_token/", {
                headers: {
                    Authorization: `Token ${token}`
                }
            });
            navigate("/dashboard");
        } catch (error) {
            console.error("Error verifying token:", error);
        }
    };

    const onNextStepClick = () => {
        if (email === "") {
            // Prevent empty email
            return;
        }
        setStep(2);
    };

    const onPreviousStepClick = () => {
        setStep(1);
    };

    const onLoginClick = () => {
        const userData = { email, password };
        login(userData, navigate, toast);
    };

    const onKeyPressPassword = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent form submission
            onLoginClick();
        }
    };

    let currentFormFields;
    if (step === 1) {
        currentFormFields = (
            <div className="h-12 w-[320px]">
                <Input
                    autoComplete="email"
                    className={"h-full"}
                    type="text"
                    name="email"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoFocus
                    onKeyPress={(event) => {
                        if (event.key === "Enter") onNextStepClick();
                    }}
                />
            </div>
        );
    } else if (step === 2) {
        currentFormFields = (
            <div className="flex flex-col gap-y-3">
                <div className="h-12 relative w-[320px]">
                    <Input
                        autoComplete="email"
                        type="text"
                        name="email"
                        placeholder="Email address"
                        value={email}
                        readOnly
                    />
                    <button
                        onClick={onPreviousStepClick}
                        className={"text-accent"}
                        style={{
                            position: "absolute",
                            top: "50%",
                            right: "1rem",
                            transform: "translateY(-50%)",
                            textDecoration: "none",
                        }}
                    >
                        Edit
                    </button>
                </div>
                <div className="h-12">
                    <Input
                        autoFocus
                        autoComplete="password"
                        type="password"
                        name="password"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyPress={onKeyPressPassword}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="h-full flex flex-col items-center bg-hatcheryGrey relative">
            <AbsoluteLogo />
            <div className="flex justify-center items-center align-middle mx-20 h-full w-full">
                <div className="flex-col flex gap-y-6 items-center">
                    <div className="mb-2 text-black text-[40px] font-light font-ppe-editorial-new-light leading-10 px-10 flex justify-center">
                        {step === 2 ? <p>Enter your password</p> : <p>Welcome back</p>}
                    </div>
                    <div className="flex flex-col items-center w-full">{currentFormFields}</div>
                    {step === 2 && (
                        <Link to="/send_reset_password">
                            <p className="text-accent font-sf-pro text-sm font-normal leading-tight">Forgot password?</p>
                        </Link>
                    )}
                    <Button
                        className="w-[320px] h-12"
                        color="primary"
                        onClick={step === 1 ? onNextStepClick : onLoginClick}
                    >
                        {step === 1 ? "Continue" : "Log in"}
                    </Button>
                    <div className="flex flex-row gap-x-1 justify-center">
                        <p className="text-black text-sm font-medium font-['SF Pro Text'] leading-[18px]">Don&apos;t have an account?</p>
                        <Link to="/signup">
                            <p className="text-accent font-sf-pro text-sm font-medium leading-[18px]">Sign up</p>
                        </Link>
                    </div>
                </div>
                <PrivacyPolicy />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps, { login })(Login);
