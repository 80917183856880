import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "react-toastify/dist/ReactToastify.css";
import "./scss/custom.css";
import "./scss/scrollbar.css";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";

window.mobileCheck = function() {
    let check = false;
    (function(a) {
        if (/android|bb\d+|meego|mobile|iphone|ipod|iemobile|blackberry|windows phone/i.test(a)) {
            check = true;
        }
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};

const isMobile = window.mobileCheck();
const isMobileNotSupportedPage = window.location.pathname === "/mobile-not-supported.html";

if (isMobile && !isMobileNotSupportedPage) {
    window.location.href = "/mobile-not-supported.html";
} else {
    const root = ReactDOM.createRoot(document.getElementById('root'))
    root.render(
        <App />
    )
}

// Report web vitals
reportWebVitals();

// Axios interceptor for handling 401 errors
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            window.location.href = "/login?session_expired=1";
        }
        return Promise.reject(error);
    }
);
