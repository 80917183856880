import React, {useContext} from "react";
import {CompanyConfigContext} from "../../CompanyConfigContextProvider";

function AbsoluteLogo() {
    const companyConfig = useContext(CompanyConfigContext);

    return (
        <div className={"w-full absolute top-4 flex justify-center"}>
              <div className={"size-12 flex items-center"}>
                  <img className={"size-fit"}
                       src={companyConfig.company_logo_navbar}
                       alt="Company Logo"/>
              </div>
        </div>
    );
}


export default AbsoluteLogo