import React, {useEffect} from 'react';
import drawerIcon from "../../assets/drawer-right.svg";
import {Switch} from "../primitives/switch";

type AnnotationCategory = {
    name: string;
    color: string;
};

type CategorySelectorProps = {
    categories: AnnotationCategory[];
    activeCategories: AnnotationCategory[];
    setActiveCategories: (activeCategories: AnnotationCategory[]) => void;
    toggleSidebar: () => void
}

function ToggleComponent(props: { checked: boolean, onChange: (checked) => void, toggleSidebar: () => void}) {

    return (
        <div className={"grid grid-cols-2 mb-4 items-center"}>
            <img
                className={"drawer-sidebar-item cursor-pointer"}
                src={`${drawerIcon}`}
                onClick={props.toggleSidebar}
                alt={"sidebar"}/>
            <div className={"flex flex-row gap-x-2 items-center justify-end"}>
                <div className={" text-contentTertiary font-inter items-center flex font-normal text-3xs"}>
                    Toggle All
                </div>
                <Switch name="allow_embedding" defaultChecked onChange={props.onChange} className={"cursor-pointer"}/>
            </div>
        </div>
    );
}

function CategoryEnabler(props: CategorySelectorProps) {
    console.log("category enabler re-rendered");
    const [toggleAll, setToggleAll] = React.useState(true);
    const [hover, setHover] = React.useState("");
    useEffect(() => {
        if (toggleAll) {
            props.setActiveCategories(props.categories);
        } else {
            props.setActiveCategories([]);
        }
    }, [toggleAll]);

    const toggleCategory = (category: AnnotationCategory) => {
        if (props.activeCategories.includes(category)) {
            props.setActiveCategories(props.activeCategories.filter((activeCategory) => activeCategory.name !== category.name));
        } else {
            props.setActiveCategories([...props.activeCategories, category]);
        }
    };

    return (
        <div className={"flex flex-col w-full"}>
            <ToggleComponent checked={toggleAll} onChange={(checked) => setToggleAll(checked)} toggleSidebar={props.toggleSidebar}/>
            <div className={"category-selector"}>
                {props.categories.map((category) => {
                    // rgba(51, 192, 48, 1) -> rgba(51, 192, 48, 0.1)
                    const transparentColor = category.color.replace("1)", "0.1)");

                    return <button
                        className={"border border-transparent rounded-lg text-sm font-normal cursor-pointer h-10 font-inter"}
                        key={category.color}
                        onMouseEnter={() => {setHover(category.color)}}
                        onMouseLeave={() => {setHover("")}}
                        style={{
                            transition: 'border-color 0.25s',
                            color: category.color,
                            borderColor: props.activeCategories.includes(category) ? category.color : "#E7E5E4",
                            outlineColor: props.activeCategories.includes(category) ? category.color : "#E7E5E4",
                            backgroundColor: (hover === category.color && !props.activeCategories.includes(category))? "#F5F5F4" : (props.activeCategories.includes(category) ? transparentColor : "#FBF9F9"),
                        }}
                        onClick={() => toggleCategory(category)}
                    >
                        {category.name}
                    </button>
                })}
            </div>
        </div>
    );
}

export default CategoryEnabler;
