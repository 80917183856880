import { useEffect, useState } from "react";
import { Button } from "../primitives/button";
import Confetti from "./confetti";
import {useNavigate} from "react-router-dom";
import { createSessionWithoutDispatch} from "../sessioncreator/SessionCreatorActions";

export function SessionComplete() {
    const [showConfetti, setShowConfetti] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleKeyPress = (event: WindowEventMap["keydown"]) => {
            if (event.key === "k") {
                setShowConfetti(true);
            }
        };

        window.addEventListener("keydown", handleKeyPress);
        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, []);


    return (
        <div className="relative max-w-screen h-full overflow-hidden">
            {showConfetti && <Confetti />}
            <div className="h-full flex items-center flex-col justify-center text-center font-inter">
                <div className="font-black text-[4rem] pb-6 leading-[56px] text-contentPrimary font-ppe-editorial-new-light">
                    Well done on completing your session.
                </div>
                <p className="font-medium text-contentTertiary text-xl mb-8">
                    You will find your results reflected on your dashboard analytics.
                </p>
                <div className="flex flex-row gap-4 h-10 text-normal cursor-pointer">
                    <Button onClick={() => navigate("/dashboard")}>
                        <p className="text-white text-base font-semibold">Return to dashboard</p>
                    </Button>
                    <Button
                        color="primary"
                        onClick={async () => {
                            await createSessionWithoutDispatch(navigate);
                            navigate("/current_session");
                        }}
                    >
                        <p className="text-contentPrimary text-base font-semibold">Start another session</p>
                    </Button>
                </div>
            </div>
            <div className="absolute justify-center w-full flex bottom-0">
                <p className="text-sm text-contentTertiary">Press &apos;K&apos; to celebrate</p>
            </div>
        </div>
    );
}
