import {useContext} from 'react';
import {CompanyConfigContext} from "../CompanyConfigContextProvider";

const useRedirectToPurchasePage = () => {
    const companyConfig = useContext(CompanyConfigContext);

    return () => {
        if (companyConfig.purchase_page_redirect_url) {
            window.location.href = companyConfig.purchase_page_redirect_url;
        } else {
            window.location.href = "/purchase_page";
        }
    };
};

export default useRedirectToPurchasePage;