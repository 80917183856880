import React, {useEffect, useState} from "react";
import drawerIcon from "../../assets/drawer-right.svg";
import { Modal} from "../primitives/modal";
import SetupModal from "./SetupModal";
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import axios from "axios";
import {Button} from "../primitives/button";
import { useToast } from "../ui/use-toast"
import SubmitEssay from "./SubmitEssay";

interface EssayPackage {
    essayID : number,
    essayText : string,
}

function getText(html) {
    const divContainer = document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
}

interface ExamEssayProps {
    nextQuestion: (essayId?: number) => void;
    prompt: string;
}

const ExamEssay: React.FC<ExamEssayProps> = (props) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [essay, setEssay] = useState<string>("");
    const [essayPrompt, setEssayPrompt] = useState<string>("");
    const [essaySubmitted, setEssaySubmitted] = useState<boolean>(false);
    const [writeInside, setWriteInside] = useState<boolean>(true);
    const [openSetupModal, setOpenSetupModal] = useState<boolean>(false);
    const [loadingAnalysis, setLoadingAnalysis] = useState<boolean>(false);
    const [isSetPrompt, setIsSetPrompt] = useState<boolean>(true);
    const [isWriting, setIsWriting] = useState<boolean>(false);
    const [tempEssay, setTempEssay] = useState<string>("");
    const [tempEssayPrompt, setTempEssayPrompt] = useState<string>("");
    const [essayEditorState, setEssayEditorState] = useState<NonNullable<unknown>>(null);
    const [essayPromptEditorState, setEssayPromptEditorState] = useState<NonNullable<unknown>>(null);
    const [essayPackage, setEssayPackage] = useState<EssayPackage>({essayText: "", essayID: 0})
    const [essayTitle, setEssayTitle] = useState<string>("")
    const [task, setTask] = useState<string>("")
    const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);

    const {toast} = useToast()
    useEffect(() => {
        setEssayPrompt(props.prompt)
        if (writeInside) {
            setTempEssay(null)
        } else {
            setTempEssay("Essay will be written outside")
        }
    }, [writeInside])
    useEffect(() => {
        if (essayEditorState && essayPromptEditorState) { /* empty */
        }
    }, [essayEditorState, essayPromptEditorState])

    useEffect(() => {
        setTempEssayPrompt(essayPrompt)
    }, [essayPrompt, setEssayPrompt, setTempEssayPrompt])

    const toggleSidebar = () => {
        setIsRightSidebarOpen(isSidebarOpen);
        setIsSidebarOpen(!isSidebarOpen);

    };

    const handleSubmitEssay = () => {
        const essayTextOnly = getText(tempEssay);
        const essayPromptTextOnly = getText(tempEssayPrompt);
        if (essayPromptTextOnly.length < 3) {
            return toast({
                variant: 'destructive',
                title: "Prompt too short",
                description: "Please submit a longer prompt.",
            })
        }
        if (essayTextOnly.length < 101) {
            return toast({
                variant: 'destructive',
                title: "Essay too short",
                description: "Please submit a longer essay.",
            })
        }
        setEssay(tempEssay);
        setEssayPrompt(essayPromptTextOnly);
        const postData = {
            essay_text: essayTextOnly,
            prompt: essayPromptTextOnly,
            title: essayTitle === "" ? "My Essay" : essayTitle,
            writing_category: task ? task : "NA",
        };
        axios.post('/api/v1/writingtoolessay/', postData)
            .then(response => {
                setEssayPackage({essayID: response.data.id, essayText: tempEssay})
                console.log('Essay successfully submitted:', response.data);
                setEssaySubmitted(true);
                setLoadingAnalysis(true);
                axios.post(`/essay-feedback/?essay_id=${response.data.id}`).then(() =>
                    toast({
                        title: "Success",
                        description: "Essay processed successfully.",
                    })
                )
                props.nextQuestion(response.data.id)
            })
            .catch(error => {
                console.error('Error submitting essay:', error);
            });

    }

    return (
        <>
            <Modal
                open={openSetupModal}
                onClose={setOpenSetupModal}
                className={"absolute top-[60px] left-1/2 transform -translate-x-1/2 max-w-[400px] rounded-0.625rem p-0 overflow-y-auto max-h-calc(100vh - 120px)"}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <SetupModal
                    setPrompt={setEssayPrompt}
                    writeInside={writeInside}
                    setOpenSetupModal={setOpenSetupModal}
                    setWriteInside={setWriteInside} isSetPrompt={isSetPrompt}
                    setIsSetPrompt={setIsSetPrompt}
                    setIsWriting={setIsWriting}
                    setEssayTitle={setEssayTitle}
                    setEssayTask={setTask}
                    essayTask={task}
                />
            </Modal>
            <div className={"w-full flex flex-row h-[calc(100vh-65px)]"}>
                <div className={"w-full overflow-y-auto flex flex-col"}>
                    <div className={"grid grid-cols-2 border-b border-borderOpaque px-4 h-12 items-center"}>
                        <div className={"flex flex-row justify-start items-center gap-x-2"}>
                            <img
                                alt={"drawericon"}
                                className={`drawer-sidebar-item cursor-pointer ${isSidebarOpen ? 'hidden' : 'block'}`}
                                src={`${drawerIcon}`}
                                onClick={toggleSidebar}
                            />
                            <p className={"text-contentPrimary text-sm font-bold font-inter leading-[18px]"}>Writer</p>
                        </div>
                        <div className={"flex flex-row justify-end items-center h-full"}>
                            <Button onClick={handleSubmitEssay} color={'secondary'}>Submit</Button>
                        </div>
                    </div>
                    <div className={"overflow-y-auto h-[calc(100vh-113px)] w-full"}>
                        <SubmitEssay
                            setEssay={setEssay}
                            setEssayPrompt={setEssayPrompt}
                            setEssaySubmitted={setEssaySubmitted}
                            setLoadingAnalysis={setLoadingAnalysis}
                            essayPrompt={essayPrompt}
                            writeInside={writeInside}
                            isSetPrompt={isSetPrompt}
                            setTempEssay={setTempEssay}
                            setTempEssayPrompt={setTempEssayPrompt}
                            tempEssay={tempEssay}
                            tempEssayPrompt={tempEssayPrompt}
                            isExam={true}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ExamEssay