import React, { useEffect, useState } from "react";
import axios from "axios";
import Breadcrumbs from "../primitives/breadcrumbs";
import {connect, ConnectedProps} from "react-redux";
import {NavbarWithStreakIndicator} from "../PreconfiguredNavbars/NavbarWithStreakIndicator";
import {Button} from "../primitives/button";
import {PlusIcon} from "@heroicons/react/20/solid";
import {useNavbarContent} from "../../NavbarProvider";

interface ExamAttempt {
    id: number;
    start_time: string;
    exam: {
        name: string;
    };
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface ExamHistoryProps extends PropsFromRedux {}

const ExamHistory: React.FC<ExamHistoryProps> = () => {
    const [examAttempts, setExamAttempts] = useState<ExamAttempt[]>([]);
    useNavbarContent({
        component: NavbarWithStreakIndicator
    });
    useEffect(() => {
        axios
            .get('/get_all_exam_attempts_for_user')
            .then((response) => {
                setExamAttempts(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const pages = [
        { name: 'Exams', href: '/exams', current: false },
        { name: 'History', href: '#', current: true },
    ];

    return (
        <div className={"w-full p-4 flex flex-col"}>
            <div className={""}>
                <Breadcrumbs pages={pages}/>
            </div>
            <div className={"flex justify-center"}>
                {examAttempts.length > 0 ?
                    <div className={"w-[720px] mt-16"}>
                        <table className={"w-full"}>
                            <thead className={"border-b-2 border-borderOpaque"}>
                            <tr>
                                <th className={"px-6 py-2 text-contentPrimary text-xs font-bold font-inter leading-tight text-left"}>Exam
                                    Name
                                </th>
                                <th className={"px-6 py-2 text-contentPrimary text-xs font-bold font-inter leading-tight text-left"}>Attempt
                                    Date
                                </th>
                                <th className={"px-6 py-2 text-contentPrimary text-xs font-bold font-inter leading-tight text-left"}>Results</th>
                            </tr>
                            </thead>
                            <tbody>
                            {examAttempts.map((examAttempt, index) => {
                                const normalDate = new Date(examAttempt.start_time)
                                return (
                                    <tr className={"border-b border-borderOpaque"} key={index}>
                                        <td className={"px-6 py-2 text-contentPrimary text-sm font-inter leading-tight font-normal"}>{examAttempt.exam.name}</td>
                                        <td className={"px-6 py-2 text-contentPrimary text-sm font-inter leading-tight font-normal"}>{normalDate.toLocaleString()}</td>
                                        <td className={"px-6 py-2 text-contentPrimary text-sm font-inter leading-tight font-normal"}>
                                            <button
                                                onClick={() => {
                                                    window.location.href = `/exam_results?id=${examAttempt.id}`
                                                }}

                                            >View
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                    :
                    <>
                        <div className={"flex flex-col min-w-[692px] mt-6"}>
                            <div
                                className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                <p className="mt-2 text-black text-2xl font-extrabold font-inter-tight">Your first
                                    exam awaits!</p>
                                <p className="mt-2 text-stone-400 text-sm font-normal font-inter leading-tight">Your
                                    exam history is currently empty. <br/>
                                    Complete an exam and return here to revisit your metrics</p>
                                <div className="mt-6">
                                    <Button
                                        color={"primary"}
                                        onClick={() => {
                                            window.location.href = "/exams"
                                        }}
                                        type="button"
                                    >
                                        <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true"/>
                                        Pick exam
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default connector(ExamHistory);