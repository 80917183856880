import React from "react";
import { Button } from "../../primitives/button";
import { CheckBadgeIcon } from "@heroicons/react/16/solid";

interface ProductSelectCardProps {
    selectedProduct: {
        price: string;
    };
    onClick: () => void;
}

const ProductSelectCard: React.FC<ProductSelectCardProps> = ({ selectedProduct, onClick }) => {
    const features = [
        '1000s of questions',
        'Unlimited video library access',
        'Full mock-exam access',
        'AI Writer',
    ];

    return (
        <div className={"w-[285px] pt-10 pb-5 h-[508px] bg-backgroundPrimary text-contentPrimary border-[0.5px] border-greyHatchery rounded-lg"}>
            <div className={"border-b-[0.5px] border-greyHatchery"}>
                <div className={"flex flex-col justify-start gap-y-10 pb-7 px-6"}>
                    <div className={"flex flex-col gap-y-3"}>
                        <p className={"font-ppe-editorial-new-light font-normal text-[40px] leading-10"}>Online</p>
                        <p className={"text-xs"}>Every digital selective entry superpower at your disposal.</p>
                    </div>
                    <p className={"font-inter-tight text-[40px] font-medium leading-none"}>${selectedProduct.price}</p>
                    <div>
                        <Button onClick={onClick} className={"cursor-pointer"}>Upgrade now</Button>
                    </div>
                </div>
            </div>
            <div className={"flex flex-col justify-start px-6 mt-5"}>
                <div className={"flex flex-col gap-y-2"}>
                    <p className={"font-inter text-xs font-bold"}>Everything in Free, and</p>
                    {features.map((feature, index) => (
                        <div className={"flex flex-row gap-x-2 items-center"} key={index}>
                            <CheckBadgeIcon className={"size-4 text-primaryB"}/>
                            <p className={"font-inter font-normal text-xs"}>{feature}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProductSelectCard;
