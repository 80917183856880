import React, {useState, useEffect, useContext} from "react";
import axios from "axios";
import clockIcon from "../../assets/clockfaceeightoclock-light.svg";
import {Dialog, DialogActions, DialogBody, DialogTitle} from "../primitives/dialog";
import {Button} from "../primitives/button";
import {ClockIcon, LockClosedIcon} from "@heroicons/react/16/solid";
import {CompanyConfigContext} from "../../CompanyConfigContextProvider";
import useRedirectToPurchasePage from "../../utils/useRedirectToPurchasePage";
import Divider from "../primitives/Divider";

interface ExamSelectionProps {
    selectExam: (exam: Exam) => void;
}

interface ExamCardProps {
    exam: Exam;
    selectExam: (exam: Exam) => void;
    subscriptionStatus: boolean;
}

interface ExamCardContentProps {
    exam: Exam;
    isLocked: boolean;
    isFutureDate: boolean;
}

interface ExamCardOverlayProps {
    subscriptionStatus: boolean;
}

const ExamCardOverlay: React.FC<ExamCardOverlayProps> = (props) => (
    <div
        className={"absolute opacity-0 bg-contentPrimary hover:bg-opacity-75 hover:opacity-100 flex justify-center items-center w-full h-full top-0"}>
        {!props.subscriptionStatus &&
        <LockClosedIcon className={"size-12 text-primaryA"}/>
        }
    </div>
);


const ExamCardContent: React.FC<ExamCardContentProps> = ({exam, isLocked, isFutureDate}) => {

    return (
        <div className={"flex flex-col justify-start items-start min-w-full"}>
            <div className={"w-full flex justify-center h-[196px]"}>
                <img alt={"examimage"} src={exam.image_url} className={"mb-6  object-contain"}/>
            </div>
            <div className={"flex flex-row mb-[16px] gap-x-1.5"}>
                {isFutureDate || !exam.release_date ?
                    <>
                        <div
                            className="px-2 py-[3px] bg-contentSecondary rounded-[5px] justify-start items-center flex-row flex h-full">
                            <ClockIcon className={"text-white size-2.5"}/>
                            <div
                                className="text-white text-2xs font-extrabold font-sf-pro leading-3 ml-1">SCHEDULED {(exam.release_date && isFutureDate) && exam.release_date.toString().replace(/-/g, '/')}</div>
                        </div>
                    </>
                    :
                    <div className={"flex items-center flex-row gap-x-2"}>
                        <div className={"text-pinkSharp font-inter-tight text-xs font-medium"}>
                            Your last attempt
                        </div>
                        <div className="px-1.5 py-0.5 bg-pinkSharp rounded-[6px] inline-flex h-[18px]">
                            <div
                                className="text-white leading-none text-xs font-medium font-sf-pro flex items-center">
                                {exam.last_attempt_score !== null ?
                                    <>{(exam.last_attempt_score * 100).toFixed(0)}%</>
                                    :
                                    <>N/A</>
                                }
                            </div>
                        </div>
                    </div>
                }

                {isLocked &&
                <div
                    className="px-2 py-[3px] bg-contentPrimary rounded-[5px] justify-start items-center flex-row flex h-full">
                    <LockClosedIcon className={"text-white size-2.5"}/>
                    <div className="text-white text-2xs font-extrabold font-sf-pro leading-3 ml-1">LOCKED</div>
                </div>}
            </div>
            <p className={"text-contentPrimary text-[40px] font-bold font-inter-tight leading-9 mb-4"}>{exam.name}</p>
            <p className={'text-contentPrimary text-xs font-normal font-inter overflow-y-hidden line-clamp-5 leading-snug'}>{exam.description}</p>
        </div>
    );
}

const ExamCardSkeleton: React.FC = () => {
    return (
        <>
            <div
                className={"flex flex-col items-center px-8 py-12 relative overflow-hidden hover:border-contentPrimary border-transparent border rounded-[10px] w-[338px] h-[460px] cursor-pointer bg-backgroundPrimary"}>
                <div role="status"
                     className="animate-pulse flex flex-col justify-start items-start min-w-full gap-y-5">
                    <div
                        className="flex items-center justify-center w-full h-48 bg-gray-300 rounded dark:bg-gray-700">
                        <svg className="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                            <path
                                d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"/>
                        </svg>
                    </div>
                    <div className="w-full">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
                    </div>
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </>
    );
};

const ExamCard: React.FC<ExamCardProps> = ({exam, selectExam, subscriptionStatus}) => {
    const currentDate = new Date();
    const currentUTCDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));

    const examReleaseDate = new Date(exam.release_date);
    const examUTCDate = new Date(Date.UTC(examReleaseDate.getFullYear(), examReleaseDate.getMonth(), examReleaseDate.getDate()));

    const isFutureDate = examUTCDate > currentUTCDate;

    const [hoverLock, setHoverLock] = useState(false);
    const isLocked = !subscriptionStatus && exam.premium_only || (exam.release_date == null) || isFutureDate;
    const companyConfig = useContext(CompanyConfigContext);
    const redirectToPurchasePage = useRedirectToPurchasePage();

    const handleConfirm = () => {
        setHoverLock(false);
        if (!subscriptionStatus && exam.premium_only) {
            redirectToPurchasePage()
        }
    };

    return (
        <>
            <Dialog open={hoverLock} onClose={() => setHoverLock(false)}>
                <DialogTitle>
                    <div className="text-black text-xl font-medium font-['Inter Tight'] leading-normal">
                        {subscriptionStatus ?
                            <p>This exam has not been released yet</p>
                            :
                            <p>This exam is locked to free users</p>
                        }

                    </div>
                </DialogTitle>
                <DialogBody>
                    <div className={"text-sm"}>
                        {subscriptionStatus ?
                            <p>Please come back when the exam is open.</p>
                            :
                            <p>This exam is exclusively available to paid {companyConfig.company_name} users. To get unlimited access to all
                                exams, and all of {companyConfig.company_name}’s other features, consider upgrading your account.</p>
                        }
                    </div>
                </DialogBody>
                <DialogActions>
                    <Button plain onClick={() => setHoverLock(false)}>
                        Dismiss
                    </Button>
                    {!subscriptionStatus && exam.premium_only &&
                    <Button color={"secondary"} onClick={handleConfirm}>
                        Confirm
                    </Button>
                    }
                </DialogActions>
            </Dialog>
            <div
                className={"flex flex-col items-center px-8 py-12 relative overflow-hidden hover:border-contentPrimary border-transparent border rounded-[10px] w-[338px] h-[454px] cursor-pointer"}
                onClick={() => isLocked ? setHoverLock(true) : selectExam(exam)}
                style={{backgroundColor: isFutureDate || exam.release_date === null ? "#E7E5E4" : exam.background_colour_hex || null}}>
                <ExamCardContent exam={exam} isLocked={isLocked} isFutureDate={isFutureDate}/>
                {isLocked && <ExamCardOverlay subscriptionStatus={subscriptionStatus}/>}
            </div>
        </>
    );
};

const ExamSelection: React.FC<ExamSelectionProps> = ({selectExam}) => {
    const [exams, setExams] = useState([]);
    const [subscriptionStatus, setSubscriptionStatus] = useState(false);
    const [expirationDate, setExpirationDate] = useState(null);
    const [remainingDays, setRemainingDays] = useState(null);
    const companyConfig = useContext(CompanyConfigContext);
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        document.title = `Exams — ${companyConfig.company_name}`;
        getSubscriptionDetails();
        getExams();
        return () => {
            document.title = `${companyConfig.company_name}`;
        };
    }, [companyConfig]);

    const getExams = () => {
        axios.get("/get_exams_for_user_state")
            .then(response => {
                setExams(response.data);
            }).catch(error => {
            console.log(error);
            }).finally(
                () => setIsLoading(false));
    };

    const getSubscriptionDetails = async () => {
        try {
            const response = await axios.get("/user_has_active_subscription_request");
            if (response.data.has_active_subscription === true) {
                setSubscriptionStatus(true);
                setExpirationDate(response.data.expiry_date);
                setRemainingDays(response.data.remaining_days);
            } else {
                setSubscriptionStatus(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const goToExamHistory = () => {
        window.location.href = "/exam_history";
    };

    const number_of_free_exams = exams.filter((exam) => !exam.premium_only).length;
    const total_number_of_exams = exams.length;
    const redirectToPurchasePage = useRedirectToPurchasePage();

    return (
        <div className={""}>
            {exams.length < 1 && !isLoading ?
                <>
                    <div className={"flex flex-col min-w-[692px] mt-6 mx-8"}>
                        <div
                            className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <p className="mt-2 text-contentPrimary text-2xl font-extrabold font-inter-tight">No exams available!</p>
                            <p className="mt-2 text-contentSecondary text-sm font-normal font-inter leading-tight">Please come back later.</p>
                        </div>
                    </div>
                </>
                :
                <div className={"flex flex-col w-full h-full justify-center items-center p-6"}>
                    <div className={"grid grid-cols-2 mb-4 w-full"}>
                        <div
                            className={"flex justify-start flex-row items-center cursor-pointer"}
                        >
                            <Button plain onClick={goToExamHistory}>
                                <ClockIcon className={"size-3"}/>
                                History
                            </Button>
                        </div>
                        <div className={"flex justify-end items-center font-inter text-sm font-normal"}>
                            {subscriptionStatus ? (
                                    <div className={"flex items-center flex-row"}>
                                        <p>Your {companyConfig.company_name} subscription ends
                                            in {remainingDays} days</p>
                                        <Divider type={"vertical"} className={"mx-2.5 h-5"}/>
                                        <div onClick={redirectToPurchasePage}>
                                            <p className={"text-accent font-bold"}>Billing</p>
                                        </div>
                                    </div>
                                ) :
                                <div className={"flex items-center flex-row"}>
                                    <p>You have access to {number_of_free_exams} out
                                        of {total_number_of_exams} exams.</p>
                                    <Divider type={"vertical"} className={"mx-2.5 h-5"}/>
                                    <div className={'cursor-pointer'} onClick={redirectToPurchasePage}><p className={"text-accent font-bold"}>Give me more</p></div>
                                </div>
                            }
                        </div>
                    </div>
                    <img alt={"clock"} src={`${clockIcon}`} className={"h-[75px] w-[75px] mb-3.5"}/>
                    <div
                        className="text-black text-5xl font-medium font-inter-tight leading-[48px] mb-[18px] text-center">Choose
                        an exam to start
                    </div>
                    <p className={"text-stone-400 text-xl font-inter-tight leading-[18px] font-medium mb-[72px]"}>Made
                        with
                        love by {companyConfig.company_name}.</p>
                    <div className={"mt-4 max-w-[1200px]"}>
                        {isLoading ?
                            <div className={"grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 gap-x-[46px] gap-y-8"}>
                                <ExamCardSkeleton/>
                                <ExamCardSkeleton/>
                                <ExamCardSkeleton/>
                                <ExamCardSkeleton/>
                                <ExamCardSkeleton/>
                                <ExamCardSkeleton/>
                            </div>
                            :
                            <div className={"grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 gap-x-[46px] gap-y-8"}>
                                {exams.map((exam) => <ExamCard key={exam.id} exam={exam} selectExam={selectExam}
                                                               subscriptionStatus={subscriptionStatus}/>)}
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default ExamSelection;
