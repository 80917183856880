import React, { useState } from "react";
import { Input } from "../primitives/input";
import { CompanyConfigContext } from "../../CompanyConfigContextProvider";
import {Button} from "../primitives/button";

interface ExternalReferenceEntryProps {
    updateData: (data: {
        year_of_exam?: string;
        state_of_exam?: string;
        mobile_number?: string;
        external_reference?: string;
    }) => void;
    submitCurrentStep: () => void;
}

interface ExternalReferenceState {
    external_reference?: string;
}

const ExternalReferenceEntry: React.FC<ExternalReferenceEntryProps> = ({
                                                                           updateData,
                                                                           submitCurrentStep,
                                                                       }) => {
    const companyConfig = React.useContext(CompanyConfigContext);
    const [externalReference, setExternalReference] = useState<string>(""
    );

    const onChange = (newState: Partial<ExternalReferenceState>) => {
        const newExternalReference = newState.external_reference || "";
        setExternalReference(newExternalReference);
        updateData({ external_reference: newExternalReference });
    };

    const handleProceed = () => {
        submitCurrentStep()
    }

    return (
        <div className="container mx-auto w-[500px]">
            <div className="flex flex-col gap-4">
                <div>
                    <p className={'text-contentPrimary font-ppe-editorial-new-light text-[42px]'}>{companyConfig.company_name} Reference</p>
                </div>
                <div className="">
                    <span className="text-contentSecondary font-inter font-normal text-sm">{companyConfig.collect_external_reference_label}</span>
                    <div className={"mt-2 h-12"}>
                        <Input
                            type="text"
                            value={externalReference}
                            placeholder="Enter here"
                            onChange={(e) => onChange({external_reference: e.target.value})}
                        />
                    </div>
                </div>
                <div className={'flex justify-start w-full mt-3'}>
                    <Button onClick={handleProceed}>Continue</Button>
                </div>
            </div>
        </div>
    );
};

export default ExternalReferenceEntry;
