import React, { useEffect, useState } from 'react';
import { Group } from '@visx/group';
import { Bar } from '@visx/shape';
import { scaleLinear, scaleBand } from '@visx/scale';
import { LinearGradient } from '@visx/gradient';
import {PlusIcon} from "@heroicons/react/20/solid";
import {MinusIcon} from "@heroicons/react/16/solid";

interface SectionResultsBarsProps {
    sectionResults: Record<string, SectionResults>;
    totalQuestions: number;
    width: number;
    height: number;
    margin?: { top: number; right: number; bottom: number; left: number };
}

export default function SectionResultsBars(props: SectionResultsBarsProps) {
    const { sectionResults, totalQuestions, width, height, margin = { top: 10, right: 20, bottom: 50, left: 50 } } = props;
    const [data, setData] = useState<{ section: string; best_subtopic:string, worst_subtopic:string, value: number; cohort_value: number; }[]>([]);

    useEffect(() => {
        const sections = Object.keys(sectionResults);
        const formattedData = sections.map(section => ({
            section,
            best_subtopic: sectionResults[section].best_performing_subtopic,
            worst_subtopic: sectionResults[section].worst_performing_subtopic,
            value: Number((sectionResults[section].result_percentage * 100).toFixed(2)),
            cohort_value: Number((sectionResults[section].result_percentage_other_users * 100).toFixed(0)),
        }));
        setData(formattedData);
    }, [sectionResults]);

    const xMax = width - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;

    const xScale = scaleLinear({
        domain: [0, 100],
        range: [0, xMax],
    });
    const yScale = scaleBand<string>({
        domain: data.map(d => d.section),
        range: [0, yMax],
        padding: 0.2,
    });

    const barHeightFactor = 0.4;
    const barMarginLeft = 190
    return (
        <div className="flex items-center">
            <svg width={width} height={height}>
                <LinearGradient id="bar-gradient" from="#DAED7315" to="#DAED7360" vertical={false} />
                <Group top={margin.top} left={margin.left}>
                    {data.map((d, i) => (
                        <React.Fragment key={i}>
                            <Bar
                                x={margin.left + barMarginLeft}
                                y={yScale(d.section) + (yScale.bandwidth() - (yScale.bandwidth() * barHeightFactor)) / 2}
                                width={xScale(d.value)}
                                height={yScale.bandwidth() * barHeightFactor}
                                fill="url(#bar-gradient)"
                            />
                            <text
                                x={-margin.left / 2}
                                y={yScale(d.section) + yScale.bandwidth() / 2}
                                dy="0.32em"
                                fill="black"
                                fontSize={12}
                                textAnchor="start"
                                className={"font-inter leading-none text-base font-medium"}
                            >
                                {d.section}
                            </text>
                            <foreignObject
                                x={-margin.left / 2}
                                y={yScale(d.section) + yScale.bandwidth() * .9}
                                width={width *.9}
                                height={16}
                            >
                                <div className={"flex flex-row w-full"}>
                                    <div className={"font-inter leading-none text-xs font-medium text-contentSecondary grow"}>
                                        {d.cohort_value ? <>Average: {d.cohort_value}%</> :<>Not enough cohort data yet</>}
                                    </div>
                                    <div className="flex flex-row gap-x-8">
                                        <div className={"flex flex-row gap-x-2 items-center"}>
                                            <div
                                                className="bg-contentPositive rounded-full flex justify-center items-center size-3">
                                                <PlusIcon className="text-white"/>
                                            </div>
                                            <p className={"text-xs text-contentSecondary leading-none"}>{d.best_subtopic}</p>
                                        </div>
                                        <div className={"flex flex-row gap-x-2"}>
                                            <div
                                                className="bg-contentNegative rounded-full flex justify-center items-center size-3">
                                                <MinusIcon className="text-white"/>
                                            </div>
                                            <p className={"text-xs text-contentSecondary leading-none"}>{d.worst_subtopic}</p>
                                        </div>
                                    </div>
                                </div>

                            </foreignObject>
                            <text
                                x={margin.left + barMarginLeft}
                                y={yScale(d.section) + yScale.bandwidth() / 2}
                                dy="0.32em"
                                fill="black"
                                fontSize={12}
                                textAnchor="start"
                                className={"font-inter leading-none text-sm font-normal"}
                            >
                                {d.value}%
                            </text>
                        </React.Fragment>
                    ))}
                </Group>
            </svg>
        </div>
    );
}
