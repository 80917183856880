import React, {useState} from "react";
import { connect, ConnectedProps } from "react-redux";
import SettingsPage from "./SettingsPage";
import {Modal} from "../primitives/modal";
import { logout } from "../login/LoginActions";
import {
    DropdownMenu,
    DropdownMenuContent, DropdownMenuItem,
    DropdownMenuTrigger
} from "../ui/dropdown-menu";
import {ArrowRightStartOnRectangleIcon, BellIcon, LinkIcon, UserIcon} from "@heroicons/react/16/solid";
import {useToast} from "../ui/use-toast";
import PoweredByHatchery from "../fixedelements/PoweredByHatchery";

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
});

const connector = connect(mapStateToProps, { logout });
type PropsFromRedux = ConnectedProps<typeof connector>;

interface UserAvatarProps extends PropsFromRedux {}

const UserAvatar: React.FC<UserAvatarProps> = (props) => {
    const [showSettings, setShowSettings] = useState(false);
    const [settingsTab, setSettingsTab] = useState(0);
    const { toast } = useToast()
    const onLogoutClick = () => {
        props.logout(toast);
    };

    const handleOpenSettings = (tab: number) => {
        setSettingsTab(tab)
        setShowSettings(true);
    };

    return (
        <>
            <Modal open={showSettings} onClose={setShowSettings} size={"5xl"} className="absolute top-[60px] left-1/2 transform -translate-x-1/2  rounded-0.625rem p-0 overflow-y-auto max-h-calc(100vh - 120px)">
                <SettingsPage activeTab={settingsTab} setOpenModal={setShowSettings}/>
            </Modal>
            <DropdownMenu>
                <DropdownMenuTrigger>
                    <div
                         className={'bg-backgroundPrimary text-contentPrimary outline outline-1 outline-contentPrimary rounded-full size-8 text-sm font-inter flex items-center justify-center cursor-pointer'}
                    >
                        {!props.auth.user.username ?
                            "U"
                            :
                            props.auth.user.username.charAt(0).toUpperCase()
                        }
                    </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    <DropdownMenuItem onClick={()=>handleOpenSettings(0)}><UserIcon className={'size-4'}/>Account</DropdownMenuItem>
                    <DropdownMenuItem onClick={()=>handleOpenSettings(2)}><BellIcon className={'size-4'}/>Notifications</DropdownMenuItem>
                    <DropdownMenuItem onClick={()=>handleOpenSettings(1)}><LinkIcon className={'size-4'}/>Invite a friend</DropdownMenuItem>
                    {/*<DropdownMenuSeparator/>*/}
                    {/*{true && <DropdownMenuItem><EyeIcon className={'size-4'}/>View as student</DropdownMenuItem>}*/}
                    {/*<DropdownMenuSeparator/>*/}
                    <DropdownMenuItem onClick={onLogoutClick}><ArrowRightStartOnRectangleIcon className={'size-4'}/>Logout</DropdownMenuItem>
                    <div className={'text-3xs flex justify-center items-baseline py-0.5'}>
                        <PoweredByHatchery/>
                    </div>
                </DropdownMenuContent>
            </DropdownMenu>

        </>
    );
};

export default connector(UserAvatar);
