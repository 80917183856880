import React from 'react';

interface Props {
    marks: { [key: number]: { label: React.ReactNode } };
    defaultValue: number[];
    secondary?: boolean;
    name?: string;
    percentageActual?: number;
}

const Slider: React.FC<Props> = (props) => {

    const marks = props.marks;

    return (
        <div className="w-full">
            {Object.entries(marks).map(([key, value]) => {
                const keyValue = parseFloat(key);
                return (
                    <div key={key} className="flex flex-col">
                        <div className="flex flex-row w-full gap-x-[7px] relative items-center">
                            <div
                                className={`rounded-l-full h-1 ${keyValue >= 0 && keyValue <= 17.1 ? (!props.secondary ? 'bg-primaryA' : 'bg-primaryB') : 'bg-contentDisabled'}`}
                                style={{width: "17.1%"}}
                            ></div>
                            <div
                                className={` h-1 ${keyValue > 17.1 && keyValue < 82.9 ? (!props.secondary ? 'bg-primaryA' : 'bg-primaryB') : 'bg-contentDisabled'}`}
                                style={{width: "65.8%"}}
                            ></div>
                            <div
                                className={`rounded-r-full h-1 ${keyValue >= 82.9 && keyValue <= 100 ? (!props.secondary ? 'bg-primaryA' : 'bg-primaryB') : 'bg-contentDisabled'}`}
                                style={{width: "17.1%"}}
                            ></div>
                            <div
                                className={`absolute`}
                                style={{left: `${keyValue}%`}}
                            >
                                <div
                                    className={"rounded-full size-[14px] bg-accent border-[3px] outline-[1px] border-backgroundPrimary"}></div>
                                <div className={`absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-5`}>
                                    {value.label}
                                </div>
                            </div>

                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Slider;
