import axios from "axios";
import { SET_TOKEN, SET_CURRENT_USER, UNSET_CURRENT_USER } from "./LoginTypes";
import { setAxiosAuthToken, toastOnError } from "../../utils/Utils";
import posthog from "posthog-js";

export const login = (userData, navigate, toast) => async (dispatch) => {
    dispatch(unsetCurrentUser());

    await axios
        .post("/api/v1/login/", userData)
        .then((response) => {
            const auth_token = response.data.token;
            setAxiosAuthToken(auth_token);
            dispatch(setToken(auth_token));
            dispatch(getCurrentUser(navigate));
        })
        .catch((error) => {
            dispatch(unsetCurrentUser());
            if (
                error.response.status === 400 &&
                error.response.data &&
                error.response.data["detail"] === "User inactive or deleted."
            ) {
                navigate("/resend_activation");
            }
            toast({
                variant: "destructive",
                title: "Error",
                description: "Unable to login with the provided credentials.",
            });
        });
};

export const getCurrentUser = (navigate) => (dispatch) => {
    axios
        .get("/api/v1/users/me/")
        .then(async (response) => {
            const user = {
                username: response.data.username,
                email: response.data.email,
            };
            populateDownstreams(response.data.profile);
            dispatch(setCurrentUser(user, navigate));
        })
        .catch((error) => {
            dispatch(unsetCurrentUser());
            if (
                error.response &&
                error.response.status === 401 &&
                error.response.data?.detail === "User inactive or deleted."
            ) {
                navigate("/resend_activation");
            } else {
                toastOnError(error);
            }
        });
};

export const setCurrentUser = (user, navigate) => (dispatch) => {
    localStorage.setItem("user", JSON.stringify(user));
    dispatch({
        type: SET_CURRENT_USER,
        payload: user,
    });

    if (navigate) {
        navigate('/onboarding');
    }
};

export const setToken = (token) => (dispatch) => {
    setAxiosAuthToken(token);
    localStorage.setItem("token", token);
    dispatch({
        type: SET_TOKEN,
        payload: token,
    });
};

export const unsetCurrentUser = () => (dispatch) => {
    setAxiosAuthToken("");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    dispatch({
        type: UNSET_CURRENT_USER,
    });
};

export const logout = (toast, navigate) => (dispatch) => {
    axios
        .post("/api/v1/logout/")
        .then((response) => {
            posthog.reset();
            dispatch(unsetCurrentUser());
            toast({
                title: "Success",
                description: "You have logged out successfully.",
            });
            navigate("/login");
        })
        .catch((error) => {
            dispatch(unsetCurrentUser());
            toast({
                variant: "destructive",
                title: "Error",
                description: error.message || "An error occurred during logout.",
            });
        });
};

async function populateDownstreams(profile) {
    console.log("populating downstreams");
    await axios
        .get("/my_profile")
        .then((response) => {
            populatePostHog(response.data.profile);
        })
        .catch((error) => {
            console.log(error);
        });
}

function populatePostHog(userData) {
    posthog.init();
    posthog.identify(userData.user__email, {
        examyear: userData.year_of_exam,
        goal: userData.school_to_enter.name,
        exam: userData.state_of_exam,
        signedup: userData.user__created_at,
        mob: userData.mobile_numer,
        expiry: userData.subscription_expiry_date,
    });
}
