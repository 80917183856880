import React, { useState } from "react";
import {Button} from "../primitives/button";

interface YearSelectionProps {
    currentProfile?: CurrentProfile;
    updateData: (data: { year_of_exam: string }) => void;
    submitCurrentStep: () => void;
}

const YearSelection: React.FC<YearSelectionProps> = ({ currentProfile, updateData, submitCurrentStep }) => {
    const [yearOfExam, setYearOfExam] = useState<string>(currentProfile?.profile.year_of_exam || "");

    const updateState = (year: string) => {
        updateData({ year_of_exam: year });
        setStateAndSubmit(year);
    };

    const setStateAndSubmit = async (year: string) => {
        updateData({ year_of_exam: year });
        setYearOfExam(year);
        submitCurrentStep();
    };

    const thisAndNextFiveYears = (): number[] => {
        const currentYear = new Date().getFullYear();
        return Array.from({ length: 5 }, (_, i) => currentYear + i);
    };

    const yearSelectionButtons = () => {
        const years = thisAndNextFiveYears();
        return years.map((year) => (
            <Button
                key={year}
                className={'bg-backgroundPrimary h-10'}
                outline
                onClick={() => updateState(String(year))}
            >
                <div className="text-contentPrimary font-semibold font-inter text-base leading-normal">{year}</div>
            </Button>
        ));
    };

    return (
        <div className="w-full flex flex-col items-center">
            <p className="text-center font-ppe-editorial-new-light text-contentPrimary text-[40px] leading-10 mb-9">
                What year are you sitting the exam?
            </p>
            <div className="mb-6 justify-center w-full flex flex-row gap-x-6">
                {yearSelectionButtons()}
            </div>
            <div className="mb-10">
                <Button plain onClick={submitCurrentStep}><p className={'text-contentPrimary font-semibold text-base'}>None of the above / I don&apos;t know</p></Button>
            </div>
        </div>
    );
};

export default YearSelection;
