import {QuestionMarkCircleIcon} from "@heroicons/react/16/solid";
import React, {useState} from "react";
import clsx from "clsx";


interface DashboardComponentTooltipProps {
    content: string;
    className?: string;
}

const DashboardComponentTooltip: React.FC<DashboardComponentTooltipProps> = ({ content, className }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div className="relative">
            <div
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                className={clsx("cursor-pointer", className)}
            >
                <QuestionMarkCircleIcon className="size-full text-contentTertiary" />
            </div>
            {showTooltip && (
                <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 w-max bg-white text-black text-sm p-2 rounded border border-borderOpaque z-10">
                    <p className={'font-inter text-2xs'}>{content}</p>
                </div>
            )}
        </div>
    );
};

export default DashboardComponentTooltip;
