import React from "react";

type ExplanationSidebarProps = {
    explanation: string,
    feedback: string,
    categories: AnnotationCategory[],
}

type AnnotationCategory = {
    name: string,
    color: string,
}

function ExplanationSidebar(props: ExplanationSidebarProps | null) {
    return (
        <div className={"font-inter flex flex-col justify-center text-left px-2 mt-6 w-full"}>
            <p className={"text-xs font-normal leading-tight text-contentTertiary"}>Explanation:</p>
            <p className={"text-xs font-normal text-contentPrimary mt-2.5"}>{props?.explanation}</p>
            {/*<div className={"text-sm font-normal mt-4"} style={{display:"flex",justifyContent:"right"}}><button style={{backgroundColor:"#E7E5E4", color:"#78716C"}}>Save this feedback</button></div>*/}
        </div>
    )

}
export default ExplanationSidebar;