import React, { useEffect, useState } from "react";
import axios from "axios";
import {Popover, PopoverContent, PopoverTrigger} from "../ui/popover";
import {Check, ChevronsUpDown} from "lucide-react";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "../ui/command";
import {cn} from "../../utils/ShadcnUtils";
import {Button} from "../primitives/button";

interface School {
    id: number;
    name: string;
}


interface SchoolSelectionProps {
    currentProfile?: CurrentProfile;
    updateData: (data: { selected_school: number; }) => void;
    submitCurrentStep: () => void;
}

const SchoolSelection: React.FC<SchoolSelectionProps> = ({ currentProfile, updateData, submitCurrentStep }) => {
    const [selectedSchool, setSelectedSchool] = useState<string>(
        currentProfile?.profile.school_to_enter?.name || ""
    );
    const [schoolList, setSchoolList] = useState<School[]>([]);

    const onChange = (newSchool: School) => {
        setSelectedSchool(newSchool.name);
        console.log(newSchool)
        updateData({
            selected_school: newSchool.id,
        });
        submitCurrentStep();
    };

    const getSchoolList = async () => {
        try {
            const response = await axios.get('/get_all_schools');
            setSchoolList(response.data.schools);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getSchoolList();
    }, []);

    const [open, setOpen] = React.useState(false)
    const [value, setValue] = React.useState("")

    return (
        <div className="w-full flex flex-col items-center">
            <p className="text-center font-ppe-editorial-new-light text-contentPrimary text-[40px] leading-10 mb-10">
                What school do you want to attend?
            </p>
            <div className={'bg-white'}>
                <Popover open={open} onOpenChange={setOpen}>
                    <PopoverTrigger asChild>
                        <Button
                            outline
                            role="combobox"
                            aria-expanded={open}
                            className="w-[200px] justify-between "
                        >
                            <p className={'text-contentTertiary font-normal text-sm'}>
                                {value
                                ? schoolList.find((school) => school.name === value)?.name
                                : "Select one"}
                            </p>
                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-[200px] p-0 bg-white">
                        <Command>
                            <CommandInput placeholder="Select one" />
                            <CommandList>
                                <CommandEmpty>No school found.</CommandEmpty>
                                <CommandGroup>
                                    {schoolList.map((school) => (
                                        <CommandItem
                                            key={school.id}
                                            value={school.name}
                                            onSelect={(currentValue) => {
                                                setValue(currentValue === value ? "" : currentValue)
                                                setOpen(false)
                                                onChange(school)
                                            }}
                                        >
                                            <Check
                                                className={cn(
                                                    "mr-2 h-4 w-4",
                                                    value === school.name ? "opacity-100" : "opacity-0"
                                                )}
                                            />
                                            {school.name}
                                        </CommandItem>
                                    ))}
                                </CommandGroup>
                            </CommandList>
                        </Command>
                    </PopoverContent>
                </Popover>
            </div>
            {/*<Field>*/}
            {/*    <Listbox name="status" className="w-[500px]" placeholder="Select one school">*/}
            {/*        {schoolList.map((school) => (*/}
            {/*            <ListboxOption*/}
            {/*                key={school.id}*/}
            {/*                value={school.name}*/}
            {/*                onClick={() => onChange(school)}*/}
            {/*            >*/}
            {/*                <ListboxLabel>{school.name}</ListboxLabel>*/}
            {/*            </ListboxOption>*/}
            {/*        ))}*/}
            {/*    </Listbox>*/}
            {/*</Field>*/}
        </div>
    );
};

export default SchoolSelection;
