import React, {useRef, useState} from "react";
import {ErrorBoundary} from "react-error-boundary";
import {CadenceResults} from "./CadenceResults";
import {
    BoltIcon,
    ChartBarIcon,
    ChartPieIcon, ClipboardDocumentCheckIcon,
    Cog6ToothIcon,
    PencilIcon
} from "@heroicons/react/16/solid";
import Breadcrumbs from "../primitives/breadcrumbs";
import {QuestionResults} from "./QuestionResults";
import SectionResults from "./SectionResults";
import {ParentSize} from "@visx/responsive";
import DistributionResults from "./DistributionResults";
import {WritingResults} from "./WritingResults";
import errorScreen from "./ErrorScreen";
import {ToggleTag} from "../primitives/ToggleTag";
import {SpeedVsAccuracy} from "./SpeedVsAccuracyResults";


interface OverviewResultsProps {
    examResults: ExamResultsType
    sectionResults: Record<string, SectionResults>;
}

export function OverviewResults(props: OverviewResultsProps) {
    const [reviewAnswers, setReviewAnswers] = useState(false);
    const performanceOverallRef = useRef(null);
    const performanceSectionRef = useRef(null);
    const writingFeedbackRef = useRef(null);
    const cadenceRef = useRef(null);
    const speedVsAccuracyRef = useRef(null);


    function lowestPerformingSection(sectionResults: Record<string, SectionResults>) {
        let lowestScore = 100;
        let lowestSection: string | null = null;
        const sectionKeys = Object.keys(sectionResults);

        for (const key of sectionKeys) {
            const section = sectionResults[key];
            if (section.result_percentage < lowestScore) {
                lowestScore = section.result_percentage;
                lowestSection = key;
            }
        }
        return lowestSection;
    }

    function highestPerformingSection(sectionResults: Record<string, SectionResults>) {
        let highestScore = 0;
        let highestSection: string | null = null;
        const sectionKeys = Object.keys(sectionResults);

        for (const key of sectionKeys) {
            const section = sectionResults[key];
            if (section.result_percentage > highestScore) {
                highestScore = section.result_percentage;
                highestSection = key;
            }
        }
        return highestSection;
    }

    const pages = [
        { name: 'Exams', href: '/exams', current: false },
        { name: 'Exam History', href: '/exam_history', current: false },
        { name: 'Data Room', href: '#', current: true },
    ];

    return (
        <div className={"w-full h-full flex flex-col gap-y-8 "}>
            {!reviewAnswers ?
                <div className={"columns-1 3xl:columns-2 gap-x-8 pb-8"}>
                    <div className={"flex flex-col gap-y-8"}>
                        <div className={"flex flex-col gap-y-6"}>
                            <Breadcrumbs pages={pages}/>
                            <section className={"flex flex-col gap-y-8"}>
                                <p className="text-[46px] font-normal font-ppe-editorial-new-light leading-10 text-contentPrimary">
                                    {props.examResults.percentage !== null ?
                                        (
                                            <span>You scored {(props.examResults.percentage * 100).toFixed(0)}% on the {props.examResults.exam_name}. <br/></span>)
                                        :
                                        <span>{props.examResults.exam_name}<br/></span>
                                    }
                                </p>
                                <div className={"flex flex-col gap-y-4"}>
                                    <p className={"text-base font-semibold font-inter leading-none text-contentPrimary px-3"}>
                                        <span className={"text-contentSecondary"}>User: </span> <span className={"bg-backgroundSecondary"}>@{props.examResults.pseudonym}</span>
                                    </p>
                                    <div className={"flex flex-wrap gap-x-2.5"}>
                                        <ToggleTag onClick={() => performanceOverallRef.current.scrollIntoView({ behavior: 'smooth' })}>
                                            <ChartBarIcon className={"size-4"}/>Performance (Overall)
                                        </ToggleTag>
                                        <ToggleTag onClick={() => performanceSectionRef.current.scrollIntoView({ behavior: 'smooth' })}>
                                            <ChartPieIcon className={"size-4"}/>Performance (Section)
                                        </ToggleTag>
                                        {props.examResults.essay_id.length > 0 && (
                                            <ToggleTag onClick={() => writingFeedbackRef.current.scrollIntoView({ behavior: 'smooth' })}>
                                                <PencilIcon className={"size-4"}/>Writing Feedback
                                            </ToggleTag>
                                        )}
                                        <ToggleTag onClick={() => cadenceRef.current.scrollIntoView({ behavior: 'smooth' })}>
                                            <BoltIcon className={"size-4"}/>Cadence
                                        </ToggleTag>
                                        <ToggleTag onClick={() => speedVsAccuracyRef.current.scrollIntoView({ behavior: 'smooth' })}>
                                            <Cog6ToothIcon className={"size-4"}/>Speed Vs Accuracy
                                        </ToggleTag>
                                        {props.examResults.percentage !== null &&
                                            <ToggleTag onClick={() => setReviewAnswers(!reviewAnswers)}>
                                                <ClipboardDocumentCheckIcon className={"size-4"}/>View Answers
                                            </ToggleTag>
                                        }
                                    </div>
                                </div>
                            </section>
                            {/*{props.examResults.percentage !== null &&*/}
                            {/*    <div className={"flex flex-row gap-x-2"}>*/}
                            {/*        <Button onClick={() => setReviewAnswers(!reviewAnswers)}>*/}
                            {/*            Review your answers <ArrowRightIcon/>*/}
                            {/*        </Button>*/}
                            {/*    </div>*/}
                            {/*}*/}
                        </div>
                        {props.examResults.percentage !== null ?
                            <>
                                <section
                                    ref={performanceOverallRef}
                                    className={"flex items-center break-inside-avoid-column w-full justify-center"}>
                                    <div className={"flex flex-wrap gap-3 max-w-[900px] w-full h-full"}>
                                        <div
                                            className={"border-borderInverseOpaque border-[.5px] rounded-lg flex flex-col w-full"}>
                                            <div
                                                className={"border-borderInverseOpaque bg-slate-300 border-b-[.5px] text-contentPrimary rounded-t-lg px-6 text-lg font-sf-pro leading-[28px] py-1 text-left"}>
                                                {!props.examResults.bell_curve_labels ?
                                                    <p className={"text-left"}>Not enough data cohort data yet, come back later</p>
                                                    :
                                                    <>
                                                        You are performing at
                                                        <b>&nbsp;{props.examResults.percentile > 50 ? "an above" : "a below"} average </b>
                                                        standard for this exam
                                                    </>
                                                }
                                            </div>
                                            <div className={"bg-grey-100 h-[600px] rounded-b-lg"}>
                                                <ErrorBoundary fallback={errorScreen()}>
                                                    <ParentSize>{({width, height}) => <DistributionResults width={width}
                                                                                                           height={height}
                                                                                                           examResults={props.examResults}
                                                                                                           sectionResults={props.sectionResults}
                                                    />}</ParentSize>
                                                </ErrorBoundary>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section
                                    ref={performanceSectionRef}
                                    className={"flex items-center break-inside-avoid-column w-full justify-center"}>
                                    <div className={"flex flex-wrap gap-3 max-w-[900px] w-full h-full"}>
                                        <div
                                            className={"border-borderInverseOpaque border-[.5px] rounded-lg flex flex-col w-full"}>
                                            <div
                                                className={"border-borderInverseOpaque bg-lime-300/50 border-b-[.5px] text-contentPrimary rounded-t-lg px-6 text-lg font-sf-pro leading-[28px] py-1 text-left"}>
                                                <p>Your highest performing section is&nbsp;
                                                    <b>{highestPerformingSection(props.sectionResults)}. </b>
                                                    Your lowest performing section is&nbsp;
                                                    <b>{lowestPerformingSection(props.sectionResults)}.</b></p>
                                            </div>
                                            <div className={"bg-lime-300/20 h-[400px]"}>
                                                <ErrorBoundary fallback={errorScreen()}>
                                                    <ParentSize>{({width, height}) => <SectionResults width={width}
                                                                                                      height={height}
                                                                                                      sectionResults={props.sectionResults}
                                                                                                      totalQuestions={props.examResults.total_questions}/>}</ParentSize>
                                                </ErrorBoundary>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section
                                    ref={speedVsAccuracyRef}
                                    className={"flex items-center break-inside-avoid-column w-full justify-center"}>
                                    <SpeedVsAccuracy sectionResults={props.sectionResults} examResults={props.examResults}/>
                                </section>
                                <section
                                    ref={cadenceRef}
                                    className={"flex items-center break-inside-avoid-column w-full justify-center"}>
                                    <CadenceResults sectionResults={props.sectionResults} examResults={props.examResults}/>
                                </section>
                                {/*<section*/}
                                {/*    className={"break-inside-avoid-column"}>*/}
                                {/*    <CompletionResults examResults={props.examResults}*/}
                                {/*                       sectionResults={props.sectionResults}/>*/}
                                {/*</section>*/}
                                {props.examResults.essay_id && (
                                    <div ref={writingFeedbackRef} className={"flex flex-col gap-y-8"}>
                                        {props.examResults.essay_id.map((id, index) => (
                                            <section key={id} className="flex items-center break-inside-avoid-column w-full justify-center">
                                                <WritingResults index={index + 1} essay_id={id} />
                                            </section>
                                        ))}
                                    </div>
                                )}
                            </>
                            :
                            <div>
                                An error has occurred. Please contact Hatchery support if this continues.
                            </div>
                        }
                    </div>
                </div>
                :
                <QuestionResults sectionResults={props.sectionResults}
                                 sectionSwap={() => setReviewAnswers(!reviewAnswers)}/>
            }
        </div>
    );
}
