import React from "react";
import DashboardComponentTooltip from "../../metrics/DashboardComponentTooltip";
import { Button } from "../../primitives/button";
import {CompanyConfigContext} from "../../../CompanyConfigContextProvider";

interface Product {
    id: string;
    name: string;
    months: number;
    price: number;
    original_price?: number;
}

interface ProductCheckoutCardProps {
    selectedProduct: Product;
    percentDiscount: number;
    checkout: boolean;
    onClick: ()=> void;
    enableCoupon: () => void;
}

const ProductCheckoutCard: React.FC<ProductCheckoutCardProps> = ({ selectedProduct, percentDiscount, enableCoupon }) => {
    const companyConfig = React.useContext(CompanyConfigContext);
    const currentDate = new Date();
    const expiryDate = new Date(currentDate);
    expiryDate.setMonth(currentDate.getMonth() + selectedProduct.months);

    const finalAmount =
        selectedProduct.price -
        (selectedProduct.price * (percentDiscount / 100));

    const formattedExpiryDate = `${
        expiryDate.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit' })
    } AEDT ${
        expiryDate.toLocaleString('en-US', { month: 'short' })
    } ${expiryDate.toLocaleString('en-US', { day: 'numeric' })} ${
        expiryDate.toLocaleString('en-US', { year: 'numeric' })
    }`;

    return (
        <div className={"w-full flex flex-col px-6 py-4 bg-backgroundPrimary text-contentPrimary border-[0.5px] border-greyHatchery rounded-lg"}>
            <div className={"flex flex-row gap-x-1 mb-2 items-center"}>
                <p className={"text-2xs"}>Your price today</p>
                <div className={"size-2.5"}>
                    <DashboardComponentTooltip
                        content={`As ${companyConfig.company_name} progressively releases features, the early-bird discount will decrease, and the overall price will increase.`}
                    />
                </div>
            </div>
            <div className={"flex flex-row gap-x-5 mb-3"}>
                <p className={"flex font-medium font-inter-tight leading-10 text-[32px] items-end"}>
                    ${selectedProduct.price}
                </p>
                <p className={"flex font-inter-tight font-medium items-end text-contentNegative"}>
                    {selectedProduct.original_price ? (
                        <span>
                            <s>${selectedProduct.original_price}</s>
                        </span>
                    ) : null}
                </p>
            </div>
            <div className={"mb-8 text-stone-400 font-bold font-inter text-[10px] leading-4"}>
                Expires {formattedExpiryDate}
            </div>
            <div className={"flex flex-col gap-y-1.5 mb-10"}>
                <p className={"font-inter leading-[18px] text-xs font-bold text-contentPrimary"}>Summary</p>
                <div className={"flex flex-col gap-y-1 text-[8px] text-stone-600 border-b border-dotted pb-1"}>
                    <div className={"grid grid-cols-2"}>
                        <p className={"flex justify-start"}>
                            {selectedProduct.name.split(" ")[0].toUpperCase()} X{" "}{selectedProduct.months} months
                        </p>
                        <p className={"flex justify-end"}>${selectedProduct.price}</p>
                    </div>
                    {selectedProduct.original_price &&
                        <div className={"grid grid-cols-2"}>
                            <div className={"flex justify-start flex-row gap-x-1 items-center"}>
                                <p>Early bird discount</p>
                                <div className={"size-2.5"}><DashboardComponentTooltip
                                    content={"As Hatchery progressively releases features, the early-bird discount will decrease, and the overall price will increase."}
                                />
                                </div>
                            </div>
                            <p className={"flex justify-end text-positive"}>
                                -${selectedProduct.original_price - selectedProduct.price}
                            </p>
                        </div>
                    }
                    {percentDiscount > 0 &&
                        <div className={"grid grid-cols-2"}>
                            <p className={"flex justify-start"}>Coupon discount</p>
                            <p className={"flex justify-end text-positive"}>-{percentDiscount}%</p>
                        </div>
                    }
                </div>
                <div className={"grid grid-cols-2 text-[8px] text-stone-600 items-center"}>
                    <p className={"flex justify-start"}>Total amount</p>
                    <p className={"flex justify-end font-extrabold text-sm leading-3"}>${finalAmount}</p>
                </div>
            </div>
            <Button outline onClick={enableCoupon}>
                I have a coupon code
            </Button>
        </div>
    );
};

export default ProductCheckoutCard;
