import React, {useState} from "react";
import {ErrorBoundary} from "react-error-boundary";
import {CohortCompletionHeatmap} from "./CohortCompletionHeatmap";
import errorScreen from "./ErrorScreen";


interface CompletionResultsProps {
    examResults: ExamResultsType
    sectionResults: Record<string, SectionResults>;
}

export function CompletionResults(props: CompletionResultsProps) {
    const [currentSectionCohort, setCurrentSectionCohort] = useState<number>(0)

    function questionCompletion(examResults: ExamResultsType):number {
        return (examResults.exam_question_completion_rate / examResults.total_questions * 100);
    }

    return (
        <div className={"w-full h-full flex flex-col"}>
            <section className={"flex items-center mx-auto"}>
                <div className={"flex flex-wrap gap-3 w-full"}>
                    <div
                        className={"border-borderInverseOpaque border-2 bg-amber-50 rounded-3xl flex flex-col"}>
                        <div
                            className={"border-borderInverseOpaque border-b-2 mb-2 text-contentSecondary rounded-t-3xl px-6 text-lg font-sf-pro leading-[28px] py-1 text-center"}>
                            Your completion rate is at
                            <b>&nbsp;{questionCompletion(props.examResults) > 50 ? "an above" : "a below"} average </b>
                            standard for this exam
                        </div>
                        <div className={"flex flex-row items-center justify-center gap-x-2.5 pt-2 px-3"}>
                            {Object.keys(props.sectionResults).map((sectionName, index) => (
                                <div
                                    className={`cursor-pointer px-2 py-1 rounded-[10px] text-sm ${index === currentSectionCohort ? 'text-amber-500 bg-yellow-100' : 'text-contentTertiary'}`}
                                    key={index} onClick={() => setCurrentSectionCohort(index)}>{sectionName}</div>
                            ))}
                        </div>
                        {Object.keys(props.sectionResults).map((sectionName, index) => (
                            <div key={index}>
                                {index === currentSectionCohort &&
                                    <ErrorBoundary fallback={errorScreen()}>
                                        <CohortCompletionHeatmap section={props.sectionResults[sectionName]} name={sectionName}/>
                                    </ErrorBoundary>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}
