import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toastOnError } from '../../utils/Utils';
import MetricGuage from './MetricGauge';
import DashboardComponentTooltip from './DashboardComponentTooltip';

interface MetricsData {
    [key: string]: {
        correct_count?: number;
        incorrect_count?: number;
        correct_percentage?: number;
    };
}

const ContentAreaMetricsList: React.FC = () => {
    const [metrics, setMetrics] = useState<MetricsData>({});

    useEffect(() => {
        getMetrics();
    }, []);

    const getMetrics = () => {
        axios.get("/api/v1/usercontentareametrics/").then(response => {
            // response.data = {
            //     "correct": [
            //         {
            //             "sub_topic__topic__content_area__name": "General Ability - Quantitative",
            //             "correct_count": 1170.7
            //         },
            //         {
            //             "sub_topic__topic__content_area__name": "General Ability - Verbal",
            //             "correct_count": 4142.5
            //         },
            //         {
            //             "sub_topic__topic__content_area__name": "Reasoning - Mathematics",
            //             "correct_count": 6177.7
            //         },
            //         {
            //             "sub_topic__topic__content_area__name": "Reasoning - Reading",
            //             "correct_count": 8005.4
            //         }
            //     ],
            //     "incorrect": [
            //         {
            //             "sub_topic__topic__content_area__name": "General Ability - Quantitative",
            //             "incorrect_count": 2330.4
            //         },
            //         {
            //             "sub_topic__topic__content_area__name": "General Ability - Verbal",
            //             "incorrect_count": 12401.1
            //         },
            //         {
            //             "sub_topic__topic__content_area__name": "Reasoning - Mathematics",
            //             "incorrect_count": 20237.9
            //         },
            //         {
            //             "sub_topic__topic__content_area__name": "Reasoning - Reading",
            //             "incorrect_count": 18293.7
            //         }
            //     ]
            // }
            const metricsData: MetricsData = {};

            response.data.correct.forEach(item => {
                const skillName = item.sub_topic__topic__content_area__name;
                metricsData[skillName] = { correct_count: item.correct_count };
            });

            response.data.incorrect.forEach(item => {
                const skillName = item.sub_topic__topic__content_area__name;
                metricsData[skillName] = {
                    ...metricsData[skillName],
                    incorrect_count: item.incorrect_count,
                };
            });
            // for each skill calulate the percentage of correct answers
            for (const [key, value] of Object.entries(metricsData)) {
                const totalCount = (value.correct_count || 0) + (value.incorrect_count || 0);
                metricsData[key].correct_percentage = Math.round((value.correct_count || 0) / totalCount * 100);
            }
           setMetrics(metricsData);

        }).catch(error => {
            toastOnError(error);
        })
    }

    const progress_bars: JSX.Element[] = [];
    for (const [key, value] of Object.entries(metrics)) {
        if (key === 'null') {
            continue;
        }

        let mark_with_user_value: { [key: number]: { style?: React.CSSProperties; label: JSX.Element } } = {};
        mark_with_user_value[value.correct_percentage || 0] = {
            style: { color: 'orange' },
            label: (
                <div className=" text-accent font-ibm-mono text-sm ">
                    <span className="text-sm font-semibold ">{value.correct_percentage}</span>
                </div>
            ),
        };
        mark_with_user_value = { ...mark_with_user_value };

        let default_values: number[] | null = null;
        if (value.correct_percentage && value.correct_percentage < 25) {
            default_values = [0, 25];
        } else if (value.correct_percentage && value.correct_percentage > 75) {
            default_values = [75, 100];
        } else {
            default_values = [25, 75];
        }
        progress_bars.push(
            <MetricGuage
                name={key}
                key={key}
                marks={mark_with_user_value}
                defaultValue={default_values || [0, 0]}
                secondary={false}
                percentageActual={value.correct_percentage || 0}
            />
        );
    }

    const tooltipMessage = "Metrics related to each of the exam's content areas";

    return (
        <div className="pt-6 px-6 w-full">
            <div className="flex flex-row items-center gap-x-2 mb-5 max-w-[622px]">
                <div className="text-zinc-950 text-2xl font-normal font-ppe-editorial-new-light leading-tight">Content
                    Health
                </div>
                <div className={"pb-1"}><DashboardComponentTooltip className={'size-4'} content={tooltipMessage}/></div>
            </div>
            {progress_bars}
        </div>
    );
};

export default ContentAreaMetricsList;
