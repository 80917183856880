import React from "react";
import {
    Pagination,
    PaginationGap,
    PaginationList,
    PaginationNext,
    PaginationPage,
    PaginationPrevious,
} from "../primitives/pagination";

interface QuestionPaginatorProps {
    currentQuestion: number;
    totalQuestions: number;
    onChange: (page: number) => void;
}

const QuestionPaginator: React.FC<QuestionPaginatorProps> = ({ currentQuestion, totalQuestions, onChange }) => {
    // Calculate the total number of pages
    const totalPages = Math.ceil(totalQuestions);

    // Calculate the current page
    const currentPage = Math.ceil(currentQuestion + 1);

    // Generate an array of page numbers based on the current page
    const generatePageNumbers = (): number[] => {
        const pageNumbers: number[] = [];
        const totalPagesToShow = 5;

        // Calculate the starting page to ensure 5 numbers are always displayed
        let startPage = Math.max(2, currentPage - Math.floor(totalPagesToShow / 2));

        // If the starting page is too close to the beginning, adjust it
        if (startPage + totalPagesToShow - 1 > totalPages - 1) {
            startPage = Math.max(1, totalPages - totalPagesToShow + 1);
        }

        // Calculate the ending page based on the starting page
        const endPage = Math.min(totalPages - 1, startPage + totalPagesToShow - 1);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };

    return (
        <div className={"flex items-center justify-center my-6"}>
            <Pagination>
                <div className={"cursor-pointer"} onClick={() => onChange(currentPage - 1)}>
                    <PaginationPrevious inactive={currentPage === 1} href={null} />
                </div>
                <div className={"cursor-pointer"} onClick={() => onChange(1)}>
                    <PaginationPage href={null} current={1 === currentPage}>
                        1
                    </PaginationPage>
                </div>
                <PaginationList>
                    <PaginationGap />
                    {generatePageNumbers().map((pageNumber) => (
                        <div className={"cursor-pointer"} key={pageNumber} onClick={() => onChange(pageNumber)}>
                            <PaginationPage href={null} current={pageNumber === currentPage}>
                                {String(pageNumber)}
                            </PaginationPage>
                        </div>
                    ))}
                    <PaginationGap />
                    <div className={"cursor-pointer"} onClick={() => onChange(totalPages)}>
                        <PaginationPage href={null} current={totalPages === currentPage}>
                            {String(totalPages)}
                        </PaginationPage>
                    </div>
                </PaginationList>
                <div className={"cursor-pointer"} onClick={() => onChange(currentPage + 1)}>
                    <PaginationNext href={null} inactive={currentPage === totalPages} />
                </div>
            </Pagination>
        </div>
    );
};

export default React.memo(QuestionPaginator);
