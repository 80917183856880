import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import StateSelection from "./StateSelection";
import YearSelection from "./YearSelection";
import UserInformationEntry from "./UserInformationEntry";
import SchoolSelection from "./SchoolSelection";
import { Button } from "../primitives/button";
import { CompanyConfigContext } from "../../CompanyConfigContextProvider";
import {useToast} from "../ui/use-toast";
import ExternalReferenceEntry from "./ExternalReferenceEntry";
import {useNavigate} from "react-router-dom";

interface Data {
    year_of_exam?: string;
    state_of_exam?: string;
    mobile_number?: string;
    external_reference?: string;
    selected_school?: number;
}

const OnboardingStateMachine: React.FC = () => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [data, setData] = useState<Data>({});
    const [onBoardingRequired, setOnBoardingRequired] = useState<boolean>(false);
    const [currentProfile, setCurrentProfile] = useState<CurrentProfile | null>(null);
    const companyConfig = useContext(CompanyConfigContext);
    const { toast } = useToast()
    const [invalidPhoneNumber,setInvalidPhoneNumber] = useState<boolean>(false);

    const updateData = (data: Data) => {
        setData(data);
    };

    const submitCurrentStep = async () => {
        await axios.post("/update_profile", data).then(async (r) => {
            console.log(data);
            if (
                !data.year_of_exam &&
                !currentProfile?.profile.year_of_exam &&
                currentStep === 1
            ) {
                toast({
                    variant: "destructive",
                    title: "Error",
                    description: "Select one of the options.",
                })
                return;
            }
            if (
                !data.state_of_exam &&
                !currentProfile?.profile.state_of_exam &&
                currentStep === 0
            ) {
                toast({
                    variant: "destructive",
                    title: "Error",
                    description: "Select one of the options.",
                })
                return;
            }
            if (data.mobile_number && currentStep === 3) {
                if (isNaN(Number(data.mobile_number)) || data.mobile_number.length > 12 || data.mobile_number.length < 10) {
                    toast({
                        variant: "destructive",
                        title: "Invalid mobile number",
                        description: "Please enter a valid number.",
                    })
                    setInvalidPhoneNumber(true)
                    return;
                }
            }
            await getCurrentProfile();
            nextStep();
        });
    };

    const getCurrentProfile = async () => {
        const response = await axios.get("/get_current_profile_for_onboarding");
        setCurrentProfile(response.data);
    };

    const [maxStep, setMaxStep] = useState<number>(3);

    useEffect(() => {
        document.title = `Onboarding — ${companyConfig.company_name}`;
        doesUserRequireOnboarding();
        if (companyConfig.state && companyConfig.state === "None") {
            setCurrentStep(0);
        } else {
            setCurrentStep(1)
        }
        getCurrentProfile();
        // if (companyConfig.collect_external_reference) {
        //     setMaxStep(4)
        // }
    }, [companyConfig]);

    const doesUserRequireOnboarding = () => {
        axios
            .get("/user_needs_onboarding")
            .then((response) => {
                if (!response.data.needs_onboarding) {
                    navigate("/dashboard");
                } else {
                    setOnBoardingRequired(true);
                }
            })
            .catch(() => {
                navigate("/dashboard");
            });
    };

    const nextStep = () => {
        if (currentStep === maxStep) {
            navigate("/dashboard");
        }

        setCurrentStep((prevStep) => prevStep + 1);
    };

    const previousStep = () => {
        getCurrentProfile();
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const steps = [
        <StateSelection
            key={1}
            updateData={updateData}
            submitCurrentStep={submitCurrentStep}
            currentProfile={currentProfile}
        />,
        <YearSelection
            key={2}
            updateData={updateData}
            submitCurrentStep={submitCurrentStep}
            currentProfile={currentProfile}
        />,
        <SchoolSelection
            key={3}
            updateData={updateData}
            currentProfile={currentProfile}
            submitCurrentStep={submitCurrentStep}
        />,
        <UserInformationEntry
            key={4}
            updateData={updateData}
            currentProfile={currentProfile}
            submitCurrentStep={submitCurrentStep}
            invalidPhoneNumber={invalidPhoneNumber}
        />,
        <ExternalReferenceEntry
            key={5}
            updateData={updateData}
            submitCurrentStep={submitCurrentStep}
        />,
    ];

    if (!onBoardingRequired) {
        return null;
    }

    return (
        <div className="h-full bg-hatcheryGrey flex flex-col">
            <div className="flex justify-start p-7">
                <div className={"size-8 flex items-center justify-center"}>
                    <img className={"size-fit"}
                         src={companyConfig.company_logo_navbar}
                         alt="Company Logo"/>
                </div>
            </div>
            <div className="grow">
                <div className="flex items-center h-full">{steps[currentStep]}</div>
            </div>
            {(currentStep !== 0 && (currentStep !== 1 && companyConfig.state && companyConfig.state === "None")) && (
                <div
                    className="w-full grid-cols-2 grid border-t border-borderOpaque px-40 py-3.5">

                    <div className="flex justify-start">

                        <Button
                            onClick={previousStep}
                            outline
                            className="cursor-pointer"
                        >
                            Back
                        </Button>

                    </div>


                    {/*<div className="flex justify-end">*/}
                    {/*    <Button*/}
                    {/*        onClick={submitCurrentStep}*/}
                    {/*        color="secondary"*/}
                    {/*        className="cursor-pointer"*/}
                    {/*    >*/}
                    {/*        Next*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                </div>
            )}
        </div>
    );
};

export default OnboardingStateMachine;
