import axios from "axios";
import React, { useEffect, useState } from "react";
import hashIcon from "../../assets/hash.svg";
import clockIcon from "../../assets/clock.svg";
import targetIcon from "../../assets/target.svg";
import {Button} from "../primitives/button";
import {IoCaretForward} from "react-icons/io5";
import Breadcrumbs from "../primitives/breadcrumbs";

interface ExamConfirmProps {
    exam: Exam;
    startExam: (exam: Exam) => void;
}

function ExamConfirm(props: ExamConfirmProps) {
    const [totalTime, setTotalTime] = useState<number | null>(null);
    const [totalQuestions, setTotalQuestions] = useState<number | null>(null);
    const [qpm, setQpm] = useState<number | null>(null);

    const requestParams = {
        exam_id: props.exam.id,
    };

    useEffect(() => {
        axios
            .get("/exam_info/", { params: requestParams })
            .then((response) => {
                setTotalTime(response.data.total_time);
                setTotalQuestions(response.data.total_questions);
                // calculate qpm (round to integer)
                setQpm(Math.round(response.data.total_time / response.data.total_questions));
            })
            .catch((error) => {
                // Handle error if the request fails
                console.error(error);
            });
    }, []);

    const pages = [
        { name: 'Exams', href: '/exams', current: false },
        { name: props.exam.name, href: '#', current: true },
    ];

    return (
        <div className={"w-full p-[16px] flex flex-col min-h-full"}>
            <div className={""}>
                <Breadcrumbs pages={pages}/>
            </div>
            <div className={"mt-5 flex justify-center min-h-full items-center grow"}>
                <div className={"flex flex-col w-[730px]"}>
                    <h4 className={"text-left mb-6 leading-[50px]"}>You are sitting the {props.exam.name}.</h4>
                    <div className={"flex-row flex mb-8 gap-x-3"}>
                        <div className={"flex items-center px-3 py-2 bg-backgroundSecondary rounded-[10px]"}>
                            <img
                                alt={"hashicon"}
                                src={`${hashIcon}`}
                                className={"h-5 w-5 mr-2"}
                            />
                            <p className={"text-contentPrimary text-base font-normal font-inter"}>{totalQuestions} questions</p>
                        </div>
                        <div
                            className={"flex items-center px-3 py-2 bg-backgroundSecondary rounded-[10px] gap-x-2 flex-row"}>
                            <img
                                alt={"clockicon"}
                                src={`${clockIcon}`}
                                className={"h-5 w-5"}
                            />
                            <p className={"text-contentPrimary text-base font-normal font-inter"}>{totalTime} minutes</p>
                        </div>
                        <div className={"flex items-center px-3 py-2 bg-backgroundSecondary rounded-[10px]"}>
                            <img
                                alt={"targeticon"}
                                src={`${targetIcon}`}
                                className={"h-5 w-5 mr-2"}
                            />
                            <span className={"text-contentPrimary text-base font-normal font-inter"}>
                                Aim for a question
                                {qpm === 1 ?
                                    " per minute"
                                    :
                                    ` every ${qpm} minutes`
                                }
                            </span>
                        </div>
                    </div>
                    <div
                        className={"text-left text-stone-400 text-xl font-inter-tight leading-tight mb-[20px]"}>Instructions:
                    </div>
                    <div
                        className={"w-[480px] text-stone-600 text-sm font-inter leading-tight mb-8"}>{props.exam.instructions}</div>
                    <div className={"w-100 flex justify-start"}>
                        <Button color={"primary"} onClick={() => props.startExam(props.exam)}>
                            <IoCaretForward/>
                            Start Exam
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExamConfirm;
