import axios from "axios";
import {toast} from "react-toastify";

export const setAxiosAuthToken = token => {
    if (typeof token !== "undefined" && token) {
        // Apply for every request
        axios.defaults.headers.common["Authorization"] = `Token ${token}`;
    } else {
        // Delete auth header
        delete axios.defaults.headers.common["Authorization"];
    }
};

export const toastOnError = error => {

    if (error.response) {

        toast.error(JSON.stringify(error.response.data), {className: "toast-error", position: "bottom-left", closeButton: false});
    } else if (error.message) {
        toast.error(JSON.stringify(error.message, {className: "toast-error", position: "bottom-left", closeButton: false}));
    } else {
        try {
            toast.error(error, {className: "toast-error", position: "bottom-left", closeButton: false});
        } catch (e) {
            toast.error(JSON.stringify(error), {className: "toast-error", position: "bottom-left", closeButton: false});
        }
    }
};

export const toastOnSuccess = message => {
    toast.success(message, {className: "toast-success", position: "bottom-left", closeButton: false});
}

export const toastOnInfo = message => {
    toast.info(message, {className: "toast-info", position: "bottom-left"});
}

export const isEmpty = value =>
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0);