import React, { useEffect, useState } from "react";
import SettingsPage from "../account/SettingsPage";
import { Modal } from "../primitives/modal";
import {CadenceResults} from "./CadenceResults";
import {OverviewResults} from "./OverviewResults";
import {QuestionResults} from "./QuestionResults";
import {AccuracyResults} from "./AccuracyResults";
import {CompletionResults} from "./CompletionResults";


interface NavBarContextType {
    updateNavbarContent: (content: JSX.Element | null) => void;
}

interface ExamAttempt {
    answered: boolean;
    correct: boolean;
    answer: string;
    question: {
        question_text: string;
        correct_answer: string;
        correct: boolean;
    };
}

interface ExamResultsProps {
    examResults: ExamResultsType
    sectionResults: Record<string, SectionResults>;
}

// function lowestPerformingSection(sectionResults: Record<string, SectionResults>) {
//     let lowestScore = 100; // Assuming percentages are between 0 and 100
//     let lowestSection: string | null = null;
//     const sectionKeys = Object.keys(sectionResults);
//
//     for (const key of sectionKeys) {
//         const section = sectionResults[key];
//         if (section.result_percentage < lowestScore) {
//             lowestScore = section.result_percentage;
//             lowestSection = key;
//         }
//     }
//     return lowestSection;
// }
//
// function highestPerformingSection(sectionResults: Record<string, SectionResults>) {
//     let highestScore = 0;
//     let highestSection: string | null = null;
//     const sectionKeys = Object.keys(sectionResults);
//
//     for (const key of sectionKeys) {
//         const section = sectionResults[key];
//         if (section.result_percentage > highestScore) {
//             highestScore = section.result_percentage;
//             highestSection = key;
//         }
//     }
//     return highestSection;
// }

export function ExamResults(props: ExamResultsProps) {
    const isAboveAverage = props.examResults.percentile > 50;
    const [showSettings, setShowSettings] = useState(false);
    const [reviewAnswers, setReviewAnswers] = useState(false);
    const [sessionResults, setSessionResults] = useState<ExamAttempt[]>([]);
    const [initialOrder, setInitialOrder] = useState<ExamAttempt[]>([]);
    const [activeSessionReview, setActiveSessionReview] = useState<number>(null)
    const [sortBy, setSortBy] = useState<string>("Question Order");
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        setInitialOrder([...sessionResults]);
    }, [sessionResults]);

    const pages = [
        { name: 'Exams', href: '/exams', current: false },
        { name: 'Exam History', href: '/exam_history', current: false },
        { name: 'Data Room', href: '#', current: true },
    ];

    return (
        <>
            <Modal open={showSettings} onClose={setShowSettings} size={"5xl"} className="absolute top-[60px] left-1/2 transform -translate-x-1/2  rounded-0.625rem p-0 overflow-y-auto max-h-calc(100vh - 120px)">
                <SettingsPage activeTab={0} setOpenModal={setShowSettings}/>
            </Modal>
            <div className={"flex flex-row max-w-full h-[calc(100vh-65px)] relative overflow-auto"}>
                {/*<div className={"flex sticky overflow-y-auto min-w-[256px] top-0 h-full"}>*/}
                {/*    <SidebarDataRoom currentPage={activeTab} setCurrentPage={setActiveTab}/>*/}
                {/*</div>*/}
                {/*<div*/}
                {/*    className={"flex-grow flex-col overflow-y-auto absolute left-[257px] min-w-[calc(100vw-257px)] h-full"}>*/}
                    <div className={"px-8 py-6 w-full"}>
                            <div className={"w-full h-full flex flex-col"}>
                                {
                                    {
                                        0: <OverviewResults examResults={props.examResults} sectionResults={props.sectionResults}/>,
                                        1: <CadenceResults sectionResults={props.sectionResults} examResults={props.examResults}/>,
                                        2: <QuestionResults sectionResults={props.sectionResults} sectionSwap={()=>null}/>,
                                        3: <AccuracyResults sectionResults={props.sectionResults}/>,
                                        5: <CompletionResults examResults={props.examResults} sectionResults={props.sectionResults}/>,
                                    }[activeTab]
                                }
                            </div>
                    </div>
                {/*</div>*/}
            </div>
        </>
    );
}
