import React, {useContext} from "react";
import {connect, ConnectedProps} from "react-redux";
import {NavbarWithStreakIndicator} from "./PreconfiguredNavbars/NavbarWithStreakIndicator";
import {CompanyConfigContext} from "../CompanyConfigContextProvider";
import {useNavbarContent} from "../NavbarProvider";

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface CommunityProps extends PropsFromRedux {}

const Community: React.FC<CommunityProps> = (props) => {

    useNavbarContent({
        component: NavbarWithStreakIndicator
    });

    const companyConfig = useContext(CompanyConfigContext);

    return (
        <>
            {companyConfig.community_embed_code ?
                <iframe
                    className={"w-full h-full"}
                    src={`${companyConfig.community_embed_code}`}
                />
                :
                <>
                    <p>Error: Missing community embed. Please notify {companyConfig.company_name} support.</p>
                </>
            }
        </>
    );
}

export default connector(Community);