import {useEffect, useState} from "react";
import axios from "axios";
import {Button} from "../primitives/button";
import {ForwardIcon} from "@heroicons/react/16/solid";

type PromptGenerationProps = {
    setPrompt: (prompt: string) => void,
    closePromptGeneration: () => void,
    backToSetup: () => void,
    setIsWriting: (isWriting: boolean) => void,
    setEssayTask: (essayTitle: string) => void,
    essayTask: string,
}
const taskPromptMapping = {
    "A": "Persuasive",
    "B": "Creative",
}

function PromptGeneration(props: PromptGenerationProps) {
    // there are two writing categories: Task A and Task B
    const [promptFromServer, setPromptFromServer] = useState<string>("")
    const [regeneratePrompt, setRegeneratePrompt] = useState<boolean>(false)
    const [lines, setLines] = useState<string[]>([]);

    useEffect(() => {
        // get prompt from backend
       axios.get("/essay-prompt?writing_category=" + taskPromptMapping[props.essayTask]
       ).then((response) => {
            setPromptFromServer(response.data.prompt)
        })
    }, [regeneratePrompt, props.essayTask])

    useEffect(() => {
        // Split the prompt into lines
        setLines(promptFromServer.split('\n\n'));
    }, [promptFromServer])

    return (
        <div className={"px-4 py-6 flex-col flex gap-y-4"}>
            <div className={"leading-normal text-xl font-medium text-contentPrimary font-inter-tight"}>
                Select your writing category
            </div>
            <div className={"flex flex-row text-contentSecondary gap-x-3 my-3 h-[54px] font-semibold text-xs"}>
                <button className={`rounded-lg border-2 w-full ${props.essayTask === 'A' ? 'border-borderSelected' : 'border-borderOpaque'}`}
                        onClick={() => props.setEssayTask("A")}
                > Persuasive
                </button>
                <button
                    onClick={() => props.setEssayTask("B")}
                    className={`rounded-lg border-2 w-full ${props.essayTask === 'B' ? 'border-borderSelected' : 'border-borderOpaque'}`}>
                    Creative
                </button>
            </div>
            {props.essayTask &&
            <div className={"my-2.5"}>
                <div className={"py-4 px-3 flex flex-col gap-y-[26px] font-inter text-2xs leading-none font-normal text-contentPrimary border-2 border-borderOpaque rounded-lg"}>
                    {lines.map((line, index) => (
                        <div
                            className={"w-full text-left"}
                            key={index}>{line}</div>
                    ))}
                    <div className={"flex justify-end text-contentSecondary h-6"}>
                        <Button plain
                                onClick={() => setRegeneratePrompt(!regeneratePrompt)}>
                            <ForwardIcon className={"size-4"}/>
                            <p className={'text-contentPrimary text-xs leading-none'}>Next Prompt</p>
                        </Button>
                    </div>
                </div>
            </div>
            }
            <div
                className={"flex flex-row gap-x-2 w-full justify-end"}
            >
                <Button outline onClick={() => {
                    props.backToSetup()
                }}> Cancel
                </Button>
                {props.essayTask ==="" ?
                <></>:
                    <Button color={'secondary'} onClick={() => {
                        props.setPrompt(promptFromServer)
                        props.closePromptGeneration()
                        props.setIsWriting(true)
                    }}>
                        Start Writing
                    </Button>
                }
            </div>
        </div>
    );
}

export default PromptGeneration;
