import React from 'react';

interface DividerProps {
    type?: 'vertical' | 'horizontal' | 'center';
    className?: string;
}

const Divider: React.FC<DividerProps> = ({
                                             type = 'center',
                                             className = '',
                                         }) => {
    return (
        <div className={'flex justify-center items-center'}>
            <div
                className={`${className} inline-block border-borderOpaque ${type === 'horizontal' ? 'h-px border-t min-w-5' : 'w-px border-l min-h-5'}`}/>
        </div>

    );
};

export default Divider;
