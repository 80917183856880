import {Component} from "react";
import PropTypes from "prop-types";
import {toastOnError} from "../../utils/Utils";


class StateSelection extends Component {

    constructor(props) {
        super();
        this.state = {
            stateOfExam: props.currentProfile ? props.currentProfile.profile.state_of_exam : ""
        }

    }
    componentDidMount() {
        console.log(this.props.currentProfile)
        this.setState({stateOfExam: this.props.currentProfile ? this.props.currentProfile.profile.state_of_exam : "",
        lockedState: this.props.currentProfile ? this.props.currentProfile.profile.locked_state : false
        })
    }


    updateState(stateOfExam) {
        if (this.state.lockedState) {
            toastOnError("You cannot change your state of exam, please contact us if you need to change it.")
            return
        }
        this.setStateAndSubmit( stateOfExam);

    }
    async setStateAndSubmit(stateOfExam) {

        this.props.updateData({state_of_exam: stateOfExam});
        await this.setState({stateOfExam: stateOfExam});
        this.props.submitCurrentStep();
    }
    render() {
        return (
            <div className={"w-full flex flex-col items-center"}>
                <p className={"text-center font-ppe-editorial-new-light text-contentPrimary text-[40px] leading-10 mb-10"}>Which exam are you sitting?</p>
                <div className={"flex flex-row gap-x-16"}>
                    <div
                        className={`flex flex-col items-center gap-y-7 p-8 border rounded bg-backgroundPrimary ${this.state.stateOfExam === "VIC" ? "border-red-500" : "border-black"}`}
                        onClick={() => {
                            this.updateState("VIC")
                        }}
                    >
                        <img className={"w-[162px] h-[122px]"} alt={"vicimg"}
                             src={"https://assets-customerio.s3.ap-southeast-2.amazonaws.com/VIC.svg"}/>
                        <p className={'font-really-sans font-black text-lg'}><span className={"text-[#0017E7]"}>VIC</span> Selective entry exam</p>
                    </div>
                    <div
                        className={`flex flex-col items-center gap-y-7 p-8 border rounded bg-backgroundPrimary ${this.state.stateOfExam === "NSW" ? "border-red-500" : "border-black"}`}
                        onClick={() => {
                            this.updateState("NSW")
                        }}
                    >
                        <img className={"w-[162px] h-[122px]"} alt={"nswcimg"}
                             src={"https://assets-customerio.s3.ap-southeast-2.amazonaws.com/NSW.svg"}/>
                        <p className={'font-really-sans font-black text-lg'}><span className={"text-[#FF7411]"}>NSW</span> Selective entry exam</p>
                    </div>
                </div>
            </div>
        )
    }

}

StateSelection.propTypes = {
    updateData: PropTypes.func.isRequired
}

export default StateSelection;