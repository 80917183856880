import {ring} from 'ldrs'
import React, {useContext} from "react";
import {Button} from "../primitives/button";
import {CompanyConfigContext} from "../../CompanyConfigContextProvider";
ring.register()


interface loadingSpinnerModalProps {
    setLoadingAnalysis: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoadingSpinnerModal:React.FC<loadingSpinnerModalProps> = (props)=>{
    const companyConfig = useContext(CompanyConfigContext);

    const handleGoToVault = () => {
        props.setLoadingAnalysis(false)
        window.location.href = "/writing_tool/vault"
    }

    return (
        <div className={"p-6 flex flex-col gap-y-4 items-center"}>
            <l-ring
                size="60"
                stroke="5"
                speed="2"
                color="#A8A29E"
            ></l-ring>
            <div className={"text-sm font-normal my-4 text-contentTertiary text-center"}>Your written piece is currently
                being processed by {companyConfig?.company_name || "Hatchery"} AI. Feel free to leave this page while it processes.
                Your essay will have a green indicator in your Vault when it is done.
            </div>
            <div className={"flex justify-center"}>
                <Button color={'secondary'} onClick={() => handleGoToVault()}>Go to Vault
                </Button>
            </div>
        </div>
    );
}

export default LoadingSpinnerModal;