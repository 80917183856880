import React from "react";
import vaultIcon from "../assets/vault-dark.svg";
import drawerIcon from "../assets/drawer-left.svg";
import {Button} from "../components/primitives/button";
import {PencilIcon} from "@heroicons/react/16/solid";

interface SidebarWritingToolProps {
    toggleSidebar: () => void;
    writeSomething: () => void;
    isWriting: boolean;
}

const SidebarWritingTool: React.FC<SidebarWritingToolProps> = ({ toggleSidebar, writeSomething, isWriting }) => {
    const currentPath = window.location.pathname;

    return (
        <>
                <div className={"h-full bg-backgroundSecondary border-r border-borderOpaque px-2 py-3 flex flex-col"}>
                    <div className={"flex justify-end"} onClick={toggleSidebar}>
                        <img className={"drawer-sidebar-item ml-1 cursor-pointer"}
                             alt={"drawericon"}
                             src={`${drawerIcon}`}
                        />
                    </div>
                    <div className={"my-4 h-8"}>
                        <Button onClick={writeSomething} className={"w-full"} color={'primary'}>
                            <PencilIcon/>
                            Write something
                        </Button>
                    </div>
                    <div
                        className={`flex flex-row justify-start items-center rounded-lg px-2 py-1.5 gap-x-2 cursor-pointer hover:bg-borderOpaque ${currentPath === "/writing_tool/vault" && !isWriting ? "bg-borderOpaque" : ""}`}
                        onClick={() => window.location.href = "/writing_tool/vault"}
                    >
                        <img
                            alt={"vault"}
                            src={`${vaultIcon}`}
                        />
                        <p className={"text-sm font-normal leading-[18px] font-inter"}>Vault</p>
                    </div>
                    {/*<Row className={*/}
                    {/*    (currentPath === "/saved_feedback" && !isWriting) ? "writing-tool-sidebar-item-active" : "writing-tool-sidebar-item"*/}
                    {/*    } onClick={() => (window.location.href = "/saved_feedback")}*/}
                    {/*     style={{marginLeft:0, marginRight:0,}}>*/}
                    {/*    <Col xs={8} className={"p-0"}>*/}
                    {/*        <Nav.Item>*/}
                    {/*            <Nav.Link className={"py-[6px]"}>*/}
                    {/*                <Image*/}
                    {/*                    className={"mr-2 ml-1"}*/}
                    {/*                    src={`${feedbackIcon}`}*/}
                    {/*                    // src={(currentPath === "/saved_feedback" && !isWriting) ? `${feedbackIconActive}` : `${feedbackIcon}`}*/}
                    {/*                />*/}
                    {/*                Saved feedback*/}
                    {/*            </Nav.Link>*/}
                    {/*        </Nav.Item>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </div>
        </>
    );
};

export default SidebarWritingTool;
