import { combineReducers } from "redux";
import { loginReducer } from "./components/login/LoginReducer";
import { notesReducer } from "./components/notes/NotesReducer";
import { questionsReducer } from "./components/questions/QuestionReducer";
import { skillReducer } from "./components/skills/SkillReducer";
import { sessionViewerReducer } from "./components/sessionviewer/SessionViewerReducer";

const createRootReducer = () =>
    combineReducers({
        auth: loginReducer,
        notes: notesReducer,
        questions: questionsReducer,
        skills: skillReducer,
        sessionViewer: sessionViewerReducer
    });

export default createRootReducer;
