import React from "react";
import errorIcon from "../../assets/error-icon.svg";
import {Button} from "../primitives/button";

type DeleteModalProps = {
    setOpenDeleteModal: (open: boolean) => void,
    handleDeleteInstance: () => void,
}

function DeleteModal(props: DeleteModalProps) {

    return (
        <div className={"p-6 flex flex-col gap-y-4"}>
            <div className={"flex flex-row gap-x-2.5"}>
                <div className={"flex shrink align-top h-full"}><img alt={"error icon"} src={`${errorIcon}`}/></div>
                <div className={"flex grow text-normal font-inter-tight text-contentPrimary text-xl font-medium leading-5"}>Are you sure you want to delete this writing piece?</div>
            </div>
            <div className={"text-contentSecondary text-sm leading-tight font-inter"}>
                This action cannot be undone.
            </div>
            <div className={"flex flex-row w-full justify-end gap-x-2"}>
                <Button plain onClick={() => {
                    props.setOpenDeleteModal(false)
                }}> Cancel
                </Button>
                <Button
                    color={"dangerAngry"}
                    style={{backgroundColor: "#DE1135"}}
                    onClick={() => props.handleDeleteInstance()}
                >Delete
                </Button>
            </div>
        </div>
    )
}


export default DeleteModal