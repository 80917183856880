import React, { useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from "axios";
import AbsoluteLogo from "../fixedelements/AbsoluteLogo";
import PrivacyPolicy from "../fixedelements/PrivacyPolicy";
import {Input} from "../primitives/input";
import {Button} from "../primitives/button";
import {useToast} from "../ui/use-toast";

const ResetPasswordConfirm: React.FC = () => {
    const [newPassword, setNewPassword] = useState<string>("");
    const [passwordError, setPasswordError] = useState<string>("");
    const [status, setStatus] = useState<string>("");
    const { uid, token } = useParams<{ uid: string; token: string }>();
    const navigate = useNavigate();
    const { toast } = useToast()

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(e.target.value);
    };

    const onSaveClick = () => {
        setPasswordError("");
        setStatus("");

        const data = {
            uid,
            token,
            new_password: newPassword,
        };

        axios
            .post("/api/v1/users/reset_password_confirm/", data)
            .then(() => {
                toast({
                    title: "Password reset successfully",
                    description: "You can log in now.",
                })
                navigate("/login");
            })
            .catch((error) => {
                if (error.response && Object.prototype.hasOwnProperty.call(error.response.data, "new_password")) {
                    setPasswordError(error.response.data["new_password"]);
                } else {
                    setStatus("error");
                }
            });
    };

    const errorAlert = (
        <div className="alert alert-danger">
            <strong>Problem during new password set </strong>
            <p>
                Please try <Link to="/send_reset_password">reset password</Link> again
                or contact service support for further help.
            </p>
        </div>
    );

    const successAlert = (
        <div className="alert alert-success">
            <strong>New Password Set </strong>
            <p>
                You can <Link to="/login/">Login</Link> to your account with new
                password.
            </p>
        </div>
    );

    const form = (
        <div className={'flex flex-col gap-6 w-[320px]'}>
            <div className={'h-12'}>
                <Input
                    className={`h-12`}
                    type="password"
                    name="new_password"
                    placeholder="Enter new password"
                    value={newPassword}
                    onChange={onChange}
                    onKeyPress={(event) => {
                        if (event.key === "Enter") {
                            event.preventDefault();
                            onSaveClick();
                        }
                    }}
                />
            </div>
            {passwordError && (
                <div className="invalid-feedback">{passwordError}</div>
            )}
            <Button color={'primary'} className={'h-12'} onClick={onSaveClick}>
                Save
            </Button>
        </div>
    );

    return (
        <div className="h-full flex flex-col items-center bg-hatcheryGrey">
            <AbsoluteLogo/>
            <div className={"flex justify-center items-center align-middle mx-20 h-full w-full"}>
                <div className={"flex-col flex gap-y-6"}>
                    <div className={"flex flex-col gap-y-4 justify-center items-center"}>
                        <div
                            className="text-center text-black text-[40px] font-light font-ppe-editorial-new-light leading-10 px-10">
                            Set a new password
                        </div>
                        <div className={"max-w-[316px]"}>
                            <p className="text-sm font-normal leading-[18px] text-stone-400 text-center text-wrap">
                                Enter your new password.
                            </p>
                        </div>
                    </div>
                    <div className={"flex justify-center text-center text-sm p-2"}>
                        {status === "error"
                            ? errorAlert
                            :
                            status === "success"
                                ? successAlert
                                :
                                <></>
                        }
                        {status !== "success" && form}
                    </div>

                    <div className={"flex flex-row gap-x-1 justify-center"}>
                        <Link to="/login">
                            <p className={"text-accent font-sf-pro text-sm font-medium leading-[18px]"}>Return to log
                                in</p>
                        </Link>
                    </div>
                </div>
                <PrivacyPolicy/>
            </div>
        </div>
)
    ;
};

export default ResetPasswordConfirm;
