import React, { useEffect, useState } from "react";
import {connect, ConnectedProps} from "react-redux";
import axios from "axios";
import { Button } from "../primitives/button";
import { XMarkIcon } from "@heroicons/react/16/solid";
import { Switch, SwitchField } from "../primitives/switch";
import { useToast } from "../ui/use-toast"

interface NotificationsPageProps {
    setOpenModal: (open: boolean) => void;
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface AdditionalProps extends PropsFromRedux {}

const NotificationsPage: React.FC<NotificationsPageProps&AdditionalProps> = ({ setOpenModal }) => {
    const [enableProgressReports, setEnableProgressReports] = useState(true);
    const { toast } = useToast()


    useEffect(() => {
        axios.get("/my_profile")
            .then(response => {
                setEnableProgressReports(response.data.profile.enable_progress_reports)
            }).catch(error => {
            console.log(error);
        });
    }, []);

    const updateProgressReport = (value:boolean) => {
        axios
            .post("/update_profile/enable_progress_reports", {
                enable_progress_reports: value,
            })
            .then(() => {
                toast({
                    title: "Success",
                    description: "Progress report status updated",
                })
            })
            .catch(() => {
                toast({
                    title: "Error",
                    variant: "destructive",
                    description: "Failed to update progress report status",
                })
            });
    };


    const onChange = () => {
        const newEnableProgressReports = !enableProgressReports;
        setEnableProgressReports(newEnableProgressReports);
        updateProgressReport(newEnableProgressReports);
    };

    return (
        <div className={"w-full flex flex-col overflow-y-auto"}>
            <div className={"grid grid-cols-2 py-2 pl-4 pr-2 border-b border-borderOpaque items-center"}>
                <p className={"text-sm text-contentPrimary font-bold flex justify-start"}>Notifications</p>
                <div className={"flex justify-end"}>
                    <Button className={"h-8 w-8"} onClick={()=>setOpenModal(false)} plain>
                        <XMarkIcon />
                    </Button>
                </div>
            </div>
            <div className={"px-4 pt-4 pb-6 gap-x-2 flex flex-row"}>
                <div className={"flex flex-col gap-y-1 w-[550px]"}>
                    <p className="text-contentPrimary text-sm font-medium font-inter leading-tight">SMS progress notifications</p>
                    <p className="text-contentSecondary text-sm font-normal font-inter leading-tight">Receive weekly reports on your learning progress via a text message.</p>
                </div>
                <div className={"flex items-center"}>
                    <SwitchField>
                        <Switch name="allow_embedding" onChange={onChange} checked={enableProgressReports} />
                    </SwitchField>
                </div>
            </div>
        </div>
    );
};

export default connector(NotificationsPage);
