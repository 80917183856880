import React, { useState } from "react";
import ReferralPage from "./ReferralPage";
import ProfilePage from "./ProfilePage";
import NotificationsPage from "./NotificationsPage";
import {BellIcon, LinkIcon, UserIcon} from "@heroicons/react/16/solid";
import {CompanyConfigContext} from "../../CompanyConfigContextProvider";

const style: React.CSSProperties = {
    position: 'absolute' as const,
    top: '60px',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    maxWidth:'1080px',
    borderRadius: '0.625rem',
    padding: "0rem 0rem",
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 120px)',
};

const scrollableSectionStyle: React.CSSProperties = {
    overflowY: 'auto',
    maxHeight: style.maxHeight,
};

interface SettingsPageProps {
    activeTab: number,
    setOpenModal: (open: boolean) => void,
}

const SettingsPage: React.FC<SettingsPageProps> = (props) => {
    const [activeTab, setActiveTab] = useState<number>(props.activeTab);
    const companyConfig = React.useContext(CompanyConfigContext);

    return (
        <div className={"h-full"}>
            <div className={"flex flex-row rounded-l-lg bg-stone-50 h-full"}>
                <div className={"w-[220px] py-2 flex flex-col bg-backgroundSecondary rounded-l-lg min-h-full"}>
                    <div className={"p-3"}>
                        <p className={"text-sm font-inter font-bold"}>Settings</p>
                    </div>
                    <div className={"flex flex-col gap-y-1"}>
                        <div
                            className={`group cursor-pointer items-center text-sm flex-row flex px-4 py-1.5 gap-2 ${activeTab === 0 ? 'text-contentPrimary bg-backgroundTertiary' : 'text-contentPrimary hover:bg-backgroundTertiary'}`}
                            onClick={() => setActiveTab(0)}
                        >
                            <UserIcon className={"text-contentStateDisabled group-hover:text-contentPrimary size-4"}/>
                            <p>Account</p>
                        </div>
                        {!companyConfig.disable_progress_reports &&
                            <div
                                className={`group cursor-pointer items-center text-sm flex-row flex px-[16px] py-1.5 gap-2 ${activeTab === 2 ? 'text-contentPrimary bg-backgroundTertiary' : 'text-contentPrimary hover:bg-backgroundTertiary'}`}
                                onClick={() => setActiveTab(2)}
                            >
                                <BellIcon className={"text-contentStateDisabled group-hover:text-contentPrimary size-4"}/>
                                <p>Notifications</p>
                            </div>
                        }
                        <div
                            className={`group cursor-pointer items-center text-sm flex-row flex px-[16px] py-1.5 gap-2 ${activeTab === 1 ? 'text-contentPrimary bg-backgroundTertiary' : 'text-contentPrimary hover:bg-backgroundTertiary'}`}
                            onClick={() => setActiveTab(1)}
                        >
                            <LinkIcon className={"text-contentStateDisabled group-hover:text-contentPrimary size-4"}/>
                            <p>Invite a friend</p>
                        </div>
                    </div>
                </div>
                <div className={"flex flex-grow min-w-[800px] h-[692px]"} style={scrollableSectionStyle}>
                    {activeTab === 0 ?
                        <ProfilePage
                            setOpenModal={props.setOpenModal}
                        /> : activeTab === 1 ?
                        <ReferralPage setOpenModal={props.setOpenModal}/>
                        :
                        <NotificationsPage setOpenModal={props.setOpenModal}/>
                    }
                </div>
            </div>
        </div>
    );
};

export default SettingsPage;
