import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useToast } from "../ui/use-toast";
import Question from "../questions/Question";
import { SessionProgressBar } from "./SessionProgressBar";
import SectionProgressBar from "./SectionProgressBar";
import UserAvatar from "../account/UserAvatar";
import QuestionPaginator from "./QuestionPaginator";
import { Button } from "../primitives/button";
import ExamEssay from "../writingtool/ExamEssay";
import { Modal } from "../primitives/modal";
import errorIcon from "../../assets/error-icon.svg";
import { CompanyConfigContext } from "../../CompanyConfigContextProvider";
import {ring} from 'ldrs'
ring.register()

interface Session {
    id: number;
    is_exam: boolean;
    time_limit: number;
    start_time: string;
    essay_question: { question_prompt: string };
    questions: Question[];
    is_complete?: boolean;
}

interface ExamInfo {
    total_sections: number;
    current_section: number;
}

const SessionViewer: React.FC = () => {
    const navigate = useNavigate();
    const [currentQuestion, setCurrentQuestion] = useState<number>(0);
    const [session, setSession] = useState<Session | null>(null);
    const [questions, setQuestions] = useState<Question[]>([]);
    const [isExam, setIsExam] = useState<boolean>(false);
    const [examInfo, setExamInfo] = useState<ExamInfo | null>(null);
    const [showExitModal, setShowExitModal] = useState<boolean>(false);
    const [showAreYouSureModal, setShowAreYouSureModal] = useState<boolean>(false);
    const [totalSections, setTotalSections] = useState<number>(0);
    const [currentSection, setCurrentSection] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [questionStartTime, setQuestionStartTime] = useState<number | null>(null);
    const [questionsWithNoTimeTaken, setQuestionsWithNoTimeTaken] = useState<Question[]>([]);
    const [updatingQuestionTime, setUpdatingQuestionTime] = useState<boolean>(false);
    const { toast } = useToast();
    const companyConfig = React.useContext(CompanyConfigContext);

    const updateQuestionStartTime = () => {
        setQuestionStartTime(Date.now());
    };

    const updateQuestionTimeSpent = useCallback(async () => {
        setUpdatingQuestionTime(true);
        if (!session || session.questions.length < 2) return;
        try {
            const currentQuestionObj = session.questions[currentQuestion];
            const updatedTimeTaken = (Date.now() - (questionStartTime || 0)) + (currentQuestionObj.time_taken || 0);
            const updatedQuestion: Question = { ...currentQuestionObj, time_taken: updatedTimeTaken };
            const updatedSession = {
                ...session,
                questions: session.questions.map((q) =>
                    q.id === updatedQuestion.id ? updatedQuestion : q
                ),
            };
            const response = await axios.patch(`/api/v1/learningsessions/${session.id}/`, updatedSession);
            setSession(response.data);
            setUpdatingQuestionTime(false);
        } catch (error) {
            toast({
                variant: "destructive",
                title: "Error",
                description: "Question timer failed to update.",
            });
            setUpdatingQuestionTime(false);
        }
    }, [currentQuestion, session, questionStartTime]);

    const discardExam = async () => {
        await axios.get("/discard_all_sessions");
        navigate("/dashboard"); // Use navigate instead of history.push
    };

    const requestExit = () => {
        setShowExitModal(true);
    };

    const completeSession = async (essayId?: number) => {
        if (!session) return;
        try {
            session.is_complete = true;
            await axios.patch(`/api/v1/learningsessions/${session.id}/`, session);
            if (essayId) {
                await axios.get("/api/v1/complete_current_session/", { params: { essay_id: essayId } });
            } else {
                await axios.get("/api/v1/complete_current_session/");
            }
            if (isExam) {
                navigate("/exam_complete?id=latest");
            } else {
                navigate("/session_complete");
            }
        } catch (error) {
            toast({
                variant: "destructive",
                title: "Error",
                description: "Couldn't complete session.",
            });
        }
    };

    const getRemainingTime = () => {
        if (!session) return 0;
        const time_limit_milliseconds = session.time_limit * 60 * 1000;
        const start_time_milliseconds = new Date(session.start_time).getTime();
        const end_time_milliseconds = start_time_milliseconds + time_limit_milliseconds;
        return end_time_milliseconds - Date.now();
    };

    const getExamProgress = async () => {
        try {
            const response = await axios.get("/get_remaining_section_count_for_current_exam");
            setTotalSections(response.data.total_sections);
            setCurrentSection(response.data.current_section);
        } catch (error) {
            toast({
                variant: "destructive",
                title: "Error",
                description: "Couldn't get remaining section count",
            });
        }
    };

    const getLatestSession = async () => {
        try {
            const response = await axios.get("/api/v1/learningsessions/");
            const latestSession = response.data[0];
            if (latestSession) {
                setSession(latestSession);
                setIsExam(latestSession.is_exam);
                if (latestSession.is_exam) {
                    const examInfoResponse = await axios.get("/section_attempt_info");
                    setExamInfo(examInfoResponse.data);
                    getExamProgress();
                }
                setQuestions(latestSession.questions);
            }
        } catch (error) {
            toast({
                variant: "destructive",
                title: "Error",
                description: "Couldn't retrieve latest session.",
            });
        } finally {
            setLoading(false);
        }
    };

    const jumpToQuestion = (questionId: number) => {
        if (updatingQuestionTime) {
            console.log("Too fast");
            return;
        }
        updateQuestionTimeSpent();
        const questionIdStr = String(questionId);
        const questionIndex = session.questions.findIndex((q) => {
            return String(q.question.id) === questionIdStr;
        });
        if (questionIndex !== undefined && questionIndex >= 0) {
            setCurrentQuestion(questionIndex);
            updateQuestionStartTime();
        }
    };

    const previousQuestion = () => {
        if (updatingQuestionTime) {
            console.log("Too fast");
            return;
        }
        updateQuestionTimeSpent();
        if (currentQuestion > 0) {
            setCurrentQuestion(currentQuestion - 1);
            updateQuestionStartTime();
        }
    };

    const nextQuestion = (essayId?: number) => {
        if (updatingQuestionTime) {
            console.log("Too fast");
            return;
        }
        updateQuestionTimeSpent();
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
            updateQuestionStartTime();
        } else {
            const unansweredQuestions = session?.questions.filter((question) => !question.answered) || [];
            if (unansweredQuestions.length > 0) {
                setQuestionsWithNoTimeTaken(unansweredQuestions);
                setShowAreYouSureModal(true);
            } else {
                completeSession(essayId);
            }
        }
    };

    const updateAnswerResult = (questionId, isAnswered, isCorrect, answer) => {
        const question = session.questions.find((q) => q.question.id === questionId);
        if (question) {
            question.answered = isAnswered;
            question.correct = isCorrect;
            question.answer = answer;
            setSession({
                ...session,
                questions: session.questions.map((q) =>
                    q.question.id === questionId ? question : q
                ),
            });
        }
    };

    useEffect(() => {
        document.title = `Learn — ${companyConfig.company_name}`;
        getLatestSession();
        if (getRemainingTime() <= 2000 && session?.time_limit > 0) {
            completeSession();
        }
        updateQuestionStartTime();
    }, [companyConfig]);

    useEffect(() => {
        if (getRemainingTime() <= 2000 && isExam) {
            completeSession();
        }
    }, [isExam, session]);

    useEffect(() => {
        if (session) {
            setQuestions(session.questions);
        }
    }, [session]);

    if (loading) {
        return (
            <div className={'h-full w-full flex justify-center items-center'}>
                <l-ring
                size="60"
                stroke="5"
                speed="2"
                color="#A8A29E"/>
            </div>
        );
    }

    if (!session) {
        return <div>No session found for this user yet. Please start a new session from the dashboard.</div>;
    }

    const answeredQuestionsCount = session.questions.filter((q) => q.answered).length;

    const questionsHtml = questions.map((question, index) => (
        <div key={index}>
            <Question
                question={question.question as QuestionMeta}
                answered={question.answered}
                answer={question.answer}
                correct={question.correct}
                isExam={isExam}
                previousQuestion={previousQuestion}
                nextQuestion={nextQuestion}
                requestExit={requestExit}
                showExitModal={showExitModal}
                updateAnswerResult={updateAnswerResult}
            />
        </div>
    ));

    return (
        <>
            <div className="w-full flex flex-row items-center border-b border-borderOpaque">
                <div className="ml-6 mr-20 cursor-pointer" onClick={() => setShowExitModal(true)}>
                    <div className={"size-7 flex items-center"}>
                        <img
                            className={"size-fit"}
                            src={companyConfig.company_logo_navbar}
                            alt="Company Logo"
                        />
                    </div>
                </div>
                <div className="grid grid-cols-2 w-full px-6 items-center h-16">
                    <div className="flex flex-row grow items-center justify-start gap-x-3 h-[34px]">
                        {isExam && (
                            <div className="flex flex-row justify-center border border-borderOpaque rounded-lg mr-10 px-4 h-full items-center font-inter">
                                <p className="Primary text-sm font-medium truncate leading-none">
                                    Section <strong>&nbsp;{currentSection}&nbsp;</strong> of {totalSections}
                                </p>
                            </div>
                        )}
                        <div className="w-[514px] h-full border border-borderOpaque rounded-lg">
                            {isExam ? (
                                <SectionProgressBar
                                    completeSection={completeSession}
                                    remainingTime={getRemainingTime()}
                                    timeLimit={session.time_limit * 60 * 1000}
                                />
                            ) : (
                                <SessionProgressBar
                                    currentQuestion={answeredQuestionsCount}
                                    questionLength={questions.length}
                                />
                            )}
                        </div>
                        {session.time_limit > 0 && !isExam && (
                            <div className="w-[514px] h-[34px] border border-borderOpaque rounded-lg">
                                <SectionProgressBar
                                    color="bg-primaryA"
                                    completeSection={completeSession}
                                    remainingTime={getRemainingTime()}
                                    timeLimit={session.time_limit * 60 * 1000}
                                />
                            </div>
                        )}
                    </div>
                    <div className="flex flex-row justify-end">
                        <UserAvatar />
                    </div>
                </div>
            </div>
            <div className={"w-full flex flex-col justify-center"}>
                {isExam && session.essay_question ? (
                    <ExamEssay prompt={session.essay_question.question_prompt} nextQuestion={completeSession} />
                ) : (
                    <div className={"py-8"}>
                        {questionsHtml[currentQuestion]}
                        {isExam && (
                            <div className="w-full flex flex-col justify-center items-center py-3">
                                <QuestionPaginator
                                    currentQuestion={currentQuestion}
                                    totalQuestions={questions.length}
                                    onChange={(page) =>
                                        jumpToQuestion(Number(session.questions[page - 1].question.id))
                                    }
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
            <Modal
                open={showExitModal}
                onClose={() => setShowExitModal(false)}
                size={"xl"}
                className="absolute top-[60px] w-[376px] left-1/2 transform -translate-x-1/2 p-6 rounded-0.625rem overflow-y-auto max-h-calc(100vh - 120px)"
            >
                <div className={"flex flex-row items-start mb-5"}>
                    <div className={"shrink min-h-6 min-w-6 pt-0.5 mr-2.5"}>
                        <img alt={"erroricon"} src={`${errorIcon}`} />
                    </div>
                    <p className={"text-xl font-medium"}>Are you sure you want to exit?</p>
                </div>
                <div className={"text-slate-600 text-xs mb-[16px]"}>
                    If you leave this page, your progress will be lost. To save your progress, please complete the
                    session.
                </div>
                <div className={"flex flex-row justify-end gap-2"}>
                    <Button plain onClick={() => setShowExitModal(false)}>
                        Cancel
                    </Button>
                    <Button color={"dangerAngry"} onClick={discardExam}>
                        Confirm
                    </Button>
                </div>
            </Modal>
            <Modal
                size={"xl"}
                className="absolute top-[60px] left-1/2 transform -translate-x-1/2 rounded-0.625rem p-0 overflow-y-auto max-h-calc(100vh - 120px)"
                open={showAreYouSureModal}
                onClose={() => setShowAreYouSureModal(false)}
            >
                <div className={"py-6 rounded-2xs flex-col flex gap-y-4 px-3"}>
                    <div className={"px-1 text-contentPrimary font-inter-tight text-xl font-medium"}>
                        Are you sure you want to proceed to the next section?
                    </div>
                    <div className={"px-3 rounded-lg border border-borderOpaque max-h-40 overflow-auto "}>
                        <p className={"font-semibold text-sm"}>You have not answered these questions:</p>
                        <ul className={"list-disc list-inside"}>
                            {questionsWithNoTimeTaken.map((questionMeta, index) => (
                                <li key={index} className={"text-xs"}>
                                    Question:{" "}
                                    {session.questions.findIndex(
                                        (q) => q.question.id === questionMeta.question.id
                                    ) + 1}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={"px-1 text-contentPrimary font-inter-tight text-xl font-medium flex justify-end gap-2"}>
                        <Button plain onClick={() => setShowAreYouSureModal(false)}>
                            Cancel
                        </Button>
                        <Button color={"secondary"} onClick={() => completeSession()}>
                            Proceed
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default SessionViewer;
