import React, {useState, useEffect, useCallback} from "react";
import Chance from "chance";
import leftArrow from "../../assets/l-arrow.svg";
import rightArrow from "../../assets/r-arrow.svg";
import FlagManager from "./FlagManager";
import PropTypes from "prop-types";
import {Modal} from "../primitives/modal";
import {Button} from "../primitives/button";
import {FlagIcon, XCircleIcon} from "@heroicons/react/16/solid";
import { useToast } from "../ui/use-toast"


interface QuestionProps {
    question: QuestionMeta;
    answered: boolean;
    answer: string;
    correct: boolean;
    isExam: boolean;
    previousQuestion: () => void;
    nextQuestion: () => void;
    requestExit: () => void;
    showExitModal: boolean;
    updateAnswerResult: (
        id: string,
        isSubmitted: boolean,
        isCorrect: boolean,
        answer: string
    ) => void;
}

interface AnswerChoice {
    label: string;
    isCorrect: boolean;
    value: string;
    image: string;
}

interface QuestionTextOrContextProps {
    question: QuestionMeta;
}

const QuestionTextOrContext: React.FC<QuestionTextOrContextProps> = React.memo(function QuestionTextOrContext(props) {
    let text = props.question?.question_text;
    if (props.question?.extended_question_context) {
        text = props.question?.extended_question_context?.question_context;
    }

    return (
        <div className="question-html-wrapper p-7">
            {text && <p className={'text-contentPrimary text-base font-normal font-inter leading-[18px]'} dangerouslySetInnerHTML={{ __html: text }} />}
            {props.question.question_image && <img src={props.question.question_image} alt={"Question Image"}/>}
        </div>
    );
});

QuestionTextOrContext.propTypes = { question: PropTypes.any };

interface ExtendedAnswerQuestionProps {
    question: {
        extended_question_context?: {
            question_context: string;
        };
        question_text: string;
    };
}

const ExtendedAnswerQuestion: React.FC<ExtendedAnswerQuestionProps> = React.memo(function ExtendedAnswerQuestion(props) {
    const text = props.question?.question_text;
    return (
        <>
            {props.question?.extended_question_context && (
                <div className="rounded-[10px] px-[30px] py-[18px] mb-8 bg-backgroundTertiary">
                    <div className={'w-full d-flex justify-content-between'}>
                        <p className={'text-contentPrimary text-base font-normal font-inter leading-[24px]'} dangerouslySetInnerHTML={{ __html: text }}/>
                    </div>
                </div>
            )}
        </>
    );
});

ExtendedAnswerQuestion.propTypes = { question: PropTypes.any };

const Question: React.FC<QuestionProps> = (props) => {
    const [isAnswered, setIsAnswered] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState<AnswerChoice>(null);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [shuffledAnswers, setShuffledAnswers] = useState<AnswerChoice[]>(null);
    const [firstUpArrow, setFirstUpArrow] = useState(true);
    const { toast } = useToast()

    useEffect(() => {
        initQuestionGroup()
        setIsAnswered(props.answered);
    }, []);

    const handleGoNextQuestion = () => {
        if (isAnswered || props.isExam)
        props.nextQuestion()
    }
    const handleGoPreviousQuestion = () => {
        props.previousQuestion()
    }

    const handleKeyPress = useCallback((event: KeyboardEvent) => {
        if (!openModal && !props.showExitModal) {
            const key = event.key.toUpperCase();

            if (key === 'ENTER') {
                if ((!props.isExam && !isAnswered) && selectedAnswer !== null) {
                    onSubmitClick();
                } else {
                    handleGoNextQuestion()
                }
            }

            if (key === 'F' && !props.isExam) {
                setOpenModal(true)
            }

            if (key === 'ARROWRIGHT' && !props.isExam) {
                handleGoNextQuestion()
            }

            if (key === 'ARROWLEFT' && !props.isExam) {
                handleGoPreviousQuestion()
            }

            // Check if the key pressed corresponds to any label in answers
            const pressedAnswer = shuffledAnswers.find(answer => answer.label === key);

            if (pressedAnswer && !isAnswered) {
                const radio = document.getElementById(`${props.question?.id}_${pressedAnswer.label}`) as HTMLInputElement;
                if (radio) {
                    radio.checked = true;
                    setSelectedAnswer(pressedAnswer);
                }
            }

            if ((key === 'ARROWUP' || key === 'ARROWDOWN') && !isAnswered && shuffledAnswers) {
                event.preventDefault();
                const currentIndex = shuffledAnswers.findIndex(answer => answer === selectedAnswer);
                let offset = key === 'ARROWUP' ? -1 : 1;

                if (key === 'ARROWUP' && firstUpArrow) {
                    setFirstUpArrow(false);
                    offset = 0;
                }

                const nextIndex = (currentIndex + offset + shuffledAnswers.length) % shuffledAnswers.length;
                const nextAnswer = shuffledAnswers[nextIndex];
                setSelectedAnswer(nextAnswer);
            }
        }
    }, [isAnswered, props.question?.id, shuffledAnswers, selectedAnswer]);

    useEffect(() => {
        // attach the event listener
        document.addEventListener('keydown', handleKeyPress);

        // remove the event listener
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    const onSubmitClick = () => {
        if (!selectedAnswer && !props.isExam) {
            toast({
                variant: 'destructive',
                title: "Please select an answer",
                description: "It is better to submit an answer than submit nothing!",
            })
        } else {
            const answer = selectedAnswer;
            if (answer && answer.isCorrect) {
                setSelectedAnswer(answer);
                setIsAnswered(true);
            } else {
                setSelectedAnswer(answer);
                setIsAnswered(true);
            }
        }
    };

    const initQuestionGroup = () => {
        const { question } = props;

        let answers: AnswerChoice[] = [
            { label: "A", isCorrect: true, value: question?.correct_answer, image: question?.correct_answer_image },
            { label: "B", isCorrect: false, value: question?.incorrect_answer_1, image: question?.incorrect_answer_1_image },
            { label: "C", isCorrect: false, value: question?.incorrect_answer_2, image: question?.incorrect_answer_2_image },
            { label: "D", isCorrect: false, value: question?.incorrect_answer_3, image: question?.incorrect_answer_3_image },
            { label: "E", isCorrect: false, value: question?.incorrect_answer_4, image: question?.incorrect_answer_4_image },
        ].filter(answer => answer.value !== undefined && answer.value !== null && answer.value !== "" || answer.image);

        const chance = new Chance(question?.id);
        answers = chance.shuffle(answers);

        answers.forEach((answer, index) => {
            answer.label = String.fromCharCode(65 + index);
        });
        setShuffledAnswers(answers)
        if (props.answer) {
            setSelectedAnswer(answers.find(answerChoice => answerChoice.value === props.answer || answerChoice.image === props.answer));
        }
    }

    const QuestionGroup = () => {
        const { question, isExam } = props;
        return (
            <>
                {shuffledAnswers && shuffledAnswers.map((answer, index) => {
                    const isSelected = answer === selectedAnswer;
                    const isCorrect = answer.isCorrect;
                    let outlineClasses = 'outline outline-transparent rounded-lg';
                    let fillClasses = '';

                    if (isAnswered && isSelected && isCorrect && !isExam) {
                        outlineClasses = 'outline outline-1 rounded-lg outline-contentPositive';
                        fillClasses = 'bg-[#10A760]';
                    } else if (isAnswered && isSelected && !isCorrect && !isExam) {
                        outlineClasses = 'outline outline-1 rounded-lg outline-contentNegative';
                        fillClasses = 'bg-[#DE1135]';
                    } else if (isExam && isSelected) {
                        outlineClasses = 'outline outline-1 rounded-lg outline-primaryA';
                    } else if (isSelected && !isExam) {
                        outlineClasses = 'outline outline-1 rounded-lg outline-primaryB';
                    } else if (isAnswered && isCorrect && !isExam) {
                        outlineClasses = 'outline outline-1 rounded-lg outline-[rgba(113,203,134,1)]';
                        fillClasses = 'bg-[rgba(113,203,134,1)]';
                    }

                    return (
                        <div className="group mb-4 font-inter rounded-lg border border-transparent hover:border-borderOpaque hover:border focus-visible:outline-0"
                             key={index}
                             tabIndex={0}
                        >
                            <div
                                className={`cursor-pointer px-2 py-2.5 ${outlineClasses}`}
                                onClick={() => {
                                    if (props.isExam || !isAnswered) {
                                        setSelectedAnswer(answer);

                                    }
                                }}
                            >
                                <div className={"flex flex-row items-center"}>
                                        <label
                                            htmlFor={`${props.question?.id}_${answer.label}`}
                                            className={`border border-borderOpaque rounded-[10px] min-h-10 min-w-10 flex justify-center items-center mb-0 mr-5 ${fillClasses}`}
                                        >
                                            <input
                                                type="radio"
                                                name={question?.id}
                                                value={answer.value}
                                                id={`${question?.id}_${answer.label}`}
                                                style={{display: "none"}}
                                                onChange={() => {
                                                    if (!isAnswered || isExam) {
                                                        setSelectedAnswer(answer);
                                                    }
                                                }}
                                            />
                                            <span
                                                className={`font-inter text-lg font-bold flex justify-center items-center ${
                                                    ((isSelected && !isCorrect) || isCorrect) && isAnswered && !isExam
                                                        ? "text-white"
                                                        : ""
                                                }`}
                                            >
                                                {answer.label}
                                            </span>
                                        </label>

                                        <div className={"answer-html-wrapper"}>
                                            {answer.image && <img src={answer.image} alt={"Answer Image"}/>}
                                            <div
                                                className={'text-contentPrimary text-base font-normal font-inter leading-[18px]'}
                                                dangerouslySetInnerHTML={{ __html: answer.value }}
                                                style={{
                                                    maxWidth: "100%",
                                                    width: "100%",
                                                    whiteSpace: "normal",
                                                    fontWeight: isCorrect && isAnswered && !isExam ? "bold" : "normal",
                                                }}
                                            />
                                        </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };

    useEffect(() => {
        const { question, updateAnswerResult, isExam } = props;

        if (selectedAnswer && (isExam || isAnswered)) {
            setIsAnswered(true)
            if (selectedAnswer.value) {
                updateAnswerResult(question?.id, isAnswered, selectedAnswer.isCorrect, selectedAnswer.value);
            } else if (selectedAnswer.image) {
                updateAnswerResult(question?.id, isAnswered, selectedAnswer.isCorrect, selectedAnswer.image);
            } else {
                toast({
                    variant: 'destructive',
                    title: 'Error',
                    description: 'Question answer type invalid. Please flag this question or notify support.'
                })
            }
        }

    }, [selectedAnswer, isAnswered]);

    const handleExamQuestionSubmit = () => {
        onSubmitClick()
        props.nextQuestion()
    }

    return (
        <>
            <Modal open={openModal} onClose={setOpenModal} size={"xl"} className="absolute top-[60px] left-1/2 transform -translate-x-1/2  rounded-0.625rem p-0 overflow-y-auto max-h-calc(100vh - 120px)">
                <FlagManager setOpenModal={setOpenModal} questionId={props.question.id}/>
            </Modal>
            <div className={"justify-center flex"}>
                <div className={"flex gap-x-8 h-[700px] min-w-[880px] w-9/12"}>
                    <div className={"w-full flex flex-col grow"}>
                        <div
                            className={`grow border border-borderOpaque rounded-lg overflow-y-auto h-1/2`}
                        >
                            <QuestionTextOrContext question={props.question} />
                        </div>

                        {!props.isExam && isAnswered && (
                            <div className="grow border border-borderOpaque rounded-lg overflow-y-auto mt-3 w-full h-1/2">
                                <div className="question-html-wrapper p-[30px]">
                                    <p className={"mb-4"}><b>Feedback</b></p>
                                    <div className={'text-contentPrimary text-base font-normal font-inter leading-[18px] question-html-wrapper'}>
                                        {props.question.solution_image && <img src={props.question.solution_image} alt={"Answer Image"}/>}

                                        <p dangerouslySetInnerHTML={{ __html: props.question.solution }} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={"w-full flex flex-col grow"}>
                        <ExtendedAnswerQuestion question={props.question} />
                        <div className="border border-borderOpaque rounded-lg p-7 grow overflow-y-scroll">
                                <QuestionGroup/>
                        </div>
                        <div className="grid grid-cols-2 mt-8 p-3 border border-borderOpaque rounded-lg">
                            <div className={"flex flex-row gap-x-3 justify-start items-center"}>
                                {!props.isExam ? (
                                    <Button outline
                                            className={"h-10 w-10"}
                                        onClick={props.previousQuestion}
                                    >
                                        <img alt={"leftarrow"} src={`${leftArrow}`} width={20} />
                                    </Button>
                                ) : null}
                                {!props.isExam ? (
                                    <Button outline
                                            className={"h-10 w-10"}
                                        onClick={() => props.nextQuestion()}
                                    >
                                        <img alt={"right arrow"} src={`${rightArrow}`} width={20} />
                                    </Button>
                                ) : null}
                            </div>

                            <div className={"flex flex-row items-center justify-end gap-x-3"}>
                                <Button
                                    className={"h-10 w-10"}
                                    color={"danger"}
                                    onClick={props.requestExit}
                                >
                                    <XCircleIcon className={"size-4"}/>
                                </Button>
                                {!props.isExam ? (
                                    <Button
                                        className={"h-10 w-10"}
                                        color={"flag"}
                                        onClick={() => {setOpenModal(true)}}>
                                        <FlagIcon className={"size-4"}/>
                                    </Button>
                                ) : null}
                                {props.isExam ?
                                    <Button
                                        className={"h-10"}
                                        color={"secondary"}
                                        onClick={handleExamQuestionSubmit}>
                                        Next
                                    </Button>
                                    :
                                    !isAnswered ? (
                                        <Button
                                            className={"h-10"}
                                            color={"secondary"}
                                            onClick={onSubmitClick}>
                                            Submit
                                        </Button>
                                    ) : (
                                        <Button
                                            className={"h-10"}
                                            color={"secondary"}
                                            onClick={() => props.nextQuestion()}>
                                            Next
                                        </Button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Question;
