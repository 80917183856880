import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import axios from "axios";
import EssayInstance from "./EssayInstance";
import ComponentEmpty from "./ComponentEmpty";

interface Essay {
    id: number;
    title: string;
    writing_category: string;
    date: Date;
    processed: boolean;
}

interface OutletContextProps {
    setOpenSetupModal: (open: boolean) => void;
    handleDisplayClickedEssay: (essayId: number) => Essay;
    setOpenRenameModal: (open: boolean) => void;
    setOpenDeleteModal: (open: boolean) => void;
    setInstanceId: (instanceId: number) => void;
    refreshFlag: boolean;
}

const Vault: React.FC = () => {
    const [essayInstances, setEssayInstancesFromServer] = useState<Essay[]>([]);
    const [firstLoad, setFirstLoad] = useState(false);

    const {
        setOpenSetupModal,
        handleDisplayClickedEssay,
        setOpenRenameModal,
        setOpenDeleteModal,
        setInstanceId,
        refreshFlag,
    } = useOutletContext<OutletContextProps>();

    const fetchEssayInstances = () => {
        axios
            .get("api/v1/writingtoolessay")
            .then((response) => {
                setEssayInstancesFromServer(response.data);
                setFirstLoad(true);
            })
            .catch((error) => {
                console.error("Error fetching essays:", error);
            });
    };

    useEffect(() => {
        fetchEssayInstances();
    }, [refreshFlag]);

    const handleClickedEssay = (essayId: number) => {
        handleDisplayClickedEssay(essayId);
    };

    return (
        <div className="flex flex-col w-full p-4">
            {essayInstances.length === 0 && firstLoad ? (
                <ComponentEmpty setOpenSetupModal={setOpenSetupModal} />
            ) : (
                <>
                    {essayInstances
                        .sort((a: Essay, b: Essay) => new Date(b.date).getTime() - new Date(a.date).getTime())
                        .map((essay) => (
                            <EssayInstance
                                key={essay.id}
                                id={essay.id}
                                title={essay.title}
                                task={
                                    essay.writing_category === "A"
                                        ? "Persuasive"
                                        : essay.writing_category === "B"
                                            ? "Creative"
                                            : essay.writing_category
                                }
                                date={essay.date}
                                processed={essay.processed}
                                feedBackColour={""}
                                onClick={() => handleClickedEssay(essay.id)}
                                setInstanceId={setInstanceId}
                                setOpenDeleteModal={setOpenDeleteModal}
                                setOpenRenameModal={setOpenRenameModal}
                            />
                        ))}
                </>
            )}
        </div>
    );
};

export default Vault;
