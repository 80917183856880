import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from '../primitives/button';
import {NavbarWithStreakIndicator} from "../PreconfiguredNavbars/NavbarWithStreakIndicator";
import {useToast} from "../ui/use-toast";
import {ring} from 'ldrs'
import {useNavbarContent} from "../../NavbarProvider";
ring.register()

const ExamComplete: React.FC = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [incompleteSections, setIncompleteSections] = useState<string | null>(null);
    const [nextSection, setNextSection] = useState<string | null>(null);
    const { toast } = useToast()

    useNavbarContent({
        component: NavbarWithStreakIndicator
    });

    useEffect(() => {
        getActiveExamAttemptDetails();

    }, []);

    const getActiveExamAttemptDetails = async () => {
        try {
            const response = await axios.get(`/exam_progress`);
            const { finished, incompleteSections, next_section } = response.data;

            setIncompleteSections(incompleteSections);
            setNextSection(next_section);
            if (finished) {
                window.location.href = `/exam_results?id=latest`
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            toast({
                variant: "destructive",
                title: "Error",
                description: error,
            })
        }
    };

    const activateNextSection = async () => {
        try {
            await axios.get(`/next_section_or_finish`);
            window.location.href = "/current_session";
        } catch (error) {
            toast({
                variant: "destructive",
                title: "Error",
                description: error,
            })
        }
    };

    return (
        <>
            {isLoading ?
                <div className={'h-full w-full flex justify-center items-center'}>
                    <l-ring
                        size="60"
                        stroke="5"
                        speed="2"
                        color="#A8A29E"/>
                </div>
                :
                <>
                    <div className="flex flex-col justify-center w-full h-full items-center font-inter gap-y-9">
                        <div
                            className="flex flex-row text-contentPrimary text-[64px] font-light font-ppe-editorial-new-light leading-none">
                            <p className="text-center">
                                The <span>{nextSection}</span> section is commencing
                            </p>
                        </div>
                        <div>
                            <Button onClick={activateNextSection} className="w-[100px] h-10">
                                Continue
                            </Button>
                        </div>
                    </div>
                </>
            }
        </>
    );
};

export default ExamComplete;
