import React, {useState, useCallback, useMemo, useEffect} from "react";
import {AreaClosed, Bar, Line, LinePath} from '@visx/shape';
import { LinearGradient } from '@visx/gradient';
import { Group } from '@visx/group';
import { scaleLinear } from "@visx/scale";
import { localPoint } from '@visx/event';
import {withTooltip, TooltipWithBounds} from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { ToggleTag } from "../primitives/ToggleTag";
import {bisector} from "d3-array";

export type AreaProps = {
    examResults: ExamResultsType;
    sectionResults: Record<string, SectionResults>;
    width: number;
    height: number;
    margin?: { top: number; right: number; bottom: number; left: number };
};

interface TooltipData {
    questionNumber: number;
    userPace: number;
    basePace: number;
}

const getX = (d: TooltipData) => d[0];
const getY = (d: TooltipData) => d[1];
// const getPercentile = (d: TooltipData) => d.percentile;
const bisectData = bisector<TooltipData, number>((d) => (d[0])).left;

export default withTooltip<AreaProps, TooltipData>(
    ({
         examResults,
         sectionResults,
         width,
         height,
         margin = { top: 70, right: 0, bottom: 40, left: 0 },
         showTooltip,
         hideTooltip,
         tooltipData,
         tooltipTop = 0,
         tooltipLeft = 0,
     }: AreaProps & WithTooltipProvidedProps<TooltipData>) => {
        if (width < 10) return null;

        const sections = Object.keys(sectionResults);
        const [examResultPercentile, setExamResultPercentile] = useState(examResults.percentile)
        const [examResultPercentage, setExamResultPercentage] = useState(examResults.percentage)
        const [activeAbbreviation, setAbbreviation] = useState<string>(sections[0]);
        const [activeGraph, setActiveGraph] = useState(sectionResults[sections[0]])
        const [basePace, setBasePace] = useState([])
        const [usersPace, setUsersPace] = useState([])
        const [isLoading, setIsLoading] = useState(true)
        const [secondsPerQuestion, setSecondsPerQuestion] = useState(0)


        useEffect(() => {
            const basePace = [];
            const usersPace = [];

            const cadenceValues = Object.values(activeGraph.cadence_other_users);
            const questionDurations = Object.values(activeGraph.question_durations);
            const minTime = Math.min(...cadenceValues, ...questionDurations);
            const maxTime = Math.max(...cadenceValues, ...questionDurations);

            const normalizeY = (value: number) => (value - minTime) / (maxTime - minTime);

            let totalDuration = 0;
            let numDurations = 0;
            for (const key in activeGraph.cadence_other_users){
                if (activeGraph.cadence_other_users[key] && activeGraph.question_durations[key]){
                    const basePaceY = 1 / activeGraph.cadence_other_users[key];
                    const usersPaceY = 1 / activeGraph.question_durations[key];

                    basePace.push([key, normalizeY(basePaceY).toFixed(2)])
                    usersPace.push([key, normalizeY(usersPaceY).toFixed(2)])

                    totalDuration += activeGraph.question_durations[key];
                    numDurations++;
                }
            }

            setSecondsPerQuestion(totalDuration / numDurations)
            setBasePace(basePace)
            setUsersPace(usersPace)
            setIsLoading(false)
        },[examResults, sectionResults, activeGraph]);

        const xMin = 0;
        const xMax = width - margin.left - margin.right;
        const yMax = height - margin.top - margin.bottom;

        const xScale = useMemo(
            () =>
                scaleLinear({
                    domain: [0, basePace.length],
                    range: [xMin, xMax],
                }),
            [xMax, margin.left, usersPace, basePace],
        );

        const yScale = useMemo(
            () =>
                scaleLinear({
                    domain: [0, 1],
                    range: [yMax, 0],
                }),
            [margin.top, yMax, usersPace, basePace],
        );

        const handleActiveGraph = (abv: string)=> {
            if (abv ==="All"){
                // setActiveGraph(allSections)
                // setExamResultPercentage(examResults.percentage)
                // setExamResultPercentile(examResults.percentile)
            } else {
                setActiveGraph(sectionResults[abv])
                setExamResultPercentage(sectionResults[abv].result_percentage)
                setExamResultPercentile((Math.round(100*sectionResults[abv].bell_curve.percentile)))
            }
            setAbbreviation(abv)
        }

        const handleTooltip = useCallback(
            (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
                const { x } = localPoint(event) || { x: 0 };
                const x0 = xScale.invert(x);
                const index = bisectData(usersPace, x0, 1);
                const d0 = usersPace[index - 1];
                const d1 = usersPace[index];
                let d = d0;
                if (d1 && getX(d1)) {
                    d = x0.valueOf() - getX(d0).valueOf() > getX(d1).valueOf() - x0.valueOf() ? d1 : d0;
                }
                const tooltipLeft = Math.max(margin.left, Math.min(x, width - margin.right));
                showTooltip({
                    tooltipData: d,
                    tooltipLeft: tooltipLeft,
                    tooltipTop: yScale(getY(d)),
                });
            },
            [showTooltip, yScale, xScale, usersPace, basePace],
        );

        const getAbbreviation = (section : string) => !sections.includes(section) ? section : sectionResults[section].section_abbreviation || section;

        const abbreviations = [
            // 'All',
            ...sections];

        return (
            <div className="flex items-center">
                {!isLoading && (
                    <>
                        <svg width={width} height={height}>
                            <LinearGradient id="CadenceGradient" from="#10A76040" to="#10A76000" />
                            <Group top={margin.top} left={margin.left}>
                                <AreaClosed
                                    data={basePace}
                                    x={(d) => xScale(getX(d)) ?? 0}
                                    y={(d) => yScale(getY(d)) ?? 0}
                                    y0={yMax + margin.bottom + 12}
                                    stroke="#10A760"
                                    strokeWidth={0}
                                    fill="url(#CadenceGradient)"
                                    
                                    yScale={yScale}
                                />
                                <LinePath
                                    data={usersPace}
                                    x={(d) => xScale(getX(d)) ?? 0}
                                    y={(d) => yScale(getY(d)) ?? 0}
                                    stroke="#12B76A"
                                    strokeWidth={6}
                                    strokeOpacity={1}
                                    shapeRendering="geometricPrecision"
                                    markerMid="url(#marker-circle)"
                                />
                                <Bar
                                    x={0}
                                    y={0}
                                    width={width}
                                    height={height - margin.top}
                                    fill="transparent"
                                    rx={14}
                                    onTouchStart={handleTooltip}
                                    onTouchMove={handleTooltip}
                                    onMouseMove={handleTooltip}
                                    onMouseLeave={() => hideTooltip()}
                                />
                                <foreignObject x={0} y={-margin.top + 10} width={width} height={40}>
                                    <div className={"w-full flex justify-center h-full"}>
                                        <div className={"flex flex-row gap-x-[100px]"}>
                                            <div className={"flex flex-row gap-x-4 h-full items-center"}>
                                                {abbreviations.map((abv) => (
                                                    <div className={"h-full"} key={abv} onClick={() => handleActiveGraph(abv)}>
                                                        <ToggleTag active={abv === activeAbbreviation}>{getAbbreviation(abv)}</ToggleTag>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className={"h-full items-center flex text-base text-contentPrimary font-inter leading-none font-medium"}>
                                                Avg: {secondsPerQuestion.toFixed(0)} seconds / question
                                            </div>
                                        </div>
                                    </div>
                                </foreignObject>
                            </Group>
                            {tooltipData && (
                                <Group>
                                    <Line
                                        from={{ x: tooltipLeft, y: margin.top}}
                                        to={{ x: tooltipLeft, y: innerHeight+margin.top}}
                                        stroke={"#A8A29E"}
                                        strokeWidth={2}
                                        pointerEvents="none"
                                        strokeDasharray="5,2"
                                    />
                                </Group>
                            )}
                        </svg>
                        {tooltipData && (
                            <Group>
                                <TooltipWithBounds
                                    key={Math.random()}
                                    top={Math.max(margin.top, Math.min(tooltipTop - 50, height - margin.bottom - 100))}
                                    left={Math.max(margin.left, Math.min(tooltipLeft, width - margin.right - 240))}
                                    className={"bg-backgroundPrimary border border-borderOpaque text-contentPrimary"}
                                >
                                    <div className={"flex flex-col gap-1"}>
                                        <p>Question Number: {`${Number(getX(tooltipData))+1}`}</p>
                                        <p>Cohort&apos;s pace: {`${activeGraph.cadence_other_users[getX(tooltipData)].toFixed(2)} seconds`}</p>
                                        <p>Your pace: {`${activeGraph.question_durations[getX(tooltipData)].toFixed(2)} seconds`}</p>
                                    </div>
                                </TooltipWithBounds>
                            </Group>
                        )}
                    </>
                )}
            </div>
        );
    }
);
