import React, {useEffect, useState, useMemo, useContext} from "react";
import PropTypes from "prop-types";
import { connect, ConnectedProps } from "react-redux";
import { logout } from "../login/LoginActions";
import SkillMetricsList from "../metrics/SkillMetricsList";
import { OverallStats } from "./OverallStats";
import { createSession } from "../sessioncreator/SessionCreatorActions";
import axios from "axios";
import PlaceholderMetrics from "./PlaceholderMetrics";
import ContentAreaMetricsList from "../metrics/ContentAreaMetricsList";
import { CompanyConfigContext } from "../../CompanyConfigContextProvider";
import {NavbarWithStreakIndicator} from "../PreconfiguredNavbars/NavbarWithStreakIndicator";
import {useNavbarContent} from "../../NavbarProvider";

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
});
const connector = connect(mapStateToProps, { createSession, logout });
type PropsFromRedux = ConnectedProps<typeof connector>;

interface DashboardProps extends PropsFromRedux {}

const Metrics = () => {
    return (
        <div className={"max-w-screen-xl w-full"}>
            <div className="w-full flex flex-col items-center">
                <div className="w-full mb-8 flex justify-center">
                    <OverallStats />
                </div>
                <div className={"grid grid-cols-1 xl:grid-cols-2 gap-8 w-full"}>
                    <div className="grow lg:grow-0 xl:grow">
                        <div className="flex justify-center w-full">
                            <div className="grow rounded-[10px] border border-stone-200 flex-col justify-start items-start inline-flex max-w-[622px]">
                                <ContentAreaMetricsList />
                            </div>
                        </div>
                    </div>
                    <div className="grow lg:grow-0 xl:grow">
                        <div className="flex justify-center w-full">
                            <div className="grow rounded-[10px] border border-stone-200 flex-col justify-start items-start inline-flex max-w-[622px]">
                                <SkillMetricsList />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Dashboard: React.FC<DashboardProps> = (props) => {
    const companyConfig = useContext(CompanyConfigContext);
    const [state, setState] = useState({
        createDashboardMetrics: false,
        loading: true,
        firstName: null,
        error: null,
        skipPlaceholder: false,
    });

    useNavbarContent({
        component: NavbarWithStreakIndicator
    });

    const setProfileState = (profile) => {
        setState((prev) => ({
            ...prev,
            firstName: profile?.first_name,
        }));
    };

    const setMetricsState = (metrics) => {
        setState((prev) => ({
            ...prev,
            createDashboardMetrics: metrics.should_create_dashboard_metrics,
            loading: false,
        }));
    };

    const setErrorState = (error) => {
        setState((prev) => ({
            ...prev,
            loading: false,
            error: error.message || "An error occurred while fetching data.",
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const profileResponse = await axios.get("/my_profile");
                setProfileState(profileResponse.data.profile);

                const metricsResponse = await axios.get("/should_create_dashboard_metrics");
                setMetricsState(metricsResponse.data);
            } catch (error) {
                setErrorState(error);
            }
        };

        document.title = `Dashboard — ${companyConfig.company_name}`;
        fetchData();
    }, [props.auth, companyConfig]);

    const handleSkip = () => {
        setState((prev) => ({
            ...prev,
            skipPlaceholder: true,
        }));
    };

    const renderMetrics = useMemo(() => {
        if (state.loading) {
            return <p></p>;
        } else if (state.error) {
            return <p>{state.error}</p>;
        } else if (state.createDashboardMetrics || companyConfig.allow_users_to_skip_placeholders) {
            return (
                <>
                    <div className={"flex justify-center w-full p-8"}>
                        <Metrics />
                    </div>
                </>
            );
        } else {
            // Placeholder state
            return <div className={""}><PlaceholderMetrics handleSkip={handleSkip} /></div>;
        }
    }, [state.createDashboardMetrics, state.loading, state.error, state.skipPlaceholder, companyConfig]);

    return (
        <div className={"w-full"}>
            {renderMetrics}
        </div>
    );
};

Dashboard.propTypes = {
    logout: PropTypes.func.isRequired,
};

export default connector(Dashboard);
