import React, { useEffect, useState } from "react";
import axios from "axios";
import {ring} from "ldrs";
import {useToast} from "../ui/use-toast";
import {ExamResults} from "./ExamResults";
import {NavbarWithStreakIndicator} from "../PreconfiguredNavbars/NavbarWithStreakIndicator";
import {useLocation} from "react-router-dom";
import {useNavbarContent} from "../../NavbarProvider";
ring.register()


interface ExamResultsWrapperProps {
    history?: { push: (path: string) => void };
}

const ExamResultsWrapper: React.FC<ExamResultsWrapperProps> = (props) => {
    const [id, setId] = useState<string | null>(null);
    const [examResults, setExamResults] = useState<ExamResultsType>(null);
    const [sectionResults, setSectionResults] = useState<never>(null);
    const { toast } = useToast()
    const location = useLocation()
    useNavbarContent({
        component: NavbarWithStreakIndicator
    });
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const examId = params.get("id");
        setId(examId);
        getExamResults(examId);
        getSectionResults(examId);
    }, []);

    const getExamResults = (id: string | null) => {
        if (id) {
            axios
                .get("/get_exam_attempt_results_by_id", { params: { id: id } })
                .then((response) => {
                    setExamResults(response.data);
                })
                .catch((error) => {
                    console.log(error)
                    toast({
                        title: "Error",
                        description: "Unable to retrieve exam data",
                    })
                });
        }
    };

    const getSectionResults = (id: string | null) => {
        if (id) {
            axios
                .get("/get_exam_attempt_results_per_section_by_id", { params: { id: id } })
                .then((response) => {
                    setSectionResults(response.data);
                })
                .catch((error) => {
                    toast({
                        title: "Error",
                        description: "Unable to retrieve exam data",
                    })
                });
        }
    };

    if (examResults !== null && sectionResults !== null) {
        return (
            <div className={"w-full"}>
                <ExamResults examResults={examResults} sectionResults={sectionResults} />
            </div>
        );
    } else {
        return <div className={"flex h-full mx-auto items-center justify-center"}>
            <div>
                <l-ring
                    size="60"
                    stroke="5"
                    speed="2"
                    color="#A8A29E"
                ></l-ring>
            </div>
        </div>;
    }
};

export default ExamResultsWrapper;
