import React, {useState, useMemo, useEffect, useRef} from "react";
import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import { Button } from "../primitives/button";
import {
    AdjustmentsHorizontalIcon,
    ArrowRightEndOnRectangleIcon,
    ClockIcon,
    PencilIcon,
    PlayPauseIcon
} from "@heroicons/react/16/solid";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

const fetchEventLogs = async (start: number, end: number) => {
    const { data } = await axios.get(`/event_logs/?start=${start}&end=${end}`);
    return data;
};

const formatDate = (dateString: string, isDetailed: boolean) => {
    const date = new Date(dateString);
    if (isDetailed) {
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
    } else {
        return date.toLocaleString('en-US', { year: 'numeric', month: 'long' });
    }
};

const getCompressedEventText = (eventType: string, count: number): string => {
    switch (eventType) {
        case 'ESSAY':
            return `Wrote ${count} essay${count > 1 ? 's' : ''}`;
        case 'VIDEO':
            return `Watched ${count} video${count > 1 ? 's' : ''}`;
        case 'EXAM':
            return `Sat ${count} exam${count > 1 ? 's' : ''}`;
        case 'SESSION':
            return `Did ${count} session${count > 1 ? 's' : ''}`;
        case 'ACCOUNT':
            return `Performed ${count} account action${count > 1 ? 's' : ''}`;
        default:
            return `${count} ${eventType.toLowerCase()} event${count > 1 ? 's' : ''}`;
    }
};

interface EventLog {
    id: number;
    event_type: string;
    event_text: string;
    created_at: string;
    event_reference: string;
}

interface CompressedEventLog {
    id: string;
    event_type: string;
    event_text: string;
    created_at: string;
    event_reference: string;
    count: number;
}

const HistorySection: React.FC = () => {
    const tabs = [
        { id: "detailed", name: "detailed" },
        { id: "monthly", name: "monthly" },
    ];
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
    const [displayedRecords, setDisplayedRecords] = useState<number>(10);
    const [totalFetchedRecords, setTotalFetchedRecords] = useState<number>(100);
    const queryClient = useQueryClient();
    const numberOfRecordsToLoad = 10;

    const { data: historyItems, isLoading, isError, error } = useQuery<EventLog[], Error>({
        queryKey: ["eventLogs", totalFetchedRecords],
        queryFn: () => fetchEventLogs(0, totalFetchedRecords),
    });

    const compressedMonthlyData = useMemo(() => {
        if (!historyItems) return [];

        const monthlyData: Record<string, Record<string, CompressedEventLog>> = {};

        historyItems.forEach(item => {
            const monthYear = formatDate(item.created_at, false);
            if (!monthlyData[monthYear]) {
                monthlyData[monthYear] = {};
            }
            if (!monthlyData[monthYear][item.event_type]) {
                monthlyData[monthYear][item.event_type] = {
                    id: `${monthYear}-${item.event_type}`,
                    event_type: item.event_type,
                    event_text: "",
                    created_at: monthYear,
                    event_reference: "",
                    count: 0
                };
            }
            monthlyData[monthYear][item.event_type].count += 1;
        });

        return Object.values(monthlyData).flatMap(monthData =>
            Object.values(monthData).map(item => ({
                ...item,
                event_text: getCompressedEventText(item.event_type, item.count)
            }))
        );
    }, [historyItems]);

    const displayedItems = useMemo(() => {
        const items = activeTabIndex === 0 ? historyItems : compressedMonthlyData;
        return items?.slice(0, displayedRecords) || [];
    }, [activeTabIndex, historyItems, compressedMonthlyData, displayedRecords]);

    const loadMore = async () => {
        if (displayedRecords < totalFetchedRecords) {
            setDisplayedRecords((prevRecords) => Math.min(prevRecords + numberOfRecordsToLoad, totalFetchedRecords));
        } else {
            setTotalFetchedRecords((prev) => prev + numberOfRecordsToLoad * 10);
            setDisplayedRecords((prev) => prev + numberOfRecordsToLoad);
            await queryClient.invalidateQueries({queryKey: ["eventLogs"]});
        }
    };

    const hasMoreLogs = useMemo(() => {
        return historyItems && historyItems.length > displayedRecords;
    }, [historyItems, displayedRecords]);

    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error: {error.message}</div>;

    return (
        <div className={'flex justify-center w-full mt-24 px-14 min-h-screen'}>
            <div className={'flex flex-col gap-y-[26px] max-w-screen-md w-full'}>
                <div className={'flex flex-row h-10 w-full justify-between'}>
                    <p className={'text-contentPrimary text-3xl font-semibold'}>History</p>
                    <SlidingTabBar tabs={tabs} activeTabIndex={activeTabIndex} setActiveTabIndex={setActiveTabIndex}/>
                </div>
                <div className={'flex flex-col'}>
                    <AnimatePresence mode={'popLayout'}>
                        {displayedItems?.map((item, index, arr) => (
                            <motion.div
                                key={item.id}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.3 }}
                            >
                                <HistoryItem
                                    category={item.event_type}
                                    action={item.event_text}
                                    date={formatDate(item.created_at, activeTabIndex === 0)}
                                    reference={item.event_reference}
                                    last={index === arr.length - 1}
                                    monthly={activeTabIndex === 1}
                                />
                            </motion.div>
                        ))}
                    </AnimatePresence>
                </div>
                {hasMoreLogs && activeTabIndex === 0 && (
                    <div>
                        <Button plain className={'h-6'} onClick={loadMore}>Show {numberOfRecordsToLoad} more</Button>
                    </div>
                )}
            </div>
        </div>
    );
};

interface HistoryItemProps {
    category: string;
    action: string;
    date: string;
    reference?: string;
    last: boolean;
    monthly: boolean;
}

const iconMap: Record<string, React.FC<{ className: string }>> = {
    'SESSION': AdjustmentsHorizontalIcon,
    'ESSAY': PencilIcon,
    'VIDEO': PlayPauseIcon,
    'EXAM': ClockIcon,
    'ACCOUNT': ArrowRightEndOnRectangleIcon,
    'MISC': AdjustmentsHorizontalIcon,
};

const HistoryItem: React.FC<HistoryItemProps> = ({ category, date, action, reference, last, monthly }) => {
    const Icon = iconMap[category] || iconMap['default'];
    const navigate = useNavigate();

    const handleNavigation = () => {
        if (category === 'VIDEO') {
            navigate(`/content_portal?id=${reference}`);
        } else if (category === 'ESSAY') {
            navigate(`/writing_tool/vault?essay_id=${reference}`);
        } else if (category === 'EXAM') {
            navigate(`/exam_results?id=${reference}`);
        }
    };

    return (
        <div className="flex flex-row gap-2.5">
            <div className="flex flex-col pt-1 gap-1.5 items-center">
                <Icon className="size-4 text-primaryA" />
                {!last &&
                    <div className={'h-[2.875rem] border-l border-contentTertiary border-dashed'}/>
                }
            </div>
            <div className="flex flex-col grow gap-1 font-inter">
                <p className={'text-contentPrimary text-base font-medium leading-normal'}>{action}</p>
                <div className={'flex flex-row gap-2 text-xs'}>
                    <p className={'text-contentTertiary font-medium'}>{date}</p>
                    {!monthly &&
                        <p className={'text-primaryA font-black cursor-pointer'} onClick={handleNavigation}>
                            {category === 'VIDEO' ? 'Go to video' :
                                category === 'ESSAY' ? 'View essay' :
                                    category === 'EXAM' ? 'View results' : ''}
                        </p>
                    }
                </div>
            </div>
        </div>
    );
};

interface SlidingTabBarProps {
    tabs: {id: string, name: string}[]
    activeTabIndex: number;
    setActiveTabIndex: (index: number) => void;
}

const SlidingTabBar: React.FC<SlidingTabBarProps> = ({tabs, activeTabIndex, setActiveTabIndex}) => {
    const tabsRef = useRef<(HTMLElement | null)[]>([]);
    const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
    const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

    useEffect(() => {
        if (activeTabIndex === null) {
            return;
        }

        const setTabPosition = () => {
            const currentTab = tabsRef.current[activeTabIndex] as HTMLElement;
            setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
            setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
        };

        setTabPosition();
    }, [activeTabIndex]);

    return (
        <div className="flew-row relative flex h-full bg-transparent px-2 backdrop-blur-sm p-1 font-inter">
              <span
                  className="absolute bottom-0 top-0 -z-10 flex overflow-hidden rounded py-2 transition-all duration-300"
                  style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
              >
                  <span className="h-full w-full rounded bg-backgroundPrimary" />
              </span>
            {tabs.map((tab, index) => {
                const isActive = activeTabIndex === index;

                return (
                    <button
                        key={index}
                        ref={(el) => (tabsRef.current[index] = el)}
                        className={`${
                            isActive ? `text-contentPrimary` : `hover:text-contentPrimary text-contentSecondary`
                        } my-auto cursor-pointer select-none rounded px-3 py-1.5 text-center font-medium leading-tight text-sm`}
                        onClick={() => setActiveTabIndex(index)}
                    >
                        {tab.name}
                    </button>
                );
            })}
        </div>
    );
};

export default HistorySection