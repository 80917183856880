const initialState = {
  session: {},
};

export const sessionViewerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SESSION":
      return {
        ...state,
        session: action.payload
      }
    default:
        return state;
  }
};
