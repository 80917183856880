import React from "react";
import LoadingBar from "../primitives/LoadingBar";

interface SessionProgressBarProps {
    currentQuestion: number;
    questionLength: number;
}

export function SessionProgressBar(props: SessionProgressBarProps) {
    const roundedPercent = Math.round((props.currentQuestion / props.questionLength) * 100);

    return (
        <div className={"w-full flex flex-row items-center px-4 h-full"}>
            <div className={"flex shrink items-center justify-center mr-4"}>
                <p className={'text-sm leading-none font-medium font-inter'}>Session progress</p>
            </div>
            <div className={"flex grow items-center"}>
                <LoadingBar  progress={roundedPercent} className={'bg-primaryB h-4'}/>
            </div>
        </div>
    );
}
