import React, {useEffect, useMemo, useState} from "react";
import SidebarWritingTool from "../sidebar/SidebarWritingTool";
import SetupModal from "../components/writingtool/SetupModal";
import LoadingSpinnerModal from "../components/writingtool/LoadingSpinnerModal";
import ReviewEssay from "../components/writingtool/ReviewEssay";
import ReturnToEssay from "../components/writingtool/ReturnToEssay";
import RenameModal from "../components/writingtool/RenameModal";
import DeleteModal from "../components/writingtool/DeleteModal";
import SubmitEssay from "../components/writingtool/SubmitEssay";
import { Button } from "../components/primitives/button";
import { Modal } from "../components/primitives/modal";
import { useToast } from "../components/ui/use-toast";
import drawerIcon from "../assets/drawer-right.svg";
import axios from "axios";
import { Outlet } from "react-router-dom";
import {useNavbar} from "../NavbarProvider";
import NavbarLayout from "./PopulatableNavbar";
import {NavbarWithStreakIndicator} from "../components/PreconfiguredNavbars/NavbarWithStreakIndicator";

interface EssayPackage {
    essayID: number;
    essayText: string;
}

function getText(html) {
    const divContainer = document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
}

const WritingToolLayout: React.FC = () => {
    const currentPath = window.location.pathname;
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [essay, setEssay] = useState<string>("");
    const [essayPrompt, setEssayPrompt] = useState<string>("");
    const [essaySubmitted, setEssaySubmitted] = useState<boolean>(false);
    const [writeInside, setWriteInside] = useState<boolean>(true);
    const [openSetupModal, setOpenSetupModal] = useState<boolean>(false);
    const [loadingAnalysis, setLoadingAnalysis] = useState<boolean>(false);
    const [isSetPrompt, setIsSetPrompt] = useState<boolean>(true);
    const [isWriting, setIsWriting] = useState<boolean>(false);
    const [tempEssay, setTempEssay] = useState<string>("");
    const [tempEssayPrompt, setTempEssayPrompt] = useState<string>("");
    const [essayPackage, setEssayPackage] = useState<EssayPackage>({ essayText: "", essayID: 0 });
    const [essayTitle, setEssayTitle] = useState<string>("");
    const [task, setTask] = useState<string>("");
    const [returning, setReturning] = useState<boolean>(false);
    const [openRenameModal, setOpenRenameModal] = useState<boolean>(false);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [instanceTitle, setInstanceTitle] = useState<string>("");
    const [instanceId, setInstanceId] = useState<number>(null);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);
    const { navbarContent } = useNavbar();
    const [currentNavbarContent, setCurrentNavbarContent] = useState(navbarContent);

    const { toast } = useToast();

    useEffect(() => {
        if (navbarContent !== null) {
            setCurrentNavbarContent(navbarContent);
        }
    }, [navbarContent]);

    const memoizedNavbarContent = useMemo(() => <NavbarWithStreakIndicator />, []);

    useEffect(() => {
        const handleViewportChange = () => {
            if (isWriting && window.innerWidth < 1536) {
                setIsSidebarOpen(false);
            } else if (isWriting && window.innerWidth < 1600) {
                setIsSidebarOpen(true);
            }
        };
        handleViewportChange();
        window.addEventListener("resize", handleViewportChange);

        return () => {
            window.removeEventListener("resize", handleViewportChange);
        };
    }, [isWriting]);

    useEffect(() => {
        if (writeInside) {
            setTempEssay(null);
        } else {
            setTempEssay("Essay will be written outside");
        }
    }, [writeInside]);

    useEffect(() => {
        if (essayPrompt) {
            setTempEssayPrompt(essayPrompt);
        }
    }, [essayPrompt]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const essayId = searchParams.get("essay_id");

        if (essayId) {
            handleDisplayClickedEssay(Number(essayId));
        }
    }, [location.search]);

    const handleWriteSomethingClick = () => {
        setOpenSetupModal(true);
    };

    const toggleSidebar = () => {
        setIsRightSidebarOpen(isSidebarOpen);
        setIsSidebarOpen(!isSidebarOpen);
    };

    const toggleRightSidebar = () => {
        setIsSidebarOpen(isRightSidebarOpen);
        setIsRightSidebarOpen(!isRightSidebarOpen);
    };

    const handleSubmitEssay = () => {
        const essayTextOnly = getText(tempEssay);
        const essayPromptTextOnly = getText(tempEssayPrompt);
        if (essayPromptTextOnly.length < 3) {
            return toast({
                title: "Prompt too short",
                description: "Please submit a longer prompt.",
            });
        }
        if (essayTextOnly.length < 101) {
            return toast({
                title: "Essay too short",
                description: "Please submit a longer essay.",
            });
        }
        setEssay(tempEssay);
        setEssayPrompt(essayPromptTextOnly);
        const postData = {
            essay_text: essayTextOnly,
            prompt: essayPromptTextOnly,
            title: essayTitle === "" ? "My Essay" : essayTitle,
            writing_category: task ? task : "NA",
        };
        axios.post('/api/v1/writingtoolessay/', postData)
            .then(response => {
                setEssayPackage({ essayID: response.data.id, essayText: tempEssay });
                console.log('Essay successfully submitted:', response.data);
                setEssaySubmitted(true);
                setLoadingAnalysis(true);
            })
            .catch(error => {
                console.error('Error submitting essay:', error);
            });
    };

    const handleComponentRefresh = () => {
        setRefreshFlag(!refreshFlag);
    };

    const handleRenameInstance = () => {
        axios.patch(`/api/v1/writingtoolessay/${instanceId}/`, { title: instanceTitle })
            .then(() => {
                setInstanceTitle("");
                setOpenRenameModal(false);
                handleComponentRefresh();
            })
            .catch(error => {
                console.error('Error fetching essay:', error);
            });
    };

    const handleDeleteInstance = () => {
        axios.delete(`/api/v1/writingtoolessay/${instanceId}/`)
            .then(() => {
                setOpenDeleteModal(false);
                handleComponentRefresh();
            })
            .catch(error => {
                console.error('Error fetching essay:', error);
            });
    };

    const handleFinishReview = () => {
        window.location.href = "/writing_tool/vault";
    };

    const handleDisplayClickedEssay = async (essay_id: number) => {
        axios.get(`/api/v1/writingtoolessay/${essay_id}/`)
            .then(response => {
                const essay_response = response.data;
                setEssayPackage({ essayID: essay_response.id, essayText: essay_response.essay_text });
                setEssayPrompt(essay_response.prompt);
                setIsWriting(true);
                setEssaySubmitted(true);
                setReturning(true);
            })
            .catch(error => {
                console.error('Error fetching essay:', error);
            });
    };

    return (
        <div className="w-full">
            <Modal
                open={openSetupModal}
                onClose={setOpenSetupModal}
                className="absolute top-[60px] left-1/2 transform -translate-x-1/2 max-w-[400px] rounded-0.625rem p-0 overflow-y-auto max-h-calc(100vh - 120px)"
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <SetupModal
                    setPrompt={setEssayPrompt}
                    writeInside={writeInside}
                    setOpenSetupModal={setOpenSetupModal}
                    setWriteInside={setWriteInside}
                    isSetPrompt={isSetPrompt}
                    setIsSetPrompt={setIsSetPrompt}
                    setIsWriting={setIsWriting}
                    setEssayTitle={setEssayTitle}
                    setEssayTask={setTask}
                    essayTask={task}
                />
            </Modal>
            <Modal
                open={loadingAnalysis}
                onClose={setLoadingAnalysis}
                className="absolute top-[60px] left-1/2 transform -translate-x-1/2 max-w-[400px] rounded-0.625rem p-0 overflow-y-auto max-h-calc(100vh - 120px)"
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <LoadingSpinnerModal setLoadingAnalysis={setLoadingAnalysis} />
            </Modal>
            <Modal
                open={openRenameModal}
                onClose={setOpenRenameModal}
                className="absolute top-[60px] left-1/2 transform -translate-x-1/2 max-w-[400px] rounded-0.625rem p-0 overflow-y-auto max-h-calc(100vh - 120px)"
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <RenameModal
                    handleRenameInstance={handleRenameInstance}
                    setOpenRenameModal={setOpenRenameModal}
                    instanceTitle={instanceTitle}
                    setInstanceTitle={setInstanceTitle}
                />
            </Modal>
            <Modal
                open={openDeleteModal}
                onClose={setOpenDeleteModal}
                className="absolute top-[60px] left-1/2 transform -translate-x-1/2 max-w-[400px] rounded-0.625rem p-0 overflow-y-auto max-h-calc(100vh - 120px)"
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DeleteModal
                    setOpenDeleteModal={setOpenDeleteModal}
                    handleDeleteInstance={handleDeleteInstance}
                />
            </Modal>
            <NavbarLayout
                navbarContent={memoizedNavbarContent}
            />
            <div className="w-full flex flex-row h-[calc(100vh-65px)]">
                {isSidebarOpen && (
                    <div className="sticky overflow-y-auto h-[calc(100vh-65px)] top-[65px] p-0 min-w-[256px]">
                        <SidebarWritingTool
                            toggleSidebar={toggleSidebar}
                            writeSomething={handleWriteSomethingClick}
                            isWriting={isWriting}
                        />
                    </div>
                )}
                <div className="w-full overflow-y-auto flex flex-col">
                    <div className="grid grid-cols-2 border-b border-borderOpaque px-[16px] h-12 items-center">
                        <div className="flex flex-row justify-start items-center gap-x-2">
                            <img
                                alt="drawericon"
                                className={`drawer-sidebar-item cursor-pointer ${isSidebarOpen ? 'hidden' : 'block'}`}
                                src={`${drawerIcon}`}
                                onClick={toggleSidebar}
                            />
                            <p className="text-contentPrimary text-sm font-bold font-inter leading-[18px]">
                                {isWriting ? "Writer" : (currentPath === "/saved_feedback" ? "Saved feedback" : "Vault")}
                            </p>
                        </div>
                        {isWriting && (
                            <div className="flex flex-row justify-end items-center h-full">
                                {!essaySubmitted ? (
                                    <Button onClick={handleSubmitEssay} color="secondary">Submit</Button>
                                ) : (
                                    <Button onClick={handleFinishReview} color="secondary">Finish</Button>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="overflow-y-auto h-[calc(100vh-113px)]">
                        {!isWriting ? (
                            <Outlet
                                context={{
                                    openSetupModal,
                                    setOpenSetupModal,
                                    handleDisplayClickedEssay,
                                    setInstanceId,
                                    setOpenRenameModal,
                                    setOpenDeleteModal,
                                    refreshFlag,
                                }}
                            />
                        ) : (
                            <div className="h-full w-full">
                                {essaySubmitted && essayPackage !== null ? (
                                    <div className="h-full">
                                        {!returning ? (
                                            <ReviewEssay
                                                essay={essayPackage}
                                                prompt={essayPrompt}
                                                essaySubmitted={essaySubmitted}
                                                setAnalysisLoading={setLoadingAnalysis}
                                                handleRefresh={handleComponentRefresh}
                                                isRightSidebarOpen={isRightSidebarOpen}
                                                toggleRightSidebarOpen={toggleRightSidebar}
                                                setRightSidebarOpen={setIsRightSidebarOpen}
                                            />
                                        ) : (
                                            <ReturnToEssay
                                                essay={essayPackage}
                                                isRightSidebarOpen={isRightSidebarOpen}
                                                toggleRightSidebarOpen={toggleRightSidebar}
                                            />
                                        )}
                                    </div>
                                ) : (
                                    <div className="h-full">
                                        <SubmitEssay
                                            setEssay={setEssay}
                                            setEssayPrompt={setEssayPrompt}
                                            setEssaySubmitted={setEssaySubmitted}
                                            setLoadingAnalysis={setLoadingAnalysis}
                                            essayPrompt={essayPrompt}
                                            writeInside={writeInside}
                                            isSetPrompt={isSetPrompt}
                                            setTempEssay={setTempEssay}
                                            setTempEssayPrompt={setTempEssayPrompt}
                                            tempEssay={tempEssay}
                                            tempEssayPrompt={tempEssayPrompt}
                                            isExam={false}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WritingToolLayout;
