import React from 'react';

interface Page {
    name: string;
    href: string;
    current: boolean;
}

interface BreadcrumbsProps {
    pages: Page[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ pages }) => {
    return (
        <nav className="flex" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-2">
                <li>
                    <div>
                        <a href="#" className="text-contentTertiary hover:text-gray-500">
                            {/*<HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />*/}
                            <a
                                href={'/dashboard'}
                                className="text-sm font-normal text-contentTertiary hover:text-gray-700 font-sf-pro"
                            >
                                Dashboard
                            </a>
                            <span className="sr-only">Home</span>
                        </a>
                    </div>
                </li>
                {pages.map((page) => (
                    <li key={page.name}>
                        <div className="flex items-center">
                            <svg width="5" height="14" viewBox="0 0 5 14" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.62598 0.705078L1.50879 13.4609H0.367188L3.48438 0.705078H4.62598Z"
                                      fill="#A8A29E"/>
                            </svg>
                            <a
                                href={page.href}
                                className="text-sm font-normal text-contentTertiary hover:text-gray-700 ml-2"
                                aria-current={page.current ? 'page' : undefined}
                            >
                                {page.name}
                            </a>
                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    );
};

export default Breadcrumbs;
