import React, { useEffect, useState } from "react";
import PurchasePage from "./SelectProduct/PurchasePage";
import CheckoutPage from "./Checkout/CheckoutPage";
import { CompanyConfigContext } from "../../CompanyConfigContextProvider";
import {Button} from "../primitives/button";
import {useNavigate} from "react-router-dom";

const PurchaseStateMachine: React.FC = () => {
    const [productSelected, setProductSelected] = useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
    const companyConfig = React.useContext(CompanyConfigContext);
    const navigate = useNavigate();

    if (companyConfig.purchase_page_redirect_url) {
        window.location.href = companyConfig.purchase_page_redirect_url;
        return null;
    }

    useEffect(() => {
        document.title = `Purchase — ${companyConfig.company_name}`;
        if (companyConfig.purchase_page_redirect_url) {
            window.location.href = companyConfig.purchase_page_redirect_url;
        }
    }, [companyConfig]);

    const handleSelectProduct = (product: Product) => {
        if (!companyConfig.disable_purchasing){
            setProductSelected(true);
            setSelectedProduct(product);
        }
    };

    const backToStart = () => {
        setProductSelected(false);
        setSelectedProduct(null);
    };

    let currentComponent = null;
    if (!productSelected) {
        currentComponent = <PurchasePage onSelectProduct={handleSelectProduct} />;
    }
    if (productSelected) {
        currentComponent = (
            <CheckoutPage selectedProduct={selectedProduct} backToStart={backToStart} />
        );
    }
    if (companyConfig.disable_purchasing) {
        currentComponent = (
            <div className={'h-screen w-full flex justify-center items-center flex-col gap-6'}>
                <p className={'text-5xl font-ppe-editorial-new-light'}>Upgrading has been temporarily disabled.</p>
                <Button onClick={()=>navigate('/dashboard')} color={'primary'}>Return to Dashboard</Button>
            </div>
        )
    }

    return <div className={"w-full bg-hatcheryGrey min-h-screen"}>{currentComponent}</div>;
};

export default PurchaseStateMachine;
