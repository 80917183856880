import * as React from "react";
import {useEffect, useRef, useState} from "react";

type AnnotatedEssayProps = {
    essay: string;
    annotations: Annotation[];
    setActiveAnnotation: (annotation: Annotation) => void;
    openSidebarIfNotOpen: () => void;
};

type Annotation = {
    start: number,
    end: number,
    category: AnnotationCategory,
    feedback: string,
    explanation: string,
    hidden: boolean,
}

type AnnotationCategory = {
    name: string,
    color: string,
}

interface TooltipProps {
    children: React.ReactNode;
    position: { top: number; left: number };
    isVisible: boolean;
    annotation: Annotation | undefined;
}


const Tooltip: React.FC<TooltipProps> = ({children, position, isVisible, annotation: Annotation}) => {
    if (!isVisible) return null;
    const transparentBackgroundFromColor = Annotation.category.color.replace("1)", "0.1)");

    return (
        <div
            className={"essay-tooltip"}
            style={{
                border: '1px solid ' + Annotation.category.color,
                position: 'absolute',
                top: position.top, // Use the top and left positions from the state
                left: position.left,
                zIndex: 1000, // Make sure the tooltip is above other elements
                pointerEvents: 'none', // Prevents the tooltip from capturing mouse events
            }}
        >
            <div className={"tooltip-tag"}
            style={{
                backgroundColor: transparentBackgroundFromColor,
                borderColor: Annotation.category.color,
                color: Annotation.category.color,
            }}
            >
                {Annotation.category.name}
            </div>
            <div>
                {children}
            </div>
        </div>
    );
};

function applyAnnotations(essay: string, annotations: Annotation[], setActiveAnnotation: (annotation: Annotation) => void): string {
    let html = "";
    let lastEnd = 0;

    const sortedAnnotations = [...annotations].sort((a, b) => a.start - b.start);

    sortedAnnotations.forEach((annotation) => {
        let start = annotation.start;
        let end = annotation.end;

        // Adjust the start to the beginning of the word
        while (start > 0 && essay[start - 1] !== ' ' && essay[start - 1] !== '\n') {
            start--;
        }

        // Adjust the end to the end of the word
        while (end < essay.length && essay[end] !== ' ' && essay[end] !== '\n') {
            end++;
        }

        if (start < lastEnd) {
            start = lastEnd;
        }

        if (start >= end) {
            return;
        }

        let color = "transparent";
        if (!annotation.hidden) {
            color = annotation.category.color;
            // make colour 10% opacity
            color = color.replace("1)", "0.2)");
        }

        html += essay.slice(lastEnd, start);
        html +=
            `<span id="${annotation.feedback}" style="background-color:${color}; cursor: pointer; line-height: normal;" title="${annotation.feedback}" data-annotation>${essay.slice(start, end)}</span>`;
        lastEnd = end;
    });

    html += essay.slice(lastEnd);

    return html;
}

function AnnotatedEssay(props: AnnotatedEssayProps) {
    const containerRef = useRef(null);
    const [tooltipContent, setTooltipContent] = useState("");
    const [tooltipPosition, setTooltipPosition] = useState({top: 0, left: 0});
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    const handleAnnotationClick = (event) => {
        const annotationEl = event.target;
        const annotationId = annotationEl.getAttribute('id');
        const clickedAnnotation = props.annotations.find(a => a.feedback === annotationId);

        if (clickedAnnotation) {
            props.setActiveAnnotation(clickedAnnotation);

            const annotationRect = annotationEl.getBoundingClientRect();
            const tooltipWidth = 200;
            const tooltipHeight = 100;

            // Calculate the midpoint of the highlighted text
            const highlightMidX = annotationRect.left + annotationRect.width / 2;
            const highlightMidY = annotationRect.top + annotationRect.height / 2;

            // Calculate the offset based on the midpoint
            let offsetX = highlightMidX - tooltipWidth / 2;
            let offsetY = highlightMidY + window.scrollY + 25;

            // Check if the tooltip is too far to the right
            if (offsetX + tooltipWidth > window.innerWidth - 100) {
                offsetX = window.innerWidth - tooltipWidth - 100;
            }

            // Check if the tooltip is too far to the bottom
            if (offsetY + tooltipHeight > window.innerHeight) {
                offsetY = highlightMidY - tooltipHeight - 25;
            }

            setTooltipPosition({
                top: offsetY,
                left: offsetX,
            });

            setTooltipContent(clickedAnnotation.feedback);
            setIsTooltipVisible(true);
        }
    };


    // Hide tooltip when clicking outside of an annotation
    const handleClickOutside = (event) => {
        if (!containerRef.current.contains(event.target)) {
            setIsTooltipVisible(false);
        }
    };

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.innerHTML = applyAnnotations(props.essay, props.annotations, props.setActiveAnnotation);

            const annotations = containerRef.current.querySelectorAll('[data-annotation]');

            annotations.forEach(el => {

                el.addEventListener('click', handleAnnotationClick);
            });

            return () => {
                annotations.forEach(el => {
                    el.removeEventListener('click', handleAnnotationClick);
                });
            };
        }
    }, [props.essay, props.annotations]);
    useEffect(() => {
        // Add event listener to handle clicks outside the tooltip
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Don't forget to clean up the event listener when the component unmounts
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="paper">
            <Tooltip
                position={tooltipPosition}
                isVisible={isTooltipVisible}
                annotation={props.annotations.find(a => a.feedback === tooltipContent)}
            >
                {tooltipContent}
            </Tooltip>
            <div className={"m-0 p-0 overflow-x-none items-center leading-none break-all"} ref={containerRef}/>
        </div>
    );
}

export default AnnotatedEssay;
