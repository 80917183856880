import AnnotatedEssay from "./AnnotatedEssay";
import ExplanationSidebar from "./ExplanationSidebar";
import React, {useEffect, useState} from "react";
import axios from "axios";
import CategoryEnabler from "./CategoryEnabler";
import drawerIcon from "../../assets/drawer-left.svg";
import {connect, ConnectedProps} from "react-redux";
import {useToast} from "../ui/use-toast";

interface RootState {
    auth: {
        auth: {
            user: {
                id: string;
                username: string;
            };
        };
    };
}

type NavbarContextType = {
    navbarContent: React.ReactNode;
    updateNavbarContent: (content: React.ReactNode) => void;
};

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface AdditionalProps extends PropsFromRedux {}

type AnnotationCategory = {
    name: string,
    color: string,
}

type Annotation = {
    start: number,
    end: number,
    category: AnnotationCategory,
    feedback: string,
    explanation: string,
    hidden: boolean,
}

type serverCategory = {
    name: string,
}

type ServerOutput = {
    starting_character: number,
    ending_character: number,
    category: serverCategory,
    explanation: string,
    feedback: string,
}

interface EssayPackage {
    essayID : number,
    essayText : string,
}

type ReviewEssayProps = {
    essay: EssayPackage,
    prompt: string,
    essaySubmitted: boolean,
    setAnalysisLoading: (loading: boolean) => void,
    handleRefresh: () => void,
    setRightSidebarOpen: (isOpen: boolean) => void,
    toggleRightSidebarOpen: () => void,
    isRightSidebarOpen: boolean;
}
const AnnotationCategories: AnnotationCategory[] = [
    {
        name: "Idea Development",
        color: "rgba(249, 139, 218, 1)",
    },
    {
        name: "Argument",
        color: "rgba(243, 32, 32, 1)",
    },
    {
        name: "Organisation",
        color: "rgba(0, 71, 255, 1)",
    },
    {
        name: "Expression",
        color: "rgba(252, 186, 18, 1)",
    },
    {
        name: "Evidence & Analysis",
        color: "rgba(51, 192, 48, 1)",
    }
]

const ReviewEssay: React.FC<ReviewEssayProps & AdditionalProps> = (props) => {
    const [activeAnnotation, setActiveAnnotation] = useState<Annotation | null>(null);
    const [annotations, setAnnotations] = useState<Annotation[]>([]);
    const [activeCategories, setActiveCategories] = useState<AnnotationCategory[]>(AnnotationCategories);

    const openSidebarIfNotOpen = () => {
        if (!props.isRightSidebarOpen) {
            props.toggleRightSidebarOpen()
        }
    };
    const { toast } = useToast()
    useEffect(() => {
        openSidebarIfNotOpen()
    }, []);

    useEffect(() => {
        let isMounted = true;
        async function getEssayFeedbackFromServer(essay_id) {
            if (props.essaySubmitted) {
                try {
                    const response = await axios.post(`/essay-feedback/?essay_id=${essay_id}`).then((response) => {
                        props.handleRefresh;
                        return response
                    }).catch((error) => {
                        toast({
                            variant: 'destructive',
                            title: "Error",
                            description: "Error getting feedback from server. Please try again later.",
                        })
                        props.setAnalysisLoading(false);
                        return;
                    })
                    if (response) {
                        const serverOutput: ServerOutput[] = response.data.annotations;

                        const annotations: Annotation[] = serverOutput.map((serverOutput) => {

                            const randomColor = Math.floor(Math.random() * 16777215).toString(16);
                            // get color for category
                            const categoryColor = AnnotationCategories.find(category => category.name === serverOutput.category.name)?.color;
                            props.setAnalysisLoading(false);
                            return {
                                start: serverOutput.starting_character,
                                end: serverOutput.ending_character,
                                category: {
                                    name: serverOutput.category.name,
                                    color: categoryColor ? categoryColor : "#" + randomColor,
                                },
                                explanation: serverOutput.explanation,
                                hidden: false,
                                feedback: serverOutput.feedback,
                            };


                        });
                        setAnnotations(annotations);
                    }

                } catch (error) {
                    console.error("Error getting feedback:", error);
                }
            }
        }

        // Fetch essay feedback immediately upon mounting the component
        getEssayFeedbackFromServer(
            props.essay.essayID,
        );

        return () => {
            isMounted = false; // Mark the component as unmounted when it is cleaned up
        };
    }, [props.essay.essayID]);

    useEffect(() => {
        const updatedAnnotations = annotations.map(annotation => {
            return {
                ...annotation,
                hidden: !activeCategories.map(category => category.name).includes(annotation.category.name)
            };
        });
        setAnnotations(updatedAnnotations);
    }, [activeCategories]);
    return (
        <div className={"min-w-full h-full"}>
            <div style={{display: "flex", height: "100%", width:"100%"}}>
                <div className={"w-full"}>
                    <div className={"main-section"}>
                        <AnnotatedEssay essay={props.essay.essayText} annotations={annotations}
                                        setActiveAnnotation={setActiveAnnotation} openSidebarIfNotOpen={openSidebarIfNotOpen}/>
                    </div>
                </div>
                {!props.isRightSidebarOpen ? (
                        <div className={"justify-end mt-2 mr-2"}>
                            <div style={{minHeight:"40px", display:"flex", alignItems:"center",}}>
                                <div style={{}}>
                                    <img
                                        alt={"drawericon"}
                                        className={"drawer-sidebar-item cursor-pointer"}
                                        src={`${drawerIcon}`}
                                        onClick={props.toggleRightSidebarOpen}
                                    />
                                </div>
                            </div>
                        </div>
                    ):
                    <div className={"side-toolbar"}>
                        <CategoryEnabler categories={AnnotationCategories} activeCategories={activeCategories}
                                         setActiveCategories={setActiveCategories} toggleSidebar={props.toggleRightSidebarOpen}/>
                        {activeAnnotation &&
                            <ExplanationSidebar explanation={activeAnnotation.explanation}
                                                feedback={activeAnnotation.feedback}
                                                categories={AnnotationCategories}/>}
                    </div>
                }
            </div>
        </div>
    );
}

export default connector(ReviewEssay);