import React from 'react';
import Slider from "./Slider";

interface Props {
    marks: { [key: number]: { label: React.ReactNode } };
    defaultValue: number[];
    secondary?: boolean;
    name?: string;
    percentageActual?: number;
}

const MetricGuage: React.FC<Props> = (props) => {
    let sliderColor ='';
    if (props.secondary) {
        sliderColor = "var(--primaryB)";
    } else {
        sliderColor = "var(--primaryA)";
    }

    const lowStyle: React.CSSProperties = {
        color: props.percentageActual && props.percentageActual <= 17.1 ? sliderColor : '',
        width:"17.1%",
    };

    const moderateStyle: React.CSSProperties = {
        color: props.percentageActual && props.percentageActual > 17.1 && props.percentageActual < 82.9 ? sliderColor : '',
        width:"65.8%"
    };

    const highStyle: React.CSSProperties = {
        color: props.percentageActual && props.percentageActual >= 82.9 ? sliderColor : '',
        width:"17.1%"
    };

    return (
        <div className="mt-2 mb-2 w-full py-2">
            <div className="mb-2 flex-row flex relative justify-between gap-x-2">
                <p className="font-normal w-50 text-xs leading-none shrink ">{props.name}</p>
                <div className="justify-end flex pt-1 w-full max-w-[344px]">
                    <div className={"w-full lg:w-[344px]"}>
                    <Slider marks={props.marks} defaultValue={props.defaultValue} secondary={props.secondary}/>
                    <div
                        className="flex flex-row w-full gap-x-2 relative items-center pointer-events-none mb-1 mt-2 text-contentStateDisabled font-Inter text-xs">
                        <div className="flex justify-center" style={lowStyle}>
                            Low
                        </div>
                        <div className="flex justify-center" style={moderateStyle}>
                            Moderate
                        </div>
                        <div className="flex justify-center" style={highStyle}>
                            High
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MetricGuage;
