import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom"; // Updated imports
import { connect } from "react-redux";
import axios from "axios";
import PrivacyPolicy from "../fixedelements/PrivacyPolicy";
import { CompanyConfigContext } from "../../CompanyConfigContextProvider";
import { useToast } from "../ui/use-toast";

const ActivateAccount: React.FC = () => {
    const [status, setStatus] = useState<string>("");
    const companyConfig = useContext(CompanyConfigContext);
    const navigate = useNavigate(); // Use useNavigate instead of useHistory
    const { uid, token } = useParams<{ uid: string; token: string }>();
    const { toast } = useToast();

    useEffect(() => {
        document.title = `Activate Account — ${companyConfig.company_name}`;

        axios
            .post("/api/v1/users/activation/", { uid, token })
            .then(response => {
                toast({
                    title: "Success",
                    description: "Account activated successfully. You can now log in.",
                });
                navigate("/login"); // Use navigate instead of history.push
            })
            .catch(error => {
                setStatus("error");
            });
    }, [uid, token, companyConfig, navigate, toast]);

    const errorAlert = (
        <div className={"flex flex-col justify-center items-center gap-y-2"}>
            <p className={"text-lg "}>Problem during account activation</p>
            <span>
                Please try again or contact service support for further help.
                You can try again by clicking <Link className={"text-accent text-lg font-medium"} to="/resend_activation">here</Link>.
            </span>
        </div>
    );

    let alert: React.ReactNode = null;
    if (status === "error") {
        alert = <>{errorAlert}</>;
    }

    return (
        <div className="h-full flex flex-col items-center bg-hatcheryGrey">
            <div className="w-full absolute top-4 flex justify-center">
                <img
                    src={companyConfig.company_logo_navbar}
                    className={"size-8 flex items-center"}
                    alt="Hatchery Icon"
                />
            </div>
            <div className={"flex justify-center items-center align-middle mx-20 h-full w-full"}>
                <div className={"flex-col flex gap-y-6"}>
                    <div className={"flex flex-col gap-y-4 justify-center items-center"}>
                        <div
                            className="text-center text-black text-[40px] font-light font-ppe-editorial-new-light leading-10 px-10"
                        >
                            Activate Account
                        </div>
                        <div>{alert}</div>
                    </div>
                </div>
                <PrivacyPolicy />
            </div>
        </div>
    );
};

export default connect(null)(ActivateAccount);
