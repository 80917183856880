import React from "react";
import {Button} from "../primitives/button";

interface ComponentEmpty {
    setOpenSetupModal: (open: boolean) => void;
}


const ComponentEmpty : React.FC<ComponentEmpty> = ({setOpenSetupModal}) => {
    return (
        <div className={"w-full p-[16px]"}>
            <div className={"flex py-16 px-8 dotted-border justify-center"}>
                <div className={"flex flex-col justify-start"}>
                    <p className={"font-inter-tight font-extrabold text-[24px] mb-2"}>Write your first piece</p>
                    <p className={"font-inter text-sm mb-6"}>There’s no time like the moment. Try your hand at your
                        first written task. </p>
                    <Button
                        className={"w-[134px] h-8"}
                        color={"primary"}
                        onClick={() => setOpenSetupModal(true)}>
                            <p className="text-black text-sm font-medium font-inter leading-tight">Write something
                        </p>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ComponentEmpty