import React, { useEffect, useState } from "react";
import 'react-quill/dist/quill.snow.css';
import { connect } from "react-redux";
import { NavbarWithStreakIndicator } from "../PreconfiguredNavbars/NavbarWithStreakIndicator";
import ReactQuill from "react-quill";
import {CogIcon} from "@heroicons/react/16/solid";
import {useNavbarContent} from "../../NavbarProvider";

interface RootState {
    auth: {
        auth: {
            user: {
                id: string;
                username: string;
            };
        };
    };
}

type NavbarContextType = {
    navbarContent: React.ReactNode;
    updateNavbarContent: (content: React.ReactNode) => void;
};

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
});

const connector = connect(mapStateToProps);

type SubmitEssayProps = {
    setEssay: (essay: string) => void,
    setEssayPrompt: (essayPrompt: string) => void,
    setEssaySubmitted: (essaySubmitted: boolean) => void,
    setLoadingAnalysis: (loadingAnalysis: boolean) => void,
    essayPrompt: string,
    writeInside: boolean,
    isSetPrompt: boolean,
    setTempEssay: (tempEssay: string) => void,
    setTempEssayPrompt: (tempEssayPrompt: string) => void,
    tempEssay: string,
    tempEssayPrompt: string,
    isExam:boolean;
}

function getText(html) {
    const divContainer = document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
}

function SubmitEssay(props: SubmitEssayProps) {
    const [essayEditorState, setEssayEditorState] = useState<NonNullable<unknown>>(null);
    const [essayPromptEditorState, setEssayPromptEditorState] = useState<NonNullable<unknown>>(null);
    useNavbarContent({
        component: NavbarWithStreakIndicator
    });
    useEffect(() => {
        if (props.writeInside) {
            props.setTempEssay(null);
        } else {
            props.setTempEssay("Essay will be written outside");
        }
    }, [props.writeInside]);

    useEffect(() => {
        if (essayEditorState && essayPromptEditorState) {
            /* empty */
        }
    }, [essayEditorState, essayPromptEditorState]);

    useEffect(() => {
        props.setTempEssayPrompt(props.essayPrompt);
    }, [props.essayPrompt, props.setEssayPrompt, props.setTempEssayPrompt]);

    return (
        <div className={"pt-16 pb-[120px] w-fit justify-center flex  mx-auto"}>
            <div className={"flex justify-center  relative flex-row gap-x-10 px-2"}>
                <div className={"w-[708px]"}>
                    <ReactQuill
                        className={""}
                        theme="bubble"
                        value={props.tempEssay}
                        onChange={props.setTempEssay}
                        placeholder={'Enter your essay here...'}
                        style={{
                            width: "100%",
                            lineHeight: '1.5rem',
                        }}
                        readOnly={!props.writeInside}
                        modules={{
                            toolbar: [
                                [{header: [1, 2, false]}],
                                ['bold', 'italic', 'underline'],
                                [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
                            ],
                        }}
                    />
                </div>
                <div
                    className="flex justify-center xl:absolute xl:right-0 xl:top-0 xl:translate-x-[calc(100%+40px)] cursor-pointer group"
                >
                    {props.isSetPrompt ? (
                        <div
                            className={`border rounded-lg p-3 flex flex-col gap-y-3 group-hover:text-contentPrimary group-hover:border-borderSelected focus-within:text-contentPrimary focus-within:border-borderSelected text-contentTertiary border-borderOpaque w-[250px]`}
                        >
                            <div
                                className="flex flex-row gap-x-1 items-center group-hover:text-contentTertiary text-contentTertiary">
                                <div className="pb-1">
                                    <CogIcon className="size-4"/>
                                </div>
                                <p className="text-sm font-ppe-editorial-new-light leading-none">
                                    Prompt
                                </p>
                            </div>
                            <p className="font-inter font-normal text-xs">
                                <p dangerouslySetInnerHTML={{__html: props.tempEssayPrompt}}/>
                            </p>
                        </div>
                    ) : (
                        <div
                            className={`border rounded-lg p-3 flex flex-col gap-y-3 focus-within:border-borderSelected group-hover:border-borderSelected border-borderOpaque text-red-800 w-[250px] focus-within:text-contentPrimary`}
                        >
                            <textarea
                                className={`order-2 font-inter font-normal peer border-none text-xs w-full p-0 focus:outline-none focus:ring-0 focus:text-contentPrimary focus:placeholder:text-contentPrimary text-stone-300 placeholder:text-stone-300`}
                                placeholder="Type prompt here..."
                                value={props.tempEssayPrompt}
                                onChange={(e) => props.setTempEssayPrompt(e.target.value)}
                            />
                            <div
                                className={`order-1 flex flex-row gap-x-1 items-center text-stone-300 group-hover:text-contentTertiary focus-within:text-contentTertiary peer-focus:text-contentTertiary focus:text-contentTertiary`}
                            >
                                <div className="pb-1">
                                    <CogIcon className="size-4"/>
                                </div>
                                <p className="text-sm font-ppe-editorial-new-light leading-none">
                                    What was your prompt?
                                </p>
                            </div>

                        </div>
                    )}
                </div>

                {/*<Editor onChange={props.setTempEssay} id={"essay"}/>*/}
            </div>
        </div>
    );
}

export default connector(SubmitEssay);