import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player";
import { ring } from 'ldrs';
import axios from "axios";
ring.register();

interface VideoPlayerProps {
    video: Video;
    playing: boolean;
    muted: boolean;
    controls: boolean;
    onProgress?: (playedSeconds: number) => void;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ video, playing, muted, controls, onProgress }) => {
    const [presignedUrl, setPresignedUrl] = useState<string | null>(null);
    const mountTimeRef = useRef<number>(Date.now());
    const totalTimeSpentRef = useRef<number>(0);

    useEffect(() => {
        const fetchVideoUrl = async () => {
            try {
                const response = await axios.get(`/video?id=${video.id}`);
                setPresignedUrl(response.data.presigned_url);
            } catch (error) {
                console.error("Error fetching video URL", error);
            }
        };

        fetchVideoUrl();

        return () => {
            const unmountTime = Date.now();
            const timeSpent = (unmountTime - mountTimeRef.current) / 1000;
            totalTimeSpentRef.current += timeSpent;

            if (totalTimeSpentRef.current > 60) {
                createEventLog(Math.round(totalTimeSpentRef.current));
            }
        };
    }, [video.id]);

    const createEventLog = async (timeSpent: number) => {
        try {
            await axios.post('/create_event_log_endpoint', {
                event_type: 'VIDEO',
                event_reference: video.id,
                event_text: `Viewed video "${video.name}" for ${(timeSpent/60).toFixed(0)} minutes`
            });
            console.log('Event log created successfully');
        } catch (error) {
            console.error('Error creating event log', error);
        }
    };

    return (
        <div className="aspect-[4/3] w-full h-full">
            {presignedUrl ? (
                onProgress ?
                    <ReactPlayer
                        height="100%"
                        width="100%"
                        style={{ backgroundColor: "black", outline: "none" }}
                        url={presignedUrl}
                        playing={playing}
                        muted={muted}
                        controls={controls}
                        preload="true"
                        onProgress={({ playedSeconds }) => onProgress(playedSeconds)}
                        config={{
                            file: {
                                attributes: {
                                    controlsList: "nodownload"
                                }
                            }
                        }}
                    />
                    :
                    <ReactPlayer
                        height="100%"
                        width="100%"
                        style={{ backgroundColor: "black", outline: "none" }}
                        url={presignedUrl}
                        playing={playing}
                        muted={muted}
                        controls={controls}
                        preload="true"
                        config={{
                            file: {
                                attributes: {
                                    controlsList: "nodownload"
                                }
                            }
                        }}
                    />
            ) : (
                <div className="w-full h-full">
                    <div
                        role="status"
                        className="flex items-center justify-center h-full w-full bg-gray-300 rounded-lg animate-pulse dark:bg-gray-700"
                    >
                        <svg
                            className="w-10 h-10 text-gray-200 dark:text-gray-600"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 16 20"
                        >
                            <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                            <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM9 13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2Zm4 .382a1 1 0 0 1-1.447.894L10 13v-2l1.553-1.276a1 1 0 0 1 1.447.894v2.764Z" />
                        </svg>
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VideoPlayer;