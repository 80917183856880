import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

export default function requireAuth(Component) {
    const AuthenticatedComponent = (props) => {
        const navigate = useNavigate();
        const location = useLocation();
        const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
        const email = useSelector((state) => state.auth.email);
        const firstName = useSelector((state) => state.auth.firstName);

        useEffect(() => {
            checkAuth();
        }, [isAuthenticated, location]);

        const checkAuth = () => {
            if (!isAuthenticated) {
                const redirectAfterLogin = location.pathname;
                navigate(`/login?next=${redirectAfterLogin}`);
            }
        };

        return (
            <>
                {isAuthenticated === true ? <Component {...props} /> : null}
            </>
        );
    };

    return AuthenticatedComponent;
}
