import React from "react";
import { motion } from "framer-motion";
import { cn } from "../../utils/ShadcnUtils";

interface LoadingBarProps {
    progress: number;
    className: string;
}

function LoadingBar({ progress, className }: LoadingBarProps) {
    return (
        <div className="w-full bg-borderOpaque rounded-full h-fit">
            <motion.div
                className="h-fit rounded-full"
                initial={{ width: 0 }}
                animate={{ width: `${progress}%` }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
            >
                <div
                    className={cn("rounded-full w-full bg-primaryB h-1.5", className)}
                />
            </motion.div>
        </div>
    );
}

export default LoadingBar;