import React, {useEffect} from "react";
import * as echarts from 'echarts';

interface CohortCompletionHeatmap {
    section: SectionResults;
    name: string;
}

export function CohortCompletionHeatmap(props: CohortCompletionHeatmap) {

    useEffect(() => {
        const myChart = echarts.init(document.getElementById(String(props.section.id)+'cohort_completion'));
        myChart.resize({
            width: 900,
            height: 350
        });

        const status = [
            'Incomplete', 'Incorrect', 'Correct',
        ];

        const data = props.section.cohort_completion.map(function (item) {
                return [item[1]+1, item[2], item[0] || '-', item[3]];
            });

        const options = {
            tooltip: {
                position: 'top',
                formatter: function (params) {
                    return (
                        'Question Number: ' + params.value[0] + '<br/>' +
                        'Cohort Distribution: ' + params.value[2] + '%<br/>'

                    );
                }
            },
            grid: {
                height: '50%',
                top: '10%'
            },
            xAxis: {
                type: 'category',
                splitArea: {
                    show: true
                },
                axisLabel: {
                    show: false
                },
                axisLine: {
                    show: false
                },
                splitLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
            },
            yAxis: {
                type: 'category',
                data: status,
                axisLabel: {
                    show: true,
                    textStyle: {
                        color:'#A8A29E',
                        fontFamily:'inter',
                    },
                },
                splitArea: {
                    show: true
                },
                axisLine: {
                    show: false
                },
                splitLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
            },
            visualMap: {
                min: 0,
                max: 100,
                calculable: true,
                orient: 'horizontal',
                left: 'center',
                bottom: '15%',
                inRange: {
                    color: ['#fde68a', '#fde68a', '#fbbf24'],
                },
                textStyle: {
                    color:'#A8A29E',
                    fontFamily:'inter',
                },
            },
            series: [
                {
                    name: 'Question',
                    type: 'heatmap',
                    data: data.map((item) => ({
                        value: [item[0], item[1], item[2]],
                        label: {
                            show: item[3],
                            position: 'inside',
                            formatter: () => item[3] ? '○' : '',
                        },
                    })),
                    label: {
                        show: false
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        }
                    },
                }
            ]
        };

        myChart.setOption(options);

        // Cleanup on unmount
        return () => {
            myChart.dispose();
        };
    }, [props]);

    return (
        <>
            <div className={"flex items-center"}>
                <div id={String(props.section.id) + 'cohort_completion'}
                     className={'w-fit h-fit '}
                />
            </div>
        </>
    );
}
