import React, { useEffect, useState } from "react";
import axios from "axios";
import UserAvatar from "../account/UserAvatar";
import { createSessionWithoutDispatch } from "../sessioncreator/SessionCreatorActions";
import {Button} from "../primitives/button";
import { useToast} from "../ui/use-toast";
import aiIcon from "../../assets/ai-icon.svg";
import StreakIndicator from "../dashboard/StreakIndicator";
import useRedirectToPurchasePage from "../../utils/useRedirectToPurchasePage";
import Divider from "../primitives/Divider";
import {useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";


function getRemainingQuestionCredits(setRemainingQuestionCredits: React.Dispatch<React.SetStateAction<number>>) {
    axios.get("/user_remaining_question_credits")
        .then(response => {
            setRemainingQuestionCredits(response.data.question_credits);
        }).catch(error => {
    });
}

function getUserSubscriptionDetails(setSubscriptionStatus: React.Dispatch<React.SetStateAction<boolean | null>>, setRemainingQuestionCredits: React.Dispatch<React.SetStateAction<number>>) {
    axios.get("/user_has_active_subscription_request")
        .then(response => {
            if (response.data.has_active_subscription === true) {
                setSubscriptionStatus(true);
            } else {
                getRemainingQuestionCredits(setRemainingQuestionCredits);
                setSubscriptionStatus(false);
            }
            getRemainingQuestionCredits(setRemainingQuestionCredits);
        }).catch(error => {
    });
}

export function NavbarWithStreakIndicator() {
    const [subscriptionStatus, setSubscriptionStatus] = useState<boolean | null>(null);
    const [remainingQuestionCredits, setRemainingQuestionCredits] = useState(0);
    const { toast } = useToast()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        getUserSubscriptionDetails(setSubscriptionStatus, setRemainingQuestionCredits);
    }, []);
    const redirectToPurchasePage = useRedirectToPurchasePage();

    return (
        <>
            <div className={"w-full flex align-middle"}>
                <div className={"grid grid-cols-2 w-full"}>
                    <div className={"w-[424px] flex justify-start items-center invisible lg:visible"}>
                        <StreakIndicator/>
                    </div>
                    <div className={"flex flex-row gap-x-6 justify-end items-center"}>
                        {subscriptionStatus === false ?
                            <Button
                                color={"secondary"}
                                onClick={redirectToPurchasePage}>
                                Upgrade
                            </Button>
                            :
                            null
                        }
                        <Button className={"h-9"}
                                onClick={async () => {
                                    await createSessionWithoutDispatch(navigate)
                                    window.location.href = "/current_session"
                                }}
                        >
                            <img alt={"ai icon"} src={`${aiIcon}`}/>
                            <p className={""}>Let AI Create Session</p>
                        </Button>
                        <Divider type={"vertical"} className={"h-5"}/>
                        <UserAvatar/>
                    </div>
                </div>
            </div>
        </>
    );
}
