import React, { createContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';
import { darken, saturate, lighten } from 'polished';

const defaultConfig: CompanyConfig = {
    company_name: 'Hatchery',
    company_abn: '94 664 914 694',
    company_logo_navbar: '',
    company_favicon: '',
    company_email: 'hello@hatchery.com.au',
    company_phone: '',
    company_address: '',
    purchase_page_redirect_url: null,
    disable_purchasing: false,
    disable_progress_reports: false,
    disable_community: false,
    premium_only_exams: false,
    premium_only_videos: false,
    premium_only_writing_tool: false,
    state: null,
    company_primary_colour: '#FDBEC9',
    company_secondary_colour: '#7353BA',
    company_accent_colour: '#12B76A',
    allow_users_to_skip_placeholders: false,
    collect_external_reference: false,
    collect_external_reference_label: '',
};

export const CompanyConfigContext = createContext<CompanyConfig>(defaultConfig);

const CompanyConfigProvider = ({ children }: { children: ReactNode }) => {
    const [companyConfig, setCompanyConfig] = useState<CompanyConfig>(() => {
        const storedConfig = localStorage.getItem('companyConfig');
        return storedConfig ? JSON.parse(storedConfig) : defaultConfig;
    });

    useEffect(() => {
        (async () => {
            try {
                const response = await axios.get<Partial<CompanyConfig>>("/company_config");

                const filteredResponse = Object.entries(response.data).reduce((acc, [key, value]) => {
                    if (value !== null) {
                        acc[key] = value;
                    }
                    return acc;
                }, {} as Partial<CompanyConfig>);

                const newConfig: CompanyConfig = {
                    ...defaultConfig,
                    ...filteredResponse,
                };

                if (JSON.stringify(newConfig) !== JSON.stringify(companyConfig)) {
                    setCompanyConfig(newConfig);
                    localStorage.setItem('companyConfig', JSON.stringify(newConfig));
                }

                setCSSVariables(newConfig);
                const faviconElement = document.getElementById('dynamic-favicon') as HTMLLinkElement;
                if (faviconElement) {
                    faviconElement.href = newConfig.company_favicon;
                }
                document.title = newConfig.company_name;
            } catch (error) {
                console.error('Failed to fetch company config:', error);
            }
        })();
    }, []);

    function colorIsDarkAdvanced(bgColor) {
        const color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
        const r = parseInt(color.substring(0, 2), 16); // hexToR
        const g = parseInt(color.substring(2, 4), 16); // hexToG
        const b = parseInt(color.substring(4, 6), 16); // hexToB
        const uicolors = [r / 255, g / 255, b / 255];
        const c = uicolors.map((col) => {
            if (col <= 0.03928) {
                return col / 12.92;
            }
            return Math.pow((col + 0.055) / 1.055, 2.4);
        });
        const L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
        return L <= 0.279; //Accessibility guidelines say this value should be 0.179
    }
    
    const setCSSVariables = (config: CompanyConfig) => {
        const root = document.documentElement;
        const primaryA = config.company_primary_colour;
        const primaryB = config.company_secondary_colour;
        const accent = config.company_accent_colour;
        const primaryAText = colorIsDarkAdvanced(primaryA) ? '#FFFFFF' : '#000000';
        const primaryBText = colorIsDarkAdvanced(primaryB) ? '#FFFFFF' : '#000000';

        const primaryASharp = darken(0.2, saturate(0.3, primaryA));
        const primaryALight = lighten(0.2, primaryA);

        root.style.setProperty('--primaryA', primaryA);
        root.style.setProperty('--primaryB', primaryB);
        root.style.setProperty('--accent', accent);
        root.style.setProperty('--primaryAText', primaryAText);
        root.style.setProperty('--primaryBText', primaryBText);
        root.style.setProperty('--primaryASharp', primaryASharp);
        root.style.setProperty('--primaryALight', primaryALight);
    };

    return (
        <CompanyConfigContext.Provider value={companyConfig ?? defaultConfig}>
            {children}
        </CompanyConfigContext.Provider>
    );
};

export default CompanyConfigProvider;
