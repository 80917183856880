import React, {Fragment, useCallback, useEffect, useRef, useState} from "react";
import axios from "axios";
import VideoPlayer from "./VideoPlayer";
import {NavbarWithStreakIndicator} from "../PreconfiguredNavbars/NavbarWithStreakIndicator";
import searchIcon from "../../assets/search.svg";
import { connect, ConnectedProps } from "react-redux";
import {
    ArrowLeftIcon,
    CheckIcon,
    FunnelIcon,
    LockClosedIcon,
    XMarkIcon
} from "@heroicons/react/16/solid";
import { Popover, Transition} from '@headlessui/react';
import { Button } from "../primitives/button";
import {CompanyConfigContext} from "../../CompanyConfigContextProvider";
import useRedirectToPurchasePage from "../../utils/useRedirectToPurchasePage";
import {Badge} from "../primitives/badge";
import Divider from "../primitives/Divider";
import { motion, AnimatePresence } from "framer-motion";
import {useLocation} from "react-router-dom";
import {useNavbar, useNavbarContent} from "../../NavbarProvider";

interface VideoSelectionCardProps {
    video: Video;
    isLocked: boolean;
    onClick: () => void;
}

const splitTags = (tags: string) => {
    return tags.split(',').map(tag => tag.trim());
};

const VideoSelectionCard: React.FC<VideoSelectionCardProps> = ({ video, isLocked, onClick }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className="flex justify-center h-full">
            <div
                className={`h-full bg-backgroundPrimary w-[358px] border-2 rounded-lg  hover:border-borderSelected border-borderOpaque hover:border-2 ${
                    !isLocked ? 'cursor-pointer' : ''
                }`}
                onClick={isLocked ? undefined : onClick}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div className={"overflow-clip rounded-t-md"}>
                    {isLocked ? (
                        <div className="relative">
                            <img
                                alt="Error loading thumbnail"
                                src={video.thumbnail_url}
                                className=" h-[186px] object-fill w-full"
                            />
                            <div
                                className={`absolute inset-0 flex items-center justify-center ${
                                    isHovered ? '' : 'bg-black bg-opacity-50'
                                }`}
                            >
                                <LockClosedIcon className={"text-primaryA size-12"}/>
                            </div>
                        </div>
                    ) : (
                        <div className="relative">
                            <img
                                alt="Error loading thumbnail"
                                src={video.thumbnail_url}
                                className=" h-[186px] object-fill w-full"
                            />
                            {isHovered && (
                                <div
                                    className="absolute inset-0 flex items-center justify-center"
                                    onClick={() => {
                                        setIsHovered(false);
                                        onClick();
                                    }}
                                >
                                    <VideoPlayer video={video} playing={true} muted={true} controls={false}/>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="text-xl p-4 font-semibold font-inter-tight text-contentPrimary h-[calc(100%-186px)]">
                    <div className={"flex flex-col h-full"}>
                        <div className={"grow mb-3"}>
                            <p className="text-ellipsis overflow-hidden max-w-full leading-tight">{video.name}</p>
                        </div>
                        <div className={"flex flex-row gap-x-3 flex-shrink"}>
                            {video.tags && (
                                <div className="flex flex-row gap-x-2">
                                    {splitTags(video.tags).map((tag, index) => (
                                        <div key={index}
                                             className="py-1 px-1.5 bg-backgroundLime text-contentLime text-xs font-medium font-inter leading-none text-center flex items-center rounded-md">
                                            {tag}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface ContentPortalState {
    videos: Video[];
    subscriptionStatus: boolean;
    videoToPlay: Video | null;
    searchQuery: string;
    remainingDays?: number;
    expirationDate?: string;
}

interface OutletContext {
    setHideSidebar: (hide: boolean) => void;
}

interface TranscriptEntry {
    id: number;
    start: number;
    end: number;
    text: string;
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface ContentPortalProps extends PropsFromRedux {
}

const ContentPortal: React.FC<ContentPortalProps> = (props) => {
    const companyConfig = React.useContext(CompanyConfigContext);
    const [state, setState] = useState<ContentPortalState>({
        videos: [],
        subscriptionStatus: false,
        videoToPlay: null,
        searchQuery: "",
    });
    const [isPlaying, setIsPlaying] = useState(false);
    const [tags, setTags] = useState<VideoTag[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [transcript, setTranscript] = useState<TranscriptEntry[]>([])
    const { setHideSidebar } = useNavbar();
    const location = useLocation();
    useNavbarContent({
        component: NavbarWithStreakIndicator
    });

    function cleanSpecialCharacters(text: string): string {
        return text.replace(/[^\w\s]/gi, ''); // Removes all special characters except spaces
    }

    function combineTranscriptEntries(entries: TranscriptEntry[]): TranscriptEntry[] {
        const combinedEntries: TranscriptEntry[] = [];

        for (let i = 0; i < entries.length; i += 2) {
            const firstEntry = entries[i];
            const secondEntry = entries[i + 1];

            if (secondEntry) {
                combinedEntries.push({
                    id: firstEntry.id, // Keep the ID of the first entry
                    start: firstEntry.start,
                    end: secondEntry.end,
                    text: `${firstEntry.text} ${secondEntry.text}`,
                });
            } else {
                combinedEntries.push(firstEntry);
            }
        }

        return combinedEntries;
    }

    function processJSONString(transcript: string): TranscriptEntry[] {
        try {
            const transcriptEntries: TranscriptEntry[] = JSON.parse(transcript);

            const cleanedEntries = transcriptEntries.map(entry => ({
                ...entry,
                text: cleanSpecialCharacters(entry.text),
            }));

            return combineTranscriptEntries(cleanedEntries);
        } catch (error) {
            console.error("Invalid JSON input:", error);
            return [];
        }
    }

    const handleTagSelection = (tagId: number) => {
        const newTags = tags.map(tag =>
            tag.id === tagId ? { ...tag, checked: !tag.checked } : tag
        );
        setTags(newTags);
        const selectedTagNames = newTags.filter(tag => tag.checked).map(tag => tag.name);

        axios.get("/list_videos", {
            params: {
                tags: selectedTagNames,
            }
        })
            .then(response => {
                setState(prevState => ({ ...prevState, videos: response.data.videos }));
            })
            .catch(error => {
                console.log('Error:', error);
            });
    };

    const handleTagRemoval = (tagId: number) => {
        const newTags = tags.map(tag =>
            tag.id === tagId ? { ...tag, checked: false } : tag
        );
        setTags(newTags);
        const selectedTagNames = newTags.filter(tag => tag.checked).map(tag => tag.name);

        axios.get("/list_videos", {
            params: {
                tags: selectedTagNames,
            }
        })
            .then(response => {
                setState(prevState => ({ ...prevState, videos: response.data.videos }));
            })
            .catch(error => {
                console.log('Error:', error);
            });
    };

    const handleRemoveAllTags = () => {
        const newTags = tags.map(tag => ({ ...tag, checked: false }));
        setTags(newTags);

        axios.get("/list_videos", {
            params: {
                tags: [],
            }
        })
            .then(response => {
                setState(prevState => ({ ...prevState, videos: response.data.videos }));
            })
            .catch(error => {
                console.log('Error:', error);
            });
    };

    useEffect(() => {
        axios.get("/list_videos")
            .then(response => {
                setState(prevState => ({ ...prevState, videos: response.data.videos }));
            })
            .catch(error => {
                console.log(error);
            }).finally(()=>setIsLoading(false));
        axios.get("/list_tags")
            .then(response => {
                const fetchedTags = response.data.tags.map((tag: VideoTag) => ({ ...tag, checked: false }));
                setTags(fetchedTags);
            })
            .catch(error => {
                console.log(error);
            });

        getSubscriptionDetails();
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const videoId = searchParams.get('id');
        if (videoId) {
            const videoToPlay = state.videos.find(video => video.id.toString() === videoId);
            if (videoToPlay) {
                handleSelectVideo(videoToPlay);
            }
        }
    }, [location, state.videos]);

    const getSubscriptionDetails = async () => {
        try {
            const response = await axios.get("/user_has_active_subscription_request");
            if (response.data.has_active_subscription === true) {
                setState(prevState => ({
                    ...prevState,
                    subscriptionStatus: true,
                    expirationDate: response.data.expiry_date,
                    remainingDays: response.data.remaining_days,
                }));
            } else {
                setState(prevState => ({ ...prevState, subscriptionStatus: false }));
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(prevState => ({ ...prevState, searchQuery: event.target.value }));
    };

    const { videos, subscriptionStatus, remainingDays, searchQuery } = state;

    const filteredVideos = videos
        .filter(video => video.name.toLowerCase().includes(searchQuery.toLowerCase()))
        .sort((a, b) => {
            if (!subscriptionStatus) {
                return (a.premium_only ? 1 : 0) - (b.premium_only ? 1 : 0);
            }
            return 0;
        });

    const number_of_free_videos = videos.filter(video => !video.premium_only).length;
    const total_number_of_videos = videos.length;

    const handleSelectVideo = (video: Video) => {

        setState(prevState => ({
            ...prevState,
            videoToPlay: video,
        }))
        setIsPlaying(true)
        setHideSidebar(true)
    }

    useEffect(() => {
        if (state.videoToPlay) {
            setTranscript(processJSONString(state.videoToPlay.transcript))
        }
    }, [state.videoToPlay]);

    const [currentTime, setCurrentTime] = useState(0);

    const handleProgress = useCallback((playedSeconds: number) => {
        setCurrentTime(playedSeconds);
    }, []);

    useEffect(() => {
        // Get the current transcript entry based on the current time
        if (transcript.length > 0) {
            const currentEntry = transcript.find(entry => currentTime >= entry.start && currentTime < entry.end);
            if (currentEntry) {
                if (currentEntry && transcriptRef.current) {
                    const activeElement = document.getElementById(`transcript-entry-${currentEntry.id}`);
                    if (activeElement) {
                        const activeElementElement = activeElement as HTMLElement;
                        transcriptRef.current.scrollTo({
                            top: activeElementElement.offsetTop - transcriptRef.current.offsetTop,
                            behavior: "smooth",
                        });
                    }
                }
            }
        }
    }, [currentTime, transcript]);

    const formatTime = (seconds: number): string => {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
    };

    const hasCheckedTags = tags.filter(tag => tag.checked).length > 0;
    const redirectToPurchasePage = useRedirectToPurchasePage();
    const [showBadgeAndGradient, setShowBadgeAndGradient] = useState(true);
    const transcriptRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
        const handleScroll = () => {
            if (transcriptRef.current) {
                const scrollableHeight = transcriptRef.current.scrollHeight;
                const clientHeight = transcriptRef.current.clientHeight;
                const scrollTop = transcriptRef.current.scrollTop;
                const isBottom = scrollableHeight - clientHeight <= scrollTop + 1;
                setShowBadgeAndGradient(!isBottom);
            }
        };

        const element = transcriptRef.current;
        element?.addEventListener('scroll', handleScroll);
        return () => {
            element?.removeEventListener('scroll', handleScroll);
        };
    }, [transcript]);

    const chapters : {title: string, start: number, end: number}[] = [
        // {
        //     title: "Samir introduces Show, Don't Tell",
        //     start: 0,
        //     end: 10,
        // },
        // {
        //     title: "Samir heyasdsa",
        //     start: 10,
        //     end: 19,
        // }
    ]

    return (
        <div className={"w-full"}>
            {videos.length < 1 && !isLoading?
                <>
                    <div className={"flex flex-col min-w-[692px] mt-6 mx-8"}>
                        <div
                            className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <p className="mt-2 text-contentPrimary text-2xl font-extrabold font-inter-tight">No videos available!</p>
                            <p className="mt-2 text-contentSecondary text-sm font-normal font-inter leading-tight">Please come back later.</p>
                        </div>
                    </div>
                </>
                :
                <>
                    {isPlaying ?
                        state.videoToPlay && (
                            <div className={"px-2"}>
                                <div className={"w-full flex justify-center"}>
                                    <div className={"flex flex-row gap-x-10 w-[1152px] justify-center"}>
                                        <div className={"flex flex-col gap-y-7 py-10"}>
                                            <div><Button plain onClick={() => {setIsPlaying(false); setHideSidebar(false)}}><ArrowLeftIcon
                                                className={"size-4"}/>Return</Button></div>
                                            <div className={"flex flex-col gap-y-3"}>
                                                <p className={"text-2xl text-contentPrimary font-inter font-semibold leading-normal"}>{state.videoToPlay.name}</p>
                                                {state.videoToPlay.tags && (
                                                    <div className="flex flex-row gap-x-2">
                                                        {splitTags(state.videoToPlay.tags).map((tag, index) => (
                                                            <div key={index}
                                                                 className="py-1 px-1.5 bg-backgroundLime text-contentLime text-xs font-medium font-inter leading-none text-center flex items-center rounded-md">
                                                                {tag}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                            <div className={"overflow-clip rounded-lg w-[720px]"}>
                                                <VideoPlayer video={state.videoToPlay} playing={true} muted={false}
                                                             controls={true} onProgress={handleProgress}/>
                                            </div>
                                            {chapters.length > 0 ?
                                                <div className={'flex flex-col gap-y-3'}>
                                                    <div className={"flex flex-row gap-x-2"}>
                                                        {chapters.map((chapter, index) =>
                                                            <div key={index}
                                                                 className={`h-1 w-full rounded-full ${currentTime >= chapter.start && currentTime < chapter.end ? 'bg-accent' : 'bg-borderOpaque'}`}>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div
                                                        className={'font-inter text-3xs font-semibold w-full flex justify-center items-center'}>
                                                        {(() => {
                                                            const currentChapter = chapters.find(chapter => currentTime >= chapter.start && currentTime < chapter.end);
                                                            return currentChapter ? currentChapter.title : "No chapter detail found";
                                                        })()}
                                                    </div>
                                                </div>
                                                :
                                                <div className={'flex flex-col gap-y-3'}>
                                                    <div className={"flex flex-row gap-x-2"}>
                                                        {Array.from({length: 5}, (_, key) =>
                                                            <div key={key}
                                                                 className={`h-1 w-full rounded-full bg-borderOpaque`}>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div
                                                        className={'font-inter text-3xs text-contentSecondary font-semibold w-full flex justify-center items-center'}>
                                                        We&apos;re working on generating the chapters for this video.
                                                    </div>
                                                </div>
                                            }
                                            <div
                                                className={"flex flex-col gap-y-3.5 font-inter text-contentPrimary w-6/10"}>
                                                <p className={"text-lg font-semibold leading-[18px] tracking-tight"}>Summary</p>
                                                <p className={"text-sm font-normal tracking-tight"}>{state.videoToPlay.description ? state.videoToPlay.description : "We're working on generating the summary for this video."}</p>
                                            </div>
                                        </div>
                                        <div
                                            className="flex flex-col gap-y-2.5 relative min-w-[380px]">
                                            <div className={"sticky max-h-[calc(100vh-100px)] py-16 top-0"}>
                                                {transcript.length > 0 ?
                                                    <>
                                                        <p className="text-contentPrimary text-sm font-semibold mt-2 mb-2.5">Transcript</p>
                                                        <div className="relative h-full overflow-hidden">
                                                            <ul ref={transcriptRef} className="max-h-full overflow-y-scroll gap-2.5 flex flex-col">
                                                                {transcript.map(entry => (
                                                                    <li
                                                                        id={`transcript-entry-${entry.id}`}
                                                                        key={entry.id}
                                                                        data-start={entry.start}
                                                                        className="p-2 flex flex-row gap-x-2.5 text-xs font-normal font-inter"
                                                                    >
                                                                        <p className={`${currentTime >= entry.start && currentTime < entry.end ? 'text-accent' : 'text-contentTertiary'}`}>{formatTime(entry.start)}</p>
                                                                        <p className={`${currentTime >= entry.start && currentTime < entry.end ? 'text-contentPrimary' : 'text-contentSecondary'}`}>{entry.text}</p>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                            <div
                                                                className={`absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-white to-transparent ${showBadgeAndGradient ? '' : 'hidden'} pointer-events-none`}></div>
                                                            {showBadgeAndGradient && (
                                                                <div
                                                                    className="absolute bottom-0 left-1/2 transform -translate-x-1/2 z-30">
                                                                    <Badge>
                                                                        Scroll for more
                                                                    </Badge>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <li className="p-2 flex flex-row gap-x-2.5 text-xs font-normal font-inter">
                                                            <p className={'text-contentTertiary'}>0:00</p>
                                                            <p className={'text-contentSecondary'}>We&apos;re working on generating the transcript for this video.</p>
                                                        </li>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        :
                        <div className="w-full flex flex-col">
                            <div className="grid grid-cols-2 px-8 pt-6 pb-2 items-center">
                                <div className={'flex flex-row gap-x-6 items-center'}>
                                    <div className="flex justify-start relative h-10">
                                        <div
                                            className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                            <img alt="search" src={`${searchIcon}`} className="h-6 w-6"/>
                                        </div>
                                        <input
                                            type="search"
                                            id="default-search"
                                            className="w-[424px] block ps-10 text-sm text-gray-900 border-stone-200 border-2 rounded-lg bg-backgroundPrimary focus:border-borderSelected focus:ring-borderSelected"
                                            placeholder="Search"
                                            required
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                    </div>
                                    <Popover className="relative">
                                        {({ open }) => (
                                            <>
                                                <Popover.Button className={"focus:outline-none data-[focus]:outline-none data-[focus]:outline-0"} >
                                                    <Button plain>
                                                        <FunnelIcon className={"size-4"}/>
                                                    </Button>
                                                </Popover.Button>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-200"
                                                    enterFrom="opacity-0 translate-y-1"
                                                    enterTo="opacity-100 translate-y-0"
                                                    leave="transition ease-in duration-150"
                                                    leaveFrom="opacity-100 translate-y-0"
                                                    leaveTo="opacity-0 translate-y-1"
                                                >
                                                    <Popover.Panel className="absolute left-0 mt-3 w-[355px] max-w-sm z-50 px-4 sm:px-0 lg:max-w-3xl">
                                                        <div className="overflow-hidden h-full p-[5px] flex items-center w-[204px] min-h-10 shadow border border-slate-100 rounded-md bg-backgroundPrimary">
                                                            <div
                                                                className="flex flex-col font-inter w-full gap-y-1">
                                                                <div
                                                                    className={"pl-8 py-1.5 text-contentPrimary font-medium leading-tight text-sm mb-1"}>
                                                                    Filter Options
                                                                </div>
                                                                {tags.map((option) => (
                                                                    <div key={option.id}
                                                                         className={`w-full rounded-md flex flex-row gap-x-2 items-center pl-8 py-1.5 justify-start cursor-pointer select-none ${option.checked && 'bg-slate-100'}`}
                                                                         onClick={() => handleTagSelection(option.id)}>
                                                                        <CheckIcon
                                                                            className={`size-4 ${!option.checked && 'invisible'}`}/>
                                                                        <p className={"leading-none text-sm text-slate-700 font-medium"}>{option.name}</p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </Popover.Panel>
                                                </Transition>
                                            </>
                                        )}
                                    </Popover>
                                </div>
                                <div className="flex font-inter text-sm font-normal justify-end">
                                    {subscriptionStatus ? (
                                        <div className="flex flex-row items-center">
                                            <div className="xs:invisible xl:visible flex flex-row">
                                                <p>Your {companyConfig.company_name} subscription ends in {remainingDays} days</p>
                                                <Divider type="vertical" className="mx-2.5 h-5"/>
                                            </div>
                                            <div onClick={redirectToPurchasePage}>
                                                <p className="text-accent font-bold">Billing</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="flex flex-row items-center">
                                            <div className="xs:invisible xl:visible flex flex-row">
                                                <p>You have access to {number_of_free_videos} out
                                                    of {total_number_of_videos} videos.</p>
                                                <Divider type="vertical" className="mx-2.5 h-5"/>
                                            </div>
                                            <div onClick={redirectToPurchasePage}>
                                                <p className="text-accent font-bold cursor-pointer">Give me more</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={"relative w-full"}>
                                <div
                                    className={`absolute transition ease-in-out ${hasCheckedTags ? "translate-y-2" : "-translate-y-4"}  duration-600 w-full flex flex-col justify-center`}>
                                    <div
                                        className={`flex flex-row gap-x-2.5 w-full px-8 py-2 transition ease-in-out duration-600 ${!hasCheckedTags ? 'opacity-0' : 'opacity-100'}`}>
                                        {tags.filter((tag) => tag.checked).map((tag) => (
                                            <div key={tag.id}>
                                                <div
                                                    className="px-2 py-1 bg-white rounded-lg shadow border border-zinc-200 justify-center items-center gap-2 inline-flex cursor-pointer">
                                                    <div
                                                        className="text-black text-xs font-semibold font-inter leading-none flex flex-row gap-2 items-center"
                                                        onClick={() => handleTagRemoval(tag.id)}>
                                                        {tag.name}
                                                        <XMarkIcon className={"size-4"}/>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div
                                            className={"px-2 rounded-lg flex items-center text-xs font-semibold font-inter cursor-pointer"}
                                            onClick={() => handleRemoveAllTags()}>Reset all
                                        </div>
                                    </div>
                                    <div className="flex justify-center">
                                        <motion.div
                                            className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 px-2.5 py-4 gap-[30px] max-w-screen-xl">
                                            <AnimatePresence>
                                                {filteredVideos.map((video) => (
                                                    <motion.div
                                                        key={video.id}
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 1 }}
                                                        exit={{ opacity: 0 }}
                                                        transition={{ duration: 0.2 }}
                                                    >
                                                        <VideoSelectionCard
                                                            isLocked={video.premium_only && !subscriptionStatus}
                                                            video={video}
                                                            onClick={() => {
                                                                handleSelectVideo(video)
                                                            }}
                                                        />
                                                    </motion.div>
                                                ))}
                                            </AnimatePresence>
                                        </motion.div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    );
};

export default connector(ContentPortal);
