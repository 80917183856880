import { Button } from "../primitives/button";
import { CheckBadgeIcon } from "@heroicons/react/16/solid";
import React from "react";

interface FreeProductCardProps {
    onClick: () => void;
}

const FreeProductCard: React.FC<FreeProductCardProps> = (props) => {
    const features = [
        'In-person classes',
        'Homework help',
        'All-subject support',
    ];

    return (
        <div className="w-[285px] pt-10 pb-5 h-[508px] bg-backgroundPrimary text-contentPrimary border-[0.5px] border-greyHatchery rounded-lg">
            <div className="border-b-[0.5px] border-greyHatchery">
                <div className="flex flex-col justify-start gap-y-10 pb-7 px-6">
                    <div className="flex flex-col gap-y-3">
                        <p className="font-ppe-editorial-new-light font-normal text-[40px] leading-10">Classes</p>
                        <p className="text-xs">Small group classes with personalised guidance.</p>
                    </div>
                    <p className="font-inter-tight text-[40px] font-medium leading-none">$77 <span className={'font-medium text-base text-contentTertiary'}>per class</span></p>
                    <div>
                        <Button onClick={props.onClick} className="cursor-pointer">Go to courses</Button>
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-start px-6 mt-5">
                <div className="flex flex-col gap-y-2">
                    <p className={"font-inter text-xs font-bold"}>Everything in Online, and</p>
                    {features.map((feature, index) => (
                        <div className="flex flex-row gap-x-2 items-center" key={index}>
                            <CheckBadgeIcon className="size-4 text-primaryA"/>
                            <p className="font-inter font-normal text-xs">{feature}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default FreeProductCard;
