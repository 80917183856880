import React from "react";
import requireAuth from "./utils/RequireAuth";

const ProtectedRouteWrapper = ({ component: Component}) => {

    const AuthenticatedComponent = requireAuth(Component);

    return <AuthenticatedComponent />;
};

export default ProtectedRouteWrapper;
