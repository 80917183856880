import React, {useRef, useState} from "react";
import ProductCheckoutCard from "./ProductCheckoutCard";
import { WrappedCheckoutForm } from "../../membership/CheckoutForm";
import { Button } from "../../primitives/button";
import { XMarkIcon } from "@heroicons/react/16/solid";
import {CompanyConfigContext} from "../../../CompanyConfigContextProvider";

const CheckoutPage = ({ selectedProduct, backToStart }) => {
    const [enabledCoupon, setEnabledCoupon] = useState(false);
    const [percentDiscount, setPercentDiscount] = useState(0);
    const companyConfig = React.useContext(CompanyConfigContext);
    const childRef = useRef<{ handleSubmit: () => void }>(null);

    const handleClick = () => {
        if (childRef.current) {
            childRef.current.handleSubmit();
        }
    };

    const displayCheckoutForm = () => {
        // Call the selectProduct function from props
        // selectProduct(selectedProduct);
    };

    const enableCoupon = () => {
        setEnabledCoupon(true);
    };

    return (
        <div className={'bg-hatcheryGrey'}>
            <div className={"w-full flex items-center flex-col"}>
                <div className={"w-full grid px-8 py-6 grid-cols-2 items-center"}>
                    <a href="/dashboard">
                        <div className={"size-12 flex items-center"}>
                            <img className={"size-fit"}
                                 src={companyConfig.company_logo_navbar}
                                 alt="Company Logo"/>
                        </div>
                    </a>
                    <div className={"justify-end flex"}>
                        <Button plain onClick={() => window.history.back()} className={"cursor-pointer"}>
                            <XMarkIcon/>
                        </Button>
                    </div>
                </div>
                <div className={"pb-20 px-2.5 pt-2.5 flex flex-col items-center"}>
                    <div className={"flex flex-col gap-y-4 text-contentPrimary text-center mb-12"}>
                        <p className={"font-light font-ppe-editorial-new-light text-[40px]"}>Payment</p>
                    </div>
                    <div className={"flex flex-row gap-x-7 justify-center mb-12"}>
                        <div className={"flex min-w-[300px]"}>
                            <ProductCheckoutCard
                                percentDiscount={percentDiscount}
                                checkout={true}
                                selectedProduct={selectedProduct}
                                onClick={displayCheckoutForm}
                                enableCoupon={enableCoupon}
                            />
                        </div>
                        <div className={"flex min-w-[396px] h-full"}>
                            <WrappedCheckoutForm
                                price_id={selectedProduct.priceID}
                                showCouponInput={enabledCoupon}
                                setPercentDiscount={setPercentDiscount}
                                ref={childRef}
                            />
                        </div>
                    </div>
                    {/*<div className={"w-[636px]"}>*/}
                    {/*    <EarlybirdBanner />*/}
                    {/*</div>*/}
                </div>
            </div>
            <div
                className={"w-full grid-cols-2 grid bottom-0 bg-backgroundPrimary border-t border-borderOpaque px-40 py-3.5 fixed"}>
                <div className={"flex justify-start"}>
                    <Button onClick={backToStart} outline className={"cursor-pointer"}>
                        Back
                    </Button>
                </div>
                <div className={"flex justify-end"}>
                    <Button onClick={handleClick} className={"cursor-pointer"}>
                        Pay
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CheckoutPage;
