import { Button } from "../primitives/button";
import { CheckBadgeIcon } from "@heroicons/react/16/solid";
import React from "react";
import {CompanyConfigContext} from "../../CompanyConfigContextProvider";

interface FreeProductCardProps {
    onClick: () => void;
}

const FreeProductCard: React.FC<FreeProductCardProps> = (props) => {
    const companyConfig = React.useContext(CompanyConfigContext);
    const features = [
        'Capped practice questions',
        'Unlocked videos',
        'Analytics dashboard with rankings',
        'Free exams',
    ];

    return (
        <div className="w-[285px] pt-10 pb-5 h-[508px] bg-backgroundPrimary text-contentPrimary border-[0.5px] border-greyHatchery rounded-lg">
            <div className="border-b-[0.5px] border-greyHatchery">
                <div className="flex flex-col justify-start gap-y-10 pb-7 px-6">
                    <div className="flex flex-col gap-y-3">
                        <p className="font-ppe-editorial-new-light font-normal text-[40px] leading-10">Free</p>
                        <p className="text-xs">Add a sprinkle of {companyConfig.company_name} to your study regimen.</p>
                    </div>
                    <p className="font-inter-tight text-[40px] font-medium leading-none">$0</p>
                    <div>
                        <Button outline onClick={props.onClick} className="cursor-pointer">Keep Free</Button>
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-start px-6 mt-5">
                <div className="flex flex-col gap-y-2">
                    {features.map((feature, index) => (
                        <div className="flex flex-row gap-x-2 items-center" key={index}>
                            <CheckBadgeIcon className="h-4 w-4" />
                            <p className="font-inter font-normal text-xs">{feature}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default FreeProductCard;
