import React, { FC, useContext, memo, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { CompanyConfigContext } from '../CompanyConfigContextProvider';
import {NavbarContext, useSidebarVisibility} from "../NavbarProvider";

interface NavbarContentWrapperProps {
    content: ReactNode;
}

const NavbarContentWrapperBase: FC<NavbarContentWrapperProps> = ({ content }) => {
    return <>{content || <div className="w-full h-full bg-backgroundPrimary" />}</>;
};
const NavbarContentWrapper = memo(NavbarContentWrapperBase);
NavbarContentWrapper.displayName = 'NavbarContentWrapper';

interface NavigationBarProps {
    companyLogo?: string;
}

const NavigationBarBase: FC<NavigationBarProps> = ({ companyLogo }) => {
    const context = useContext(NavbarContext);
    const { navbarContent } = context;

    return (
        <nav className="h-16 sticky top-0 z-19 bg-white border-b border-borderOpaque flex flex-row items-center px-4 py-3">
            <a href="/dashboard" className="ml-0 mr-[10vw]">
                <div className="size-7 flex items-center justify-center">
                    {companyLogo && (
                        <img
                            className="size-fit"
                            src={companyLogo}
                            alt="Company Logo"
                            loading="eager"
                        />
                    )}
                </div>
            </a>
            <div className="flex-grow">
                <NavbarContentWrapper content={navbarContent} />
            </div>
        </nav>
    );
};
const NavigationBar = memo(NavigationBarBase);
NavigationBar.displayName = 'NavigationBar';

interface SidebarWrapperProps {
    Sidebar: FC;
}

const SidebarWrapperBase: FC<SidebarWrapperProps> = ({ Sidebar }) => {
    return (
        <div className="flex sticky overflow-y-auto min-w-[256px] top-0 h-full">
            <Sidebar />
        </div>
    );
};
const SidebarWrapper = memo(SidebarWrapperBase);
SidebarWrapper.displayName = 'SidebarWrapper';

interface NavbarLayoutProps {
    Sidebar?: FC;
    className?: string;
}

const NavbarLayoutBase: FC<NavbarLayoutProps> = ({
                                                     Sidebar,
                                                     className = "flex flex-col h-full"
                                                 }) => {
    const { hideSidebar } = useSidebarVisibility();
    const companyConfig = useContext(CompanyConfigContext);

    return (
        <div className={className}>
            <NavigationBar companyLogo={companyConfig.company_logo_navbar} />

            <div className="flex flex-row max-w-full h-[calc(100vh-65px)] relative overflow-auto">
                {!hideSidebar && Sidebar && <SidebarWrapper Sidebar={Sidebar} />}

                <div
                    className={`
                        flex-grow flex-col overflow-y-auto absolute h-full
                        ${!hideSidebar && Sidebar ? 'left-[257px] min-w-[calc(100vw-257px)]' : 'w-full'}
                    `}
                >
                    <Outlet />
                </div>
            </div>
        </div>
    );
};
export const NavbarLayout = memo(NavbarLayoutBase);
NavbarLayout.displayName = 'NavbarLayout';