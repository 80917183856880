import React, { useState } from 'react'
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { ChevronDown, ChevronUp, Clock, CheckCircle, XCircle, BookOpen } from 'lucide-react';
import {Button} from "../primitives/button";
import {useNavigate} from "react-router-dom";

const LearningSessionHistory = () => {
    const [expandedSession, setExpandedSession] = useState(null);
    const navigate = useNavigate();

    const { data, isLoading, error } = useQuery({
        queryKey: ['history'],
        queryFn: async () => {
            const { data } = await axios.get("/get_non_exam_learning_sessions");
            console.log(data);
            return data;
        }
    });

    if (isLoading) return (
        <div className="flex items-center justify-center h-64">
            <div className="text-lg text-gray-600">Loading...</div>
        </div>
    );

    if (error) return (
        <div className="flex items-center justify-center h-64">
            <div className="text-lg text-red-600">Error fetching learning session history</div>
        </div>
    );

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    return (
        <div className="flex flex-col space-y-4 p-4 max-w-4xl mx-auto">
            <Button color={'primary'} onClick={()=>navigate(-1)}>Go back</Button>
            {data.sessions.map((session) => (
                <div key={session.id} className="bg-white rounded-lg shadow-md overflow-hidden">
                    <div className="p-4 cursor-pointer hover:bg-gray-50"
                         onClick={() => setExpandedSession(expandedSession === session.id ? null : session.id)}>
                        <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-4">
                                <div className="flex flex-col">
                                    <span className="font-semibold">
                                        Session {session.id}
                                    </span>
                                    <span className="text-sm text-gray-500">
                                        {formatDate(session.start_time)}
                                    </span>
                                </div>
                            </div>

                            <div className="flex items-center space-x-6">
                                <div className="flex space-x-4">
                                    <div className="flex flex-col items-center">
                                        <span className="text-sm text-gray-500">Accuracy</span>
                                        <span className="font-semibold">{session.accuracy}%</span>
                                    </div>
                                    <div className="flex flex-col items-center">
                                        <span className="text-sm text-gray-500">Completed</span>
                                        <span className="font-semibold">{session.completion_rate}%</span>
                                    </div>
                                    <div className="flex flex-col items-center">
                                        <span className="text-sm text-gray-500">Time</span>
                                        <span className="font-semibold flex items-center">
                                            <Clock className="w-4 h-4 mr-1" />
                                            {session.duration_minutes || '-'} min
                                        </span>
                                    </div>
                                </div>

                                {expandedSession === session.id ?
                                    <ChevronUp className="w-5 h-5 text-gray-500" /> :
                                    <ChevronDown className="w-5 h-5 text-gray-500" />
                                }
                            </div>
                        </div>
                    </div>

                    {expandedSession === session.id && (
                        <div className="border-t border-gray-200 p-4">
                            <div className="space-y-3">
                                {session.questions.map((question) => (
                                    <div key={question.id}
                                         className="flex flex-col p-3 rounded-lg bg-gray-50">
                                        <div className="flex items-start">
                                            <div className="mr-3 mt-1">
                                                {question.correct ?
                                                    <CheckCircle className="w-5 h-5 text-green-500" /> :
                                                    question.answered ?
                                                        <XCircle className="w-5 h-5 text-red-500" /> :
                                                        <div className="w-5 h-5 rounded-full border-2 border-gray-300" />
                                                }
                                            </div>
                                            <div className="flex-1">
                                                <p className="text-sm mb-1" dangerouslySetInnerHTML={{__html: question.question.question_text}}/>
                                                {question.answered && (
                                                    <div className={'flex flex-row text-xs text-contentTertiary justify-between gap-2'}>
                                                        <div className="flex flex-col">
                                                            <p>Selected:&nbsp;<span
                                                                dangerouslySetInnerHTML={{__html: question.answer}}/>
                                                            </p>
                                                            <p>Correct:&nbsp;<span
                                                                dangerouslySetInnerHTML={{__html: question.question.correct_answer}}/>
                                                            </p>

                                                        </div>
                                                        <p>Time:&nbsp;{Math.round(question.time_taken / 1000)}s</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {question.question.solution && (
                                            <div className="mt-3 ml-8 p-3 bg-blue-50 rounded-md border border-blue-100">
                                                <div className="flex items-center mb-1 text-blue-700">
                                                    <BookOpen className="w-4 h-4 mr-1"/>
                                                    <span className="text-sm font-medium">Solution</span>
                                                </div>
                                                <p className="text-sm text-blue-800"
                                                   dangerouslySetInnerHTML={{__html: question.question.solution}}/>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default LearningSessionHistory;