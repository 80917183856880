import React from "react";
import { CompanyConfigContext } from "../CompanyConfigContextProvider";

interface PopulatableNavbarProps {
    navbarContent: React.ReactNode;
}

const PopulatableNavbar: React.FC<PopulatableNavbarProps> = ({ navbarContent }) => {
    const companyConfig = React.useContext(CompanyConfigContext);

    return (
        <nav className="h-16 sticky top-0 z-19 bg-white border-b border-borderOpaque flex flex-row items-center px-4 py-3">
            <a href="/dashboard" className="ml-0 mr-[10vw]">
                <div className="size-7 flex items-center justify-center">
                    {companyConfig.company_logo_navbar && (
                        <img
                            className="size-fit"
                            src={companyConfig.company_logo_navbar}
                            alt="Company Logo"
                            loading="eager"
                        />
                    )}
                </div>
            </a>
            <div className="flex-grow">
                {navbarContent || <div className="w-full h-full bg-backgroundPrimary"></div>}
            </div>
        </nav>
    );
};

export default React.memo(PopulatableNavbar);