import React, {useEffect, useState} from "react";
import {ErrorBoundary} from "react-error-boundary";
import {ParentSize} from "@visx/responsive";
import errorScreen from "./ErrorScreen";
import SpeedVsAccuracyResults from "./SpeedVsAccuracyGraph";


interface SpeedVsAccuracyProps {
    sectionResults: Record<string, SectionResults>;
    examResults: ExamResultsType;
}

export function SpeedVsAccuracy(props: SpeedVsAccuracyProps) {
    const [isValidData, setIsValidData] = useState(true)
    const [isMoreAccurate, setIsMoreAccurate] = useState(true)
    const [isFaster, setIsFaster] = useState(true)
    const [worstSection, setWorstSection] = useState("")

    useEffect(() => {
        function relativeExamSpeed(sectionResults: Record<string, SectionResults>) {
            const userSectionDurations: number[] = [];
            const allSectionDurations: number[] = [];
            const userOverallPercentage: number[] = [];
            const cohortOverallPercentage: number[] = [];

            const tempWorstSection = 101
            Object.keys(sectionResults).forEach((sectionName) => {
                const section = sectionResults[sectionName];
                cohortOverallPercentage.push(section.result_percentage_other_users)
                userOverallPercentage.push(section.result_percentage)
                userSectionDurations.push(section.total_time);
                if (tempWorstSection > section.result_percentage) {
                    setWorstSection(section.worst_performing_subtopic)
                }
                if (section.time_spent_all_users.filter(duration => duration !== null).length > 3) {
                    section.time_spent_all_users.forEach((duration) => {
                        if (duration !== null) {
                            allSectionDurations.push(duration);
                        }
                    });
                } else {
                    setIsValidData(false);
                }
            });

            const meanAllDurations = allSectionDurations.reduce((acc, value) => acc + value, 0) / allSectionDurations.length;
            const meanUserDurations = userSectionDurations.reduce((acc, value) => acc + value, 0) / userSectionDurations.length;
            const cohortPercentageMean = cohortOverallPercentage.reduce((acc, value) => acc + value, 0) / cohortOverallPercentage.length;
            const userPercentageMean = userOverallPercentage.reduce((acc, value) => acc + value, 0) / userOverallPercentage.length;
            setIsFaster(meanAllDurations > meanUserDurations);
            setIsMoreAccurate(cohortPercentageMean < userPercentageMean)
        }

        relativeExamSpeed(props.sectionResults);
    }, [props.sectionResults]);

    return (
        <div className={"flex flex-wrap gap-3 max-w-[900px] w-full h-full"}>
            <div
                className={"border-borderInverseOpaque border rounded-lg flex flex-col w-full"}>
                <div
                    className={"border-borderInverseOpaque bg-sky-200 border-b text-contentPrimary rounded-t-lg px-6 text-lg font-sf-pro leading-[28px] py-1 text-left"}>
                    {isValidData ? (
                        <p>
                            You were&nbsp;
                            <b>{isFaster ? "faster" : "slower"} </b>
                            than the majority of your peers&nbsp;
                            {isMoreAccurate ? <span>and <b>more accurate.</b></span> : <span>and <b>less accurate.</b></span>}
                            &nbsp;
                            {(isFaster && isMoreAccurate) && "Great Work."}
                            {(isFaster && !isMoreAccurate) && "Consider slowing down."}
                            {(!isFaster && isMoreAccurate) && "Speed up while maintaining your accuracy."}
                            {(!isFaster && !isMoreAccurate) && <span>Consider revising <b>{worstSection}</b>.</span>}
                        </p>
                    ) : (
                        <p className="text-left">
                            Not enough data cohort data yet, come back later.
                        </p>
                    )}
                </div>
                <div className={"bg-[#E7F9FF] h-[400px] rounded-b-lg"}>
                    {isValidData ?
                        <ErrorBoundary fallback={errorScreen()}>
                            <ParentSize>{({width, height}) => <SpeedVsAccuracyResults width={width}
                                                                                      height={height}
                                                                                      examResults={props.examResults}
                                                                                      sectionResults={props.sectionResults}
                            />}</ParentSize>
                        </ErrorBoundary>
                        :
                        <p className={"p-2 text-center"}>Waiting for more students to take this exam before we can show you full results. Please check back later
                            through your exam history.</p>
                    }
                </div>
            </div>
        </div>
    );
}
