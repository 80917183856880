import React, {useState, useCallback, useMemo, useEffect} from "react";
import {AreaClosed, Bar} from '@visx/shape';
import { LinearGradient } from '@visx/gradient';
import { Group } from '@visx/group';
import { scaleLinear } from "@visx/scale";
import { localPoint } from '@visx/event';
import { withTooltip, TooltipWithBounds } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { ToggleTag } from "../primitives/ToggleTag";
import {bisector} from "d3-array";
import {curveBasis} from '@visx/curve';
import {CursorArrowRaysIcon} from "@heroicons/react/16/solid";

export type AreaProps = {
    examResults: ExamResultsType;
    sectionResults: Record<string, SectionResults>;
    width: number;
    height: number;
    margin?: { top: number; right: number; bottom: number; left: number };
};

interface TooltipData {
    score: number;
    distribution: number;
    percentile: number;
}

const getScore = (d: TooltipData) => d.score;
const getDistribution = (d: TooltipData) => d.distribution;
const getPercentile = (d: TooltipData) => d.percentile;
const bisectData = bisector<TooltipData, number>((d) => (d.score)).left;

export default withTooltip<AreaProps, TooltipData>(
    ({
         examResults,
         sectionResults,
         width,
         height,
         margin = { top: 70, right: 0, bottom: 40, left: 0 },
         showTooltip,
         hideTooltip,
         tooltipData,
         tooltipTop = 0,
         tooltipLeft = 0,
     }: AreaProps & WithTooltipProvidedProps<TooltipData>) => {
        if (width < 10) return null;

        const sections = Object.keys(sectionResults);
        // const [examResultPercentile, setExamResultPercentile] = useState(examResults.percentile)
        const [examResultPercentage, setExamResultPercentage] = useState(examResults.percentage)
        const allSections = {bell_curve:{bell_curve_labels:examResults.bell_curve_labels, bell_curve_points:examResults.bell_curve_points, global_id_and_percentages: examResults.global_id_and_percentages}}
        const [activeAbbreviation, setAbbreviation] = useState<string>('All');
        const [activeGraph, setActiveGraph] = useState(allSections)
        const { bell_curve_labels, bell_curve_points} = activeGraph.bell_curve;
        const adjustedLabels = useMemo(
            () => (bell_curve_labels ? bell_curve_labels.map(label => Math.round(label * 100)) : []),
            [bell_curve_labels, activeGraph]
        );

        const [availablePercentages, setAvailablePercentages] = useState([])

        const adjustedPoints = useMemo(
            () => (bell_curve_points || []),
            [bell_curve_points, activeGraph]
        );

        const combinedData = useMemo(() => {
            const totalDistribution = adjustedPoints.reduce((acc, curr) => acc + curr, 0);
            return adjustedLabels.map((label, index) => ({
                score: label,
                distribution: adjustedPoints[index] / totalDistribution, // Normalize distribution values
                percentile: Math.round(adjustedPoints.slice(0, index + 1).reduce((acc, curr) => acc + (curr / totalDistribution), 0) * 100), // Correct percentile calculation
            }));
        }, [adjustedLabels, adjustedPoints]);

        const [examResultPercentile, setExamResultPercentile] = useState<number>(0); // Initialize examResultPercentile with 0

        useEffect(() => {
            setAvailablePercentages(Object.keys(activeGraph.bell_curve.global_id_and_percentages));
            const examResultScore = examResultPercentage * 100; // Convert examResultPercentage to score
            const index = bisectData(combinedData, examResultScore, 1);
            const d0 = combinedData[index - 1];
            const d1 = combinedData[index];
            let d = d0;
            if (d1 && getScore(d1)) {
                d = examResultScore - getScore(d0) > getScore(d1) - examResultScore ? d1 : d0;
            }
            setExamResultPercentile(100 - getPercentile(d));
        }, [examResultPercentage, combinedData]);

        const xMin = -10;
        const xMax = width - margin.left - margin.right;
        const yMax = height - margin.top - margin.bottom;

        const scoreScale = useMemo(
            () =>
                scaleLinear({
                    domain: [adjustedLabels[0], adjustedLabels[adjustedLabels.length - 1]],
                    range: [xMin, xMax+10],
                }),
            [xMax, margin.left, activeGraph],
        );

        const percentileScale = useMemo(
            () => {
                const totalDistribution = adjustedPoints.reduce((acc, curr) => acc + curr, 0);
                return scaleLinear({
                    domain: [0, Math.max(...adjustedPoints.map(point => point / totalDistribution))], // Set domain from 0 to the max value of normalized points
                    range: [yMax, 0],
                });
            },
            [margin.top, yMax, adjustedPoints]
        );

        const handleActiveGraph = (abv: string)=> {
            if (abv ==="All"){
                setActiveGraph(allSections)
                setExamResultPercentage(examResults.percentage)
                setExamResultPercentile(examResults.percentile)
            } else {
                setActiveGraph(sectionResults[abv])
                setExamResultPercentage(sectionResults[abv].result_percentage)
                setExamResultPercentile((Math.round(100*sectionResults[abv].bell_curve.percentile)))
            }
            setAbbreviation(abv)
        }

        const handleTooltip = useCallback(
            (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
                const { x } = localPoint(event) || { x: 0 };
                const x0 = scoreScale.invert(x);
                const index = bisectData(combinedData, x0, 1);
                const d0 = combinedData[index - 1];
                const d1 = combinedData[index];
                let d = d0;
                if (d1 && getScore(d1)) {
                    d = x0.valueOf() - getScore(d0).valueOf() > getScore(d1).valueOf() - x0.valueOf() ? d1 : d0;
                }
                showTooltip({
                    tooltipData: d,
                    tooltipLeft: x,
                    tooltipTop: percentileScale(getDistribution(d)),
                });
            },
            [showTooltip, percentileScale, scoreScale],
        );

        const abbreviations = ['All', ...sections];

        const getAbbreviation = (section : string) => !sections.includes(section) ? section : sectionResults[section].section_abbreviation || section;

        const tooltipWidth = 220;
        const tooltipHeight = 80;
        const tooltipX = scoreScale(examResultPercentage * 100) - tooltipWidth / 2;
        const adjustedTooltipX = Math.max(margin.left, Math.min(tooltipX, width - margin.right - tooltipWidth));
        const tooltipY = -margin.top + 70;

        const handleContextMenu = (event: React.MouseEvent<SVGElement>) => {
            event.preventDefault();
            if (tooltipData) {
                const currentScore = getScore(tooltipData)/100;
                const closestPercentage = availablePercentages.reduce((prev, curr) => {
                    return (Math.abs(parseFloat(curr) - currentScore) < Math.abs(parseFloat(prev) - currentScore) ? curr : prev);
                });
                window.location.href = `/exam_results?id=${activeGraph.bell_curve.global_id_and_percentages[closestPercentage][1]}`
            }
        };

        function getOrdinalSuffix(number) {
            const suffixes = ["th", "st", "nd", "rd"];
            const lastDigit = number % 10;
            const lastTwoDigits = number % 100;

            if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
                return "th";
            }

            return suffixes[lastDigit] || "th";
        }

        return (
            <div className="flex items-center">
                <svg width={width} height={height} onContextMenu={handleContextMenu}>
                    <LinearGradient id="bellCurveGradient" from="#7353BA40" to="#7353BA00" />
                    <Group top={margin.top} left={margin.left}>
                        <AreaClosed
                            data={combinedData}
                            x={(d) => scoreScale(getScore(d)) ?? 0}
                            y={(d) => percentileScale(getDistribution(d)) ?? 0}
                            y0={yMax + margin.bottom + 12}
                            stroke="#7353BA"
                            strokeWidth={6}
                            fill="url(#bellCurveGradient)"
                            curve={curveBasis}
                            yScale={percentileScale}
                        />
                        <Bar
                            x={0}
                            y={0}
                            width={width}
                            height={height - margin.top}
                            fill="transparent"
                            rx={14}
                            onTouchStart={handleTooltip}
                            onTouchMove={handleTooltip}
                            onMouseMove={handleTooltip}
                            onMouseLeave={() => hideTooltip()}
                        />
                        <foreignObject x={0} y={-margin.top + 10} width={width} height={40}>
                            <div className={"w-full flex justify-center h-full"}>
                                <div className={"flex flex-row gap-x-4 h-full"}>
                                    {abbreviations.map((abv) => (
                                        <div className={"h-full"} key={abv} onClick={() => handleActiveGraph(abv)}>
                                            <ToggleTag active={abv === activeAbbreviation}>{getAbbreviation(abv)}</ToggleTag>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </foreignObject>
                        <text
                            x={scoreScale(combinedData[combinedData.length - 1].score)}
                            y={percentileScale(combinedData[combinedData.length - 1].distribution)}
                            dx={-40}
                            dy={20}
                            fontSize={12}
                            textAnchor="start"
                            fill="#7353BA"
                        >
                            {`${combinedData[combinedData.length - 1].score}%`}
                        </text>
                        <text
                            x={scoreScale(combinedData[0].score)}
                            y={percentileScale(combinedData[0].distribution)}
                            dx={40}
                            dy={20}
                            fontSize={12}
                            textAnchor="end"
                            fill="#7353BA"
                        >
                            {`${combinedData[0].score}%`}
                        </text>
                        <line
                            x1={scoreScale(examResultPercentage*100)}
                            y1={0}
                            x2={scoreScale(examResultPercentage*100)}
                            y2={yMax + margin.bottom + 12}
                            stroke="#292524"
                            strokeWidth={1}
                        />
                        <foreignObject x={adjustedTooltipX} y={tooltipY} width={tooltipWidth} height={tooltipHeight}>
                            <div
                                className={"w-full flex justify-center bg-backgroundPrimary h-full flex-col gap-y-2.5 font-inter rounded-lg border border-contentPrimary items-center"}>
                                <p className={"text-contentPrimary text-lg font-medium"}>Your score: <span
                                    className={"font-bold"}>{(examResultPercentage * 100).toFixed(0)}%</span></p>
                                <p className={"text-contentSecondary font-medium text-xs opacity-45"}>{(examResultPercentile).toFixed(0)}%
                                    of users scored above you</p>
                            </div>
                        </foreignObject>
                    </Group>
                </svg>
                {tooltipData && (
                    <div>
                        <TooltipWithBounds
                            key={Math.random()}
                            top={tooltipTop - 12}
                            left={Math.max(margin.left, Math.min(tooltipLeft, width - margin.right - 140))}
                            className={"bg-backgroundPrimary drop-shadow-sm text-contentPrimary shadow justify-start items-center gap-2.5 inline-flex p-0 rounded-lg"}
                        >
                            <div className={"flex flex-col gap-3 px-2 text-xs py-4 rounded-lg text-center text-contentSecondary max-w-[250px]"}>
                                <p>The <span className={"font-semibold text-contentPrimary"}>{`${getPercentile(tooltipData)}`}{getOrdinalSuffix(getPercentile(tooltipData))} percentile </span>
                                    user scored
                                    <span className={"font-semibold text-contentPrimary"}> {`${(getScore(tooltipData))}`}%</span></p>
                                <div className={"flex flex-row gap-x-2 items-center"}>
                                    <div className={"flex-shrink"}><CursorArrowRaysIcon className={"size-4"}/></div>
                                    <div className={"flex-grow leading-tight"}>Right click to go to a Data Room in this area</div>
                                </div>
                            </div>
                        </TooltipWithBounds>
                        {/*<Tooltip*/}
                        {/*    top={+ margin.top - 14}*/}
                        {/*    left={tooltipLeft}*/}
                        {/*    style={{*/}
                        {/*        ...defaultStyles,*/}
                        {/*        minWidth: 72,*/}
                        {/*        textAlign: 'center',*/}
                        {/*        transform: 'translateX(-50%)',*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    {(getScore(tooltipData))}*/}
                        {/*</Tooltip>*/}
                    </div>
                )}
            </div>
        );
    }
);
