import React from "react";
import {Input} from "../primitives/input";
import {Button} from "../primitives/button";

type RenameModalProps = {
    setOpenRenameModal: (open: boolean) => void,
    handleRenameInstance: () => void,
    setInstanceTitle: (essayTitle: string) => void,
    instanceTitle: string,
}

function RenameModal(props: RenameModalProps) {

    return (
        <div className={"p-6 flex flex-col gap-y-4"}>
            <div className={"text-normal font-inter-tight text-contentPrimary text-xl font-medium leading-5"}>
                Rename Essay?
            </div>
            <div className={"h-10"}>
                <Input
                    placeholder="Essay title"
                    value={props.instanceTitle}
                    onChange={(e) => props.setInstanceTitle(e.target.value)}
                />
            </div>
            <div className={"flex flex-row w-full justify-end gap-x-2"}>
                <Button plain onClick={() => {
                    props.setOpenRenameModal(false)
                }}> Cancel
                </Button>
                <Button
                    color={"secondary"}
                    onClick={() => props.handleRenameInstance()}
                >Rename
                </Button>
            </div>
        </div>
    )

}


export default RenameModal