import React, { useState, useCallback } from "react";
import axios from "axios";
import ExamSelection from "./ExamSelection";
import ExamConfirm from "./ExamConfirm";
import PropTypes from "prop-types";
import {NavbarWithStreakIndicator} from "../PreconfiguredNavbars/NavbarWithStreakIndicator";
import {useNavbarContent} from "../../NavbarProvider";

const ExamStateMachine: React.FC = () => {
    const [examSelected, setExamSelected] = useState<Exam | null>(null);
    const selectExam = useCallback((exam: Exam) => {
        setExamSelected(exam);
    }, []);
    useNavbarContent({
        component: NavbarWithStreakIndicator
    });
    const beginExam = useCallback(async () => {
        if (examSelected) {
            const data = { exam_id: examSelected.id };
            try {
                await axios.post("/begin_exam/", data);
                window.location.href = "/current_session";
            } catch (error) {
                console.log(error);
            }
        }
    }, [examSelected]);

    const currentExamComponent = examSelected
        ? <ExamConfirm exam={examSelected} startExam={beginExam} />
        : <ExamSelection selectExam={selectExam} />;

    return (
        <div className="w-full h-full">
            {currentExamComponent}
        </div>
    );
};

ExamStateMachine.propTypes = {
    auth: PropTypes.object.isRequired
};

export default ExamStateMachine;
