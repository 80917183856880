import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {Button} from "../primitives/button";
import {CompanyConfigContext} from "../../CompanyConfigContextProvider";

function PaymentSuccessful() {
    const companyConfig = React.useContext(CompanyConfigContext);
    const navigate = useNavigate();

    return (
        <div className={"flex items-center h-screen w-screen justify-center bg-hatcheryGrey"}>
            <div className={"flex-col flex items-center gap-y-8"}>
                <p className={"font-ppe-editorial-new-light text-[40px] font-normal"}>Your {companyConfig.company_name} experience has changed.</p>
                <Link to="/dashboard">
                    <Button color={"primary"} onClick={()=>navigate('/dashboard')}>See the difference</Button>
                </Link>
            </div>
        </div>
    );
}
export default PaymentSuccessful;