import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { setAxiosAuthToken } from "../../utils/Utils";
import posthog from "posthog-js";
import { Button } from "../primitives/button";
import { Input } from "../primitives/input";
import PrivacyPolicy from "../fixedelements/PrivacyPolicy";
import { CompanyConfigContext } from "../../CompanyConfigContextProvider";
import { useToast } from "../ui/use-toast";
import AbsoluteLogo from "../fixedelements/AbsoluteLogo";

const Signup: React.FC = () => {
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [usernameError, setUsernameError] = useState<string>("");
    const [passwordError, setPasswordError] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [status, setStatus] = useState<string>("");
    const [refCode, setRefCode] = useState<string | null>(null);
    const [activationSent, setActivationSent] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [step, setStep] = useState<number>(1);
    const companyConfig = useContext(CompanyConfigContext);
    const navigate = useNavigate(); // Use useNavigate instead of useHistory
    const location = useLocation(); // Use useLocation to access query parameters
    const { toast } = useToast();

    useEffect(() => {
        document.title = `Start Free — ${companyConfig.company_name}`;
        verifyRefCode();
        getPrefilledEmail(); // Get prefilled email from query params
        return () => {
            document.title = `${companyConfig.company_name}`;
        };
    }, [companyConfig]);

    const onNextStepClick = () => {
        setStep((prevStep) => prevStep + 1);
    };

    const onPreviousStepClick = () => {
        setStep(1);
    };

    const getRefCode = () => {
        const params = new URLSearchParams(location.search); // Use useLocation's search property
        return params.get("ref_code");
    };

    const getPrefilledEmail = () => {
        const params = new URLSearchParams(location.search);
        setEmail(params.get("email") || "");
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === "username") setUsername(value);
        if (name === "password") setPassword(value);
        if (name === "email") setEmail(value);
    };

    const verifyRefCode = () => {
        const code = getRefCode();
        if (code) {
            axios.post("/verify_referral_code/", { ref_code: code });
        }
    };

    const emitPosthogEvent = (email: string) => {
        posthog.capture("user_signed_up", {
            email: email,
        });
        posthog.identify(email);
    };

    const onSignupClick = async () => {
        if (activationSent) {
            navigate(`/login?email=${email}&activation_sent=true`);
            return;
        }

        setUsernameError("");
        setEmailError("");
        setPasswordError("");
        setStatus("");

        const lowerCaseEmail = email.toLowerCase();
        const userData = {
            username: email,
            password: password,
            email: lowerCaseEmail,
        };

        setAxiosAuthToken(""); // send request with empty token
        try {
            const response = await axios.post("/api/v1/users/", userData);
            emitPosthogEvent(userData.email);
            if (getRefCode()) {
                await axios.post("/attach_referral/", {
                    ref_code: getRefCode(),
                    email: userData.email,
                });
                setStatus("success");
                setActivationSent(true);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                window.fbq("track", "CompleteRegistration");
                navigate(`/login?email=${email}&activation_sent=true`);
            } else {
                setStatus("success");
                setActivationSent(true);
                navigate(`/login?email=${email}&activation_sent=true`);
            }
        } catch (error) {
            if (error.response) {
                if (Object.prototype.hasOwnProperty.call(error.response.data, "email")) {
                    toast({
                        variant: 'destructive',
                        title: "Error",
                        description: "Email already exists or is invalid.",
                    });
                    setEmailError(error.response.data["email"]);
                }
                if (Object.prototype.hasOwnProperty.call(error.response.data, "password")) {
                    toast({
                        variant: 'destructive',
                        title: "Error",
                        description: "Your password must contain at least 8 characters and at least one number.",
                    });
                    setPasswordError(error.response.data["password"]);
                }
                if (Object.prototype.hasOwnProperty.call(error.response.data, "detail")) {
                    console.log(error);
                    setStatus("error");
                }
            } else {
                console.log(error);
                setStatus("error");
            }
        }
    };

    const errorAlert = (
        <div className={"flex flex-col p-3 bg-rose-300 my-4 rounded-[4px]"}>
            <h2>Problem during account creation</h2>
            <p>Please try again or contact service support for further help.</p>
        </div>
    );

    let currentFormFields;
    if (step === 1) {
        currentFormFields = (
            <div className={"h-12 w-[320px]"}>
                <Input
                    type="text"
                    name="email"
                    placeholder="Email address"
                    value={email}
                    onChange={onChange}
                    autoFocus
                    onKeyPress={(event) => {
                        if (event.key === "Enter") onNextStepClick();
                    }}
                />
            </div>
        );
    } else if (step === 2) {
        currentFormFields = (
            <div className={"flex flex-col gap-y-3"}>
                <div className={"h-12 w-[320px] relative"}>
                    <Input
                        type="text"
                        name="email"
                        placeholder="Email address"
                        value={email}
                        readOnly
                        style={{ paddingRight: "50px" }}
                    />
                    <button
                        onClick={onPreviousStepClick}
                        className={"text-accent"}
                        style={{
                            position: "absolute",
                            top: "50%",
                            right: "1rem",
                            transform: "translateY(-50%)",
                            textDecoration: "none",
                        }}
                    >
                        Edit
                    </button>
                </div>
                <div className={"h-12 w-[320px]"}>
                    <Input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={onChange}
                        onKeyPress={(event) => {
                            if (event.key === "Enter") onSignupClick();
                        }}
                    />
                </div>
            </div>
        );
    }

    let alert;
    if (status === "error") {
        alert = errorAlert;
    } else if (status === "success") {
        alert = <div></div>;
    } else if (status === "error_invalid_referral_code") {
        alert = (
            <div>
                <h2>Invalid referral code</h2>
                <p>Please check your referral code and try again.</p>
            </div>
        );
    }

    return (
        <div className="h-full flex flex-col items-center bg-hatcheryGrey">
            <AbsoluteLogo />
            <div className={"flex justify-center items-center align-middle mx-20 h-full max-w-[392px]"}>
                <div className={"flex-col flex gap-y-6 items-center"}>
                    <div className={"flex flex-col gap-y-4 justify-center"}>
                        <div className="text-center text-black text-[40px] font-light font-ppe-editorial-new-light leading-10 px-10">
                            Create your account
                        </div>
                        {emailError ? (
                            <p className="text-sm font-normal leading-[18px] text-stone-400 text-center text-wrap">
                                Today will be an important day when you find a unique email address.
                            </p>
                        ) : (
                            <p className="text-sm font-normal leading-[18px] text-stone-400 text-center">
                                Today is an important day.
                            </p>
                        )}
                    </div>
                    <div className={"flex flex-col items-center"}>
                        {alert}
                        {status !== "success" && currentFormFields}
                    </div>

                    <Button
                        color={"primary"}
                        className={"w-[320px] h-12"}
                        onClick={step === 1 ? onNextStepClick : onSignupClick}
                    >
                        Continue
                    </Button>

                    <div className={"flex flex-row gap-x-1 justify-center"}>
                        <p className={"text-black text-sm font-medium font-['SF Pro Text'] leading-[18px]"}>
                            Already have an account?
                        </p>
                        <Link to="/login">
                            <p className={"text-accent font-sf-pro text-sm font-medium leading-[18px]"}>Log in</p>
                        </Link>
                    </div>
                    <PrivacyPolicy />
                </div>
            </div>
        </div>
    );
};

export default connect()(Signup);
