import React, { useEffect, useState } from "react";
import axios from "axios";

interface StreakData {
    streak: boolean[];
}

const StreakIndicator: React.FC = () => {
    const [streakData, setStreakData] = useState<StreakData>({ streak: [] });
    const [numberOfStreakDays, setNumberOfStreakDays] = useState<number>(0);

    useEffect(() => {
        getWeekStreak();
    }, []);

    const getWeekStreak = async () => {
        try {
            const response = await axios.get("/user_streak");
            const streakData = response.data as StreakData;
            const numberOfStreakDays = streakData.streak.filter(day => day).length;
            setStreakData(streakData);
            setNumberOfStreakDays(numberOfStreakDays);
        } catch (error) {
            console.error(error);
        }
    };

    const totalDays = 7;
    const indicators = [];

    for (let i = 0; i < totalDays; i++) {
        indicators.push(
            <div key={i} className={`rounded-full ${i < numberOfStreakDays ? 'bg-accent' : 'bg-borderOpaque'} size-4`} />
        );
    }

    return (
        <div className="flex w-full items-center py-auto flex-row grow rounded-[10px] border border-borderOpaque px-5 gap-x-4 h-10">
            <div className="inline-flex items-center justify-center w-full relative">
                <hr className="w-full bg-transparent border-t-1 border-dashed border-contentTertiary" />
                <span className="absolute flex flex-row grow justify-between w-full">
                    {indicators}
                </span>
            </div>
            <p className="text-sm font-sf-pro font-semibold text-contentPrimary text-nowrap">
                Streak: {numberOfStreakDays} days
            </p>
        </div>
    );
};

export default StreakIndicator;
