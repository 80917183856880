// For use at Fraser's and Hatchery COMPONENT_LIBRARY

import React, {useContext, useEffect, useState} from "react";
import PromptGeneration from "./PromptGeneration";
import inside_hatchery from "../../assets/inside-hatchery.svg";
import outside_hatchery from "../../assets/outside-hatchery.svg";
import generate_for_me from "../../assets/generate-for-me.svg";
import own_prompt from "../../assets/own-prompt.svg";
import axios from "axios";
import {Input} from "../primitives/input";
import {Button} from "../primitives/button";
import {CompanyConfigContext} from "../../CompanyConfigContextProvider";

type SetupModalProps = {
    isSetPrompt: boolean,
    setIsSetPrompt: (isSetPrompt: boolean) => void,
    setPrompt: (prompt: string) => void,
    writeInside: boolean,
    setWriteInside: (writeInside: boolean) => void,
    setOpenSetupModal: (open: boolean) => void,
    setIsWriting: (isWriting: boolean) => void,
    setEssayTitle: (essayTitle: string) => void,
    setEssayTask: (essayTitle: string) => void,
    essayTask: string,
}

interface SelectablePictureFrameProps {
    imageUrl: string;
    label: string;
    isSelected: boolean;
}

const SelectablePictureFrame: React.FC<SelectablePictureFrameProps> = ({ imageUrl, label, isSelected}) => {
    return (
        <div className={"flex flex-col"}>
            <div className={`cursor-pointer rounded-md mb-1 overflow-hidden border-2 ${isSelected ? 'border-borderSelected' : 'border-borderOpaque'}`}
            >
                <img
                    src={imageUrl}
                    alt="Setup choice image"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
            </div>
            <div className={"font-bold text-2xs"}>
                {label}
            </div>
        </div>
    );
};

function SetupModal(props: SetupModalProps) {
    const [step, setStep] = useState<number>(0);
    const [wtoolcred, setWtoolCred] = useState(null)
    const [wtoolsub, setWtoolsub] = useState(null)
    const companyConfig = useContext(CompanyConfigContext);


    useEffect(() => {
        axios.get("/get_remaining_writing_tool_credits")
            .then(response => {
                setWtoolCred(response.data.remaining_credits);
                setWtoolsub(response.data.active_subscription);
            })
            .catch(error => {
                console.error("Error fetching credits:", error);
            });
    }, []);

    if (!wtoolsub && companyConfig.premium_only_writing_tool){
        return (
            <div className={"p-4 flex flex-col gap-y-4"}>
                <div className={"text-contentPrimary text-xl font-medium font-inter-tight"}>Premium feature</div>
                <div className={""}>
                    You must be a premium user to use Writer
                </div>
                <div className={"w-full flex justify-end"}>
                    <Button
                        color={'secondary'}
                        onClick={() => window.location.href = "/dashboard"}
                        disabled={!props.isSetPrompt && !props.writeInside}

                    >Back to Dashboard
                    </Button>
                </div>
            </div>
        )
    } else {
        if (wtoolcred <= 0) {
            return (
                <div className={"p-4 flex flex-col gap-y-4"}>
                    <div className={"text-contentPrimary text-xl font-medium font-inter-tight"}>No credit remaining</div>
                    <div className={""}>
                        You are out of credits for today. Please check back at 12am AEST.
                    </div>
                    <div className={"w-full flex justify-end"}>
                        <Button
                            color={'secondary'}
                            onClick={() => window.location.href = "/dashboard"}
                            disabled={!props.isSetPrompt && !props.writeInside}

                        >Back to Dashboard
                        </Button>
                    </div>
                </div>
            )
        } else {
            if (step === 0) {
                return (
                    <div className={"flex flex-col py-6 px-4 gap-y-4"}>
                        <div className={"text-contentPrimary text-xl font-medium font-inter-tight"}>Setup</div>
                        <div className={"py-3 gap-y-6 flex flex-col"}>
                            <div className={"flex flex-col gap-y-2.5"}>
                                <div className={"text-xs font-normal font-inter text-contentTertiary"}>Where will you
                                    write your piece?
                                </div>
                                <div className={"flex flex-row gap-x-3"}>
                                    <div onClick={() => props.setWriteInside(true)}>
                                        <SelectablePictureFrame imageUrl={`${inside_hatchery}`}
                                                                label={`Inside ${companyConfig.company_name}`}
                                                                isSelected={props.writeInside === true}
                                        />
                                    </div>
                                    <div onClick={() => props.setWriteInside(false)}>
                                        <SelectablePictureFrame imageUrl={`${outside_hatchery}`}
                                                                label={`Outside ${companyConfig.company_name}`}
                                                                isSelected={props.writeInside === false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={"flex flex-col gap-y-2.5"}>
                                <div className={"text-xs font-normal font-inter text-contentTertiary"}>Where will you
                                    source your prompt?
                                </div>
                                <div className={"flex flex-row gap-x-3"}>
                                    <div onClick={() => {
                                        props.setIsSetPrompt(true)
                                    }}>
                                        <SelectablePictureFrame imageUrl={`${generate_for_me}`}
                                                                label={"Generate for me"}
                                                                isSelected={props.isSetPrompt === true}
                                        />
                                    </div>
                                    <div onClick={() => {
                                        props.setIsSetPrompt(false)
                                    }}>
                                        <SelectablePictureFrame imageUrl={`${own_prompt}`}
                                                                label={"I have my own prompt"}
                                                                isSelected={props.isSetPrompt === false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={"flex flex-col gap-y-2.5"}>
                                <div className={"text-xs font-normal text-contentTertiary"}>
                                    Do you want to name your piece? (Optional)
                                </div>
                                <div>
                                    <Input placeholder="This will help you recognise it in a list"
                                           onChange={(e) => props.setEssayTitle(e.target.value)}/>
                                </div>
                                <div className={"text-xs font-normal text-contentTertiary flex flex-col"}>
                                    <p>Premium users have 5 essay reviews every 24 hours.</p>
                                    <p>You have {wtoolcred} use{(wtoolcred !== 1) && "s"} remaining.</p>
                                </div>
                            </div>
                        </div>
                        <div className={"flex flex-row gap-x-2 w-full justify-end px-2"}>
                            <Button plain onClick={() => {
                                props.setOpenSetupModal(false)
                            }}> Cancel
                            </Button>
                            <Button
                                color={'secondary'}
                                onClick={() => {
                                    if (props.isSetPrompt) {
                                        setStep(1)
                                    } else {
                                        props.setOpenSetupModal(false)
                                        props.setIsWriting(true)
                                    }
                                }}
                                disabled={!props.isSetPrompt && !props.writeInside}

                            >{!props.isSetPrompt && !props.writeInside ? "Invalid Combination" : "Continue"}
                            </Button>
                        </div>
                    </div>)
            } else if (step === 1) {
                // prompt setup
                return (
                    <div>
                        {props.isSetPrompt && <PromptGeneration
                            backToSetup={() => setStep(0)}
                            setPrompt={props.setPrompt}
                            closePromptGeneration={() => props.setOpenSetupModal(false)}
                            setIsWriting={props.setIsWriting}
                            setEssayTask={props.setEssayTask}
                            essayTask={props.essayTask}
                        />}
                    </div>
                )
            }
        }
    }
}


export default SetupModal