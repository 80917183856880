import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import LoadingBar from "../primitives/LoadingBar";

interface SessionProgressBarProps {
    remainingTime: number;
    timeLimit: number;
    completeSection: () => void;
    minutes?: number;
    seconds?: number;
    color?:string;
}

function SessionProgressBar({
                                remainingTime,
                                timeLimit,
                                completeSection,
                                color = "bg-primaryB"
                            }: SessionProgressBarProps) {
    const [timeRemaining, setTimeRemaining] = useState(remainingTime);
    const [date, setDate] = useState<number>(new Date().getTime() + remainingTime);

    useEffect(() => {
        setDate(new Date().getTime() + timeRemaining);
    }, [timeRemaining]);

    const handleTick = (time: { total: number }) => {
        setTimeRemaining(time.total);
        if (time.total < 2000) {
            completeSection();
        }
    };
    const roundedPercent =
        timeRemaining > 0 ? Math.round((timeRemaining / timeLimit) * 100) : 0;

    return (
        <div className={"w-full h flex flex-row h-full items-center px-4"}>
            <div className={"flex shrink items-center justify-center mr-4"}>
                <Countdown
                    onTick={handleTick}
                    date={date}
                    renderer={(props) => (
                        <p className="text-contentPrimary font-inter text-sm font-medium flex items-center leading-none min-w-[110px]">
                            {props.minutes < 10 ? "0" + props.minutes : props.minutes}:
                            {props.seconds < 10 ? "0" + props.seconds : props.seconds}{" "}
                            remaining
                        </p>
                    )}
                />
            </div>
            <div className={"flex grow items-center"}>
                <LoadingBar  progress={roundedPercent} className={`${color} h-4`}/>
            </div>
        </div>
    );
}

export default SessionProgressBar;
