import React, { useState, ChangeEvent, FormEvent } from "react";
import { connect } from "react-redux";
import { logout } from "../login/LoginActions";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import { Button } from "../primitives/button";
import { Input } from "../primitives/input";
import { useToast } from "../ui/use-toast";

const UpdatePassword: React.FC = () => {
    const [updatePasswordModalOpen, setUpdatePasswordModalOpen] = useState(false);
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const { toast } = useToast();
    const navigate = useNavigate(); // Use useNavigate instead of withRouter

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "password") {
            setPassword(e.target.value);
        } else if (e.target.name === "password2") {
            setPassword2(e.target.value);
        }
    };

    const openUpdatePasswordModal = () => {
        setUpdatePasswordModalOpen(!updatePasswordModalOpen);
    };

    const validatePassword = () => {
        if (password !== password2) {
            toast({
                title: "Passwords do not match",
                description: "Please ensure both passwords are identical.",
            });
            return false;
        }
        if (password.length < 8) {
            toast({
                title: "Password must be at least 8 characters long",
                description: "Please use a longer password.",
            });
            return false;
        }
        if (password.length > 128) {
            toast({
                title: "Password must be less than 128 characters long",
                description: "Please use a shorter password.",
            });
            return false;
        }
        if (!password.match(/[a-z]/)) {
            toast({
                title: "Password must contain at least one lowercase letter",
                description: "Please include a lowercase letter.",
            });
            return false;
        }

        return true;
    };

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
    };

    const updatePassword = () => {
        if (!validatePassword()) {
            return;
        }
        axios
            .post("/change_password", {
                password,
                password2,
            })
            .then((response) => {
                toast({
                    title: "Success",
                    description: "Password updated",
                });
                // Optionally navigate after success
                navigate("/somepath"); // Change to the desired path
            })
            .catch((error) => {
                toast({
                    title: "Error",
                    variant: "destructive",
                    description: "Password update failed",
                });
            });
    };

    const updatePasswordForm = (
        <form onSubmit={onSubmit} className={"w-[240px] text-sm font-inter font-semibold leading-[14px] text-contentPrimary mt-2 bg-backgroundTertiary p-3 rounded-[4px]"}>
            <div className={"py-2 flex-col flex gap-y-2"}>
                <label>New Password</label>
                <Input type="password" name="password" value={password} onChange={onChange} />
            </div>
            <div className={"py-2 flex-col flex gap-y-2 mb-2"}>
                <label>Confirm New Password</label>
                <Input type="password" name="password2" onChange={onChange} value={password2} />
            </div>
            <Button type="submit" color={"secondary"} onClick={updatePassword}>
                Submit
            </Button>
        </form>
    );

    return (
        <div>
            <div>
                <Button onClick={openUpdatePasswordModal} outline>
                    Update Password
                </Button>
            </div>
            <div>{updatePasswordModalOpen ? updatePasswordForm : null}</div>
        </div>
    );
};

UpdatePassword.propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { logout })(UpdatePassword);
