import React, {useContext, useState} from 'react';
import axios from 'axios';
import {Textarea} from "../primitives/textarea";
import {CompanyConfigContext} from "../../CompanyConfigContextProvider";
import {Button} from "../primitives/button";
import {useToast} from "../ui/use-toast";

interface FlagManagerProps {
    questionId: string;
    setOpenModal: (open: boolean) => void,
}

const FlagManager: React.FC<FlagManagerProps> = (props) => {
    const [flagText, setFlagText] = useState<string>("")
    const companyConfig = useContext(CompanyConfigContext);
    const { toast } = useToast()

    const updateFlagData = async (id: string, description: string) => {
        try {
            await axios.post('/create_or_update_active_flag', {
                question_id: id,
                description: description,
            });
            toast({
                title: "Success",
                description: `Flag submitted, the ${companyConfig.company_name} team will review it shortly`,
            })
        } catch (error) {
            toast({
                variant: "destructive",
                title: "Error",
                description: "Error submitting flag, please try again",
            })
        }
    };

    const handleOk = () => {
        const updatedText = flagText || '';
        updateFlagData(props.questionId, updatedText);
        props.setOpenModal(false)

    };

    return (
        <div className={"p-6 flex flex-col gap-y-4"}>
            <div className={"text-normal font-inter-tight text-contentPrimary text-xl font-medium leading-5"}>Flag this question?</div>
            <Textarea
                className={"text-contentSecondary text-sm leading-tight font-inter"}
                placeholder={`Describe the issue that you've noticed with this question. This question will be reviewed by the ${companyConfig.company_name} team.`}
                value={flagText}
                onChange={(e) => setFlagText(e.target.value)}
            />
            <div className={"flex flex-row w-full justify-end gap-x-2"}>
                <Button
                    plain
                    onClick={() => {
                    props.setOpenModal(false)
                }}> Cancel
                </Button>
                <Button
                    color={"secondary"}
                    onClick={() => handleOk()}
                >Flag
                </Button>
            </div>
        </div>
    );
}

export default FlagManager;
