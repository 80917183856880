import React, {useEffect, useRef, useState} from "react";
import processedIconDark from "../../assets/processed-icon-dark.svg";
import processedIconLight from "../../assets/processed-icon-light.svg";
import {Dropdown, DropdownButton, DropdownItem, DropdownMenu} from "../primitives/dropdown";
import {EllipsisVerticalIcon} from "@heroicons/react/24/solid";
import Divider from "../primitives/Divider";

interface EssayInstance {
    id: number;
    title: string;
    task: string;
    date: Date;
    processed: boolean;
    feedBackColour: string;
    onClick: () => void;
    setOpenRenameModal: (open: boolean) => void,
    setOpenDeleteModal: (open: boolean) => void,
    setInstanceId: (instanceId: number) => void,
}

const formatDate = (date: Date): string => {
    if (!(date instanceof Date)) {
        // If date is not a Date object, try to create a new Date object from the string
        date = new Date(date);

        if (isNaN(date.getTime())) {
            console.error('Invalid date:', date);
            return 'Invalid Date';
        }
    }

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    // Function to add ordinal suffix to the day
    const getOrdinalSuffix = (number: number): string => {
        const suffixes = ["th", "st", "nd", "rd"];
        const v = number % 100;
        return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
    };

    return `${month} ${getOrdinalSuffix(day)} ${year}`;
};

const EssayInstance : React.FC<EssayInstance> = (props) => {
    const [isHovered, setIsHovered] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        !dropdownVisible && setIsHovered(false);
    };

    const handleRename = () => {
        props.setOpenRenameModal(true)
        props.setInstanceId(props.id)
    };

    const handleDelete = () => {
        props.setOpenDeleteModal(true)
        props.setInstanceId(props.id)
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setDropdownVisible(false);
                setTimeout(() => {
                    setIsHovered(false);
                }, 100)
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <>
            <div
                className={`flex flex-row cursor-pointer px-4 py-2 rounded-lg ${isHovered ? "bg-backgroundTertiary" : ""}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>

                <div className={"flex flex-row justify-start items-center grow"}
                     onClick={props.onClick}>
                    <div className={"flex items-center mr-2"}>
                        {props.feedBackColour ?
                            (<div className={'w-[11px] h-[11px] rounded-[100px]'}
                                  style={{backgroundColor: props.feedBackColour}}
                            />)
                            :
                            (props.processed ? <img alt={"processediconlight"} src={`${processedIconLight}`}/> :
                                <img alt={"processesicondark"} src={`${processedIconDark}`}/>)
                        }
                    </div>
                    <p className={"font-inter overflow-ellipsis text-nowrap font-medium text-sm mr-4"}>
                        {props.title}
                    </p>
                </div>
                <div className={"justify-end flex relative"}>
                    <div className={`z-2 flex flex-row justify-end items-center`}>
                        <div className={`z-2 flex items-center text-sm font-inter font-medium transition ease-in-out ${isHovered ? "-translate-x-8" : ""}  duration-300`} onClick={props.onClick}>
                            {props.task}
                            <Divider type={"vertical"} className={"mx-4 h-5"}/>
                            {formatDate(props.date)}
                        </div>
                        <div className={`ml-4 z-1 flex items-center absolute ${isHovered ? "" : "hidden"}`}
                             onClick={() => setDropdownVisible(true)}
                             ref={dropdownRef}>
                            <Dropdown>
                                <DropdownButton plain aria-label="More options" className={"h-[22px] w-[14px]"}>
                                    <EllipsisVerticalIcon/>
                                </DropdownButton>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => handleRename()}>Rename</DropdownItem>
                                    <DropdownItem onClick={() => handleDelete()}>Delete</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default EssayInstance;