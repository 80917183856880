import React, { useEffect, useState } from "react";
import axios from "axios";
import {ArrowTrendingUpIcon, ClockIcon, HashtagIcon} from "@heroicons/react/16/solid";
import SessionCreator from "../sessioncreator/SessionCreator";
import DashboardComponentTooltip from "../metrics/DashboardComponentTooltip";
import {useNavigate} from "react-router-dom";

interface Section {
    key: string;
    label: string;
    value: number | string;
    valueType: string;
    unit?: string;
    icon: JSX.Element;
    trend: number;
    tooltip_message?: string;
}

interface OverallStats {
    total_questions: number;
    user_percentile: number;
    total_questions_trend: number;
    user_percentile_trend: number;
}

export function OverallStats() {
    const [stats, setStats] = useState<OverallStats>({total_questions:0,user_percentile:0,total_questions_trend:0,user_percentile_trend:0});
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            const response = await axios.get("/overall_user_stats");
            const data = response.data;
            setStats(data);
            setIsLoading(false)
        }
        fetchData();
    }, []);

    function getOrdinalSuffix(number) {
        const suffixes = ["th", "st", "nd", "rd"];
        const lastDigit = number % 10;
        const lastTwoDigits = number % 100;

        if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
            return "th";
        }

        return suffixes[lastDigit] || "th";
    }

    const sections: Section[] = [
        {
            key: "user_percentile",
            label: "Percentile",
            value: stats.user_percentile || 0,
            valueType: "text-3xl font-black leading-[26px]",
            unit: getOrdinalSuffix(stats.user_percentile),
            trend: stats.user_percentile_trend,
            icon: <ArrowTrendingUpIcon className={"size-4 text-accent"}/>,
            tooltip_message: `You are performing better than ${stats.user_percentile}% of the cohort`,
        },
        {
            key: "total_questions",
            label: "Questions attempted",
            value: stats.total_questions || 0,
            valueType: "text-3xl font-black leading-[26px]",
            unit: "",
            trend: stats.total_questions_trend,
            icon: <HashtagIcon className={"size-4 text-primaryB"} onClick={()=>navigate('/learning_session_history')}/>
        },
        {
            key: "time_saved",
            label: "Hours saved travelling",
            value: (((stats.total_questions as number) || 0) * 2.5 / 60).toFixed(1),
            valueType: "text-3xl font-black leading-[26px]",
            unit: "",
            trend: parseFloat((((stats.total_questions_trend || 0) * 2.5 / 60).toFixed(1))),
            icon: <ClockIcon className={"size-4 text-primaryA"}/>
        },
    ];

    return (
        <div className="gap-8 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 max-w-[622px] w-full xl:max-w-full xl:w-full">
            <div
                className={`flex-col justify-center flex flex-grow min-w-0`}
            >
                <SessionCreator/>
            </div>
            {sections.map((section, index) => (
                <div
                    key={index}
                    className={`flex-col justify-center items-start flex rounded-lg border border-borderOpaque p-6 flex-grow min-w-[220px]`}
                >
                    <div className={"flex flex-row w-full mb-2 items-center"}>
                        <div className="text-black text-sm font-normal font-ppe-editorial-new-light leading-none flex justify-start flex-row gap-x-2.5 items-center max-h-3 flex-grow">
                            <p className={"flex items-center pt-0.5"}>{section.label}</p>
                            {section.tooltip_message && <DashboardComponentTooltip className={'size-4'} content={section.tooltip_message}/>}
                        </div>
                        <div className="text-black text-sm font-normal font-inter leading-[18px] flex justify-end items-center flex-shrink">
                            {section.icon}
                        </div>
                    </div>
                    {isLoading ?
                        <div role="status" className="max-w-sm animate-pulse">
                            <div className="h-8 bg-gray-200 rounded-full mb-4 w-10"></div>
                            <div className="h-2 bg-gray-200 rounded-full w-[200px]"></div>
                            <span className="sr-only">Loading...</span>
                        </div>
                        :
                        <>
                            <div
                                className={"flex mb-1 font-bold text-contentPrimary font-inter leading-[2rem] text-[2rem]"}>
                                {section.value}{section.unit}
                            </div>
                            <div className={"text-xs text-contentTertiary leading-tight"}>
                                {section.trend === 0 ? "No change" : (section.trend > 0 ? "+ " + Math.abs(section.trend) : "- " + Math.abs(section.trend))} from last
                                week
                            </div>
                        </>
                    }
                </div>
            ))}
        </div>
    );
}
