import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { createSession, goToActiveSession } from "./SessionCreatorActions";
import axios from "axios";
import { Button } from "../primitives/button";
import {
    AdjustmentsVerticalIcon,
    ArrowRightIcon,
    ArrowTrendingDownIcon,
    ArrowTrendingUpIcon,
    WrenchIcon,
} from "@heroicons/react/16/solid";
import { Modal } from "../primitives/modal";
import errorIcon from "../../assets/error-icon.svg";
import { Popover, Transition } from "@headlessui/react";
import { useToast } from "../ui/use-toast";
import aiIcon from "../../assets/ai-icon.svg";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../ui/select";
import { CompanyConfigContext } from "../../CompanyConfigContextProvider";
import useRedirectToPurchasePage from "../../utils/useRedirectToPurchasePage";
import hourglassendicon from "../../assets/hourglassicon/hourglass-end-solid.svg";
import hourglassstarticon from "../../assets/hourglassicon/hourglass-start-solid.svg";
import hourglasshalficon from "../../assets/hourglassicon/hourglass-half-solid.svg";
import hourglassicon from "../../assets/hourglassicon/hourglass-solid.svg";
import { useNavigate, NavigateFunction } from "react-router-dom";

interface SessionCreatorProps {
    createSession: (payload: {
        topic: string;
        size: number;
        content_area: string;
        time_limit: number;
    }) => Promise<boolean>;
    goToActiveSession: (navigate: NavigateFunction) => void;
}

const SessionCreator: React.FC<SessionCreatorProps> = ({
                                                           createSession,
                                                           goToActiveSession,
                                                       }) => {
    const companyConfig = useContext(CompanyConfigContext);
    const [state, setState] = useState({
        contentAreas: [],
        selectedContentArea: null,
        topics: [],
        selectedTopic: null,
        selectedSize: 0,
        selectedTimeLimit: 0.1,
        subscriptionStatus: null,
    });
    const [showModal, setShowModal] = useState(false);
    const buttonRef: React.RefObject<HTMLButtonElement> = useRef(null);
    const { toast } = useToast();
    const navigate = useNavigate(); // Use useNavigate instead of withRouter

    useEffect(() => {
        getLearningSessionOptions();
    }, []);

    const requestRecommendation = async () => {
        try {
            const response = await axios.get("/request_recommendation");
            return response.data.reason;
        } catch (error) {
            console.error("Error requesting recommendation:", error);
            return toast({
                title: "Error",
                description: "Failed to apply AI recommendation",
            });
        }
    };

    const applyRecommendation = async () => {
        try {
            await axios.get("/apply_recommendation");
        } catch (error) {
            console.error("Error applying recommendation:", error);
        }
    };

    const onAddClick = async () => {
        const recommendation = await requestRecommendation();
        await applyRecommendation();
        console.log("Recommendation:", recommendation);
        goToActiveSession(navigate);
    };

    const OverallQuestionTimeModifier = 0.6;

    const goToSessionViewer = async () => {
        const payload = {
            topic: state.selectedTopic,
            size: state.selectedSize,
            content_area: state.selectedContentArea,
            time_limit:
                state.selectedTimeLimit === 0.1
                    ? 0
                    : state.selectedSize
                        ? state.selectedTimeLimit * state.selectedSize * OverallQuestionTimeModifier
                        : state.selectedTimeLimit * 10 * OverallQuestionTimeModifier,
        };
        const proceed = await createSession(payload);
        if (!proceed) {
            if (state.subscriptionStatus === false) {
                setShowModal(true);
                return;
            } else {
                setShowModal(false);
                return toast({
                    title: "Error",
                    description: "No more questions for this topic. Please check back later.",
                });
            }
        }
        goToActiveSession(navigate);
    };

    const redirectToPurchasePage = useRedirectToPurchasePage();

    const getLearningSessionOptions = () => {
        axios.get("/get_learning_session_options").then((r) => {
            setState({
                ...state,
                topics: r.data.topics,
                contentAreas: r.data.content_areas,
            });
        });
    };

    return (
        <>
            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                size={"5xl"}
                className="absolute top-[60px] left-1/2 transform -translate-x-1/2 rounded-0.625rem p-0 overflow-y-auto max-h-calc(100vh - 120px)"
            >
                <>
                    <div className={"flex flex-row items-start mb-[20px]"}>
                        <div className={"shrink min-h-6 min-w-6 pt-0.5 mr-2.5"}>
                            <img alt={"erroricon"} src={`${errorIcon}`} />
                        </div>
                        <p className={"text-xl font-medium"}>Out of question credit!</p>
                    </div>
                    <div className={"text-slate-600 text-xs mb-[16px]"}>
                        You have consumed all your question credits. You can upgrade your account to get more question credits. Otherwise, you can wait until the next day to get more question credits.
                    </div>
                    <div className={"flex flex-row justify-end gap-2"}>
                        <Button outline onClick={() => setShowModal(false)}>
                            Cancel
                        </Button>
                        <Button color={"dangerAngry"} onClick={redirectToPurchasePage}>
                            Upgrade
                        </Button>
                    </div>
                </>
            </Modal>
            <div className={"flex flex-col gap-y-2.5 h-full justify-between"}>
                <Popover className="relative">
                    {({ open }) => (
                        <>
                            <Popover.Button className={"w-full h-[46px]"} ref={buttonRef}>
                                <Button outline className={"w-full h-full"}>
                                    <WrenchIcon className={"size-4"} /> Build your own session
                                </Button>
                            </Popover.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <Popover.Panel className="absolute left-0 mt-3 w-[355px] max-w-sm z-50 px-4 sm:px-0 lg:max-w-3xl">
                                    <div className="overflow-hidden rounded-lg w-[355px] shadow-lg ring-1 ring-black/5">
                                        <div className={"bg-backgroundPrimary h-full p-6 flex flex-col gap-y-4"}>
                                            <div className={"flex flex-col gap-y-1"}>
                                                <p className={"font-inter-tight font-semibold text-md"}>
                                                    Build a session
                                                </p>
                                                <p className={"font-inter font-normal text-sm"}>
                                                    Design how you&apos;d like your session.
                                                </p>
                                            </div>
                                            <div className={"flex flex-col gap-y-2.5 w-full"}>
                                                <Select
                                                    value={state.selectedSize.toString()}
                                                    onValueChange={(value) => {
                                                        if (value === "Small") {
                                                            setState({ ...state, selectedSize: 5 });
                                                        } else if (value === "Medium") {
                                                            setState({ ...state, selectedSize: 10 });
                                                        } else if (value === "Large") {
                                                            setState({ ...state, selectedSize: 15 });
                                                        }
                                                    }}
                                                >
                                                    <SelectTrigger className="w-full">
                                                        <SelectValue>
                                                            {state.selectedSize === 0 ? (
                                                                <div className="flex flex-row gap-x-1.5 items-center">
                                                                    <AdjustmentsVerticalIcon className="h-4 w-4" />
                                                                    Size
                                                                </div>
                                                            ) : state.selectedSize === 5 ? (
                                                                "Small"
                                                            ) : state.selectedSize === 10 ? (
                                                                "Medium"
                                                            ) : state.selectedSize === 15 ? (
                                                                "Large"
                                                            ) : null}
                                                        </SelectValue>
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectItem value="Small">
                                                            <div className="flex flex-row items-center gap-x-2">
                                                                <ArrowTrendingDownIcon className="size-4" />
                                                                Small
                                                            </div>
                                                        </SelectItem>
                                                        <SelectItem value="Medium">
                                                            <div className="flex flex-row items-center gap-x-2">
                                                                <ArrowRightIcon className="size-4" />
                                                                Medium
                                                            </div>
                                                        </SelectItem>
                                                        <SelectItem value="Large">
                                                            <div className="flex flex-row items-center gap-x-2">
                                                                <ArrowTrendingUpIcon className="size-4" />
                                                                Large
                                                            </div>
                                                        </SelectItem>
                                                    </SelectContent>
                                                </Select>
                                                <Select
                                                    value={state.selectedContentArea?.name || ""}
                                                    onValueChange={(value) => {
                                                        const selectedContentArea = state.contentAreas.find(
                                                            (contentArea) => contentArea.name === value
                                                        );
                                                        setState({ ...state, selectedContentArea });
                                                    }}
                                                >
                                                    <SelectTrigger className="w-full">
                                                        <SelectValue placeholder="Content Area" />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        {state.contentAreas.map((contentArea) => (
                                                            <SelectItem key={contentArea.id} value={contentArea.name}>
                                                                {contentArea.name}
                                                            </SelectItem>
                                                        ))}
                                                    </SelectContent>
                                                </Select>
                                                <Select
                                                    value={state.selectedTimeLimit.toString()}
                                                    onValueChange={(value) => {
                                                        if (value === "None") {
                                                            setState({ ...state, selectedTimeLimit: 0 });
                                                        } else if (value === "Generous") {
                                                            setState({ ...state, selectedTimeLimit: 1.5 });
                                                        } else if (value === "Moderate") {
                                                            setState({ ...state, selectedTimeLimit: 1 });
                                                        } else if (value === "Extreme") {
                                                            setState({ ...state, selectedTimeLimit: 0.5 });
                                                        }
                                                    }}
                                                >
                                                    <SelectTrigger className="w-full">
                                                        <SelectValue placeholder="Timer">
                                                            {state.selectedTimeLimit === 0.1 ? (
                                                                <div className="flex flex-row gap-x-1.5 items-center">
                                                                    Timer
                                                                </div>
                                                            ) : state.selectedTimeLimit === 0 ? (
                                                                "None"
                                                            ) : state.selectedTimeLimit === 1.5 ? (
                                                                "Generous"
                                                            ) : state.selectedTimeLimit === 1 ? (
                                                                "Moderate"
                                                            ) : state.selectedTimeLimit === 0.5 ? (
                                                                "Extreme"
                                                            ) : null}
                                                        </SelectValue>
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectItem value="None">
                                                            <div className="flex flex-row items-center gap-x-2">
                                                                <img
                                                                    alt={"hourglass"}
                                                                    className={"size-4"}
                                                                    src={`${hourglassicon}`}
                                                                />
                                                                None
                                                            </div>
                                                        </SelectItem>
                                                        <SelectItem value="Generous">
                                                            <div className="flex flex-row items-center gap-x-2">
                                                                <img
                                                                    alt={"hourglass"}
                                                                    className={"size-4"}
                                                                    src={`${hourglassstarticon}`}
                                                                />
                                                                Generous
                                                            </div>
                                                        </SelectItem>
                                                        <SelectItem value="Moderate">
                                                            <div className="flex flex-row items-center gap-x-2">
                                                                <img
                                                                    alt={"hourglass"}
                                                                    className={"size-4"}
                                                                    src={`${hourglasshalficon}`}
                                                                />
                                                                Moderate
                                                            </div>
                                                        </SelectItem>
                                                        <SelectItem value="Extreme">
                                                            <div className="flex flex-row items-center gap-x-2">
                                                                <img
                                                                    alt={"hourglass"}
                                                                    className={"size-4"}
                                                                    src={`${hourglassendicon}`}
                                                                />
                                                                Extreme
                                                            </div>
                                                        </SelectItem>
                                                    </SelectContent>
                                                </Select>
                                            </div>
                                            <div className={"grid grid-cols-2"}>
                                                <div className={"flex justify-start"}>
                                                    <Button onClick={() => buttonRef.current?.click()} outline>
                                                        Cancel
                                                    </Button>
                                                </div>
                                                <div className={"flex justify-end"}>
                                                    <Button
                                                        disabled={!(state.selectedSize && state.selectedContentArea)}
                                                        color={"primary"}
                                                        onClick={goToSessionViewer}
                                                    >
                                                        Start
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </>
                    )}
                </Popover>
                <Button onClick={onAddClick} className={"h-[46px]"}>
                    <img alt={"ai icon"} src={`${aiIcon}`} />
                    Let {companyConfig.company_name} AI decide
                </Button>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { createSession, goToActiveSession })(SessionCreator);
