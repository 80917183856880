import React, {useState, useEffect, useContext} from "react";
import axios from "axios";
import { Button } from "../primitives/button";
import { XMarkIcon } from "@heroicons/react/16/solid";
import {CompanyConfigContext} from "../../CompanyConfigContextProvider";
import {Input} from "../primitives/input";
import {useToast} from "../ui/use-toast";

interface ReferralPageProps {
    setOpenModal: (value: boolean) => void;
}

const ReferralPage: React.FC<ReferralPageProps> = ({ setOpenModal }) => {
    const [refCode, setRefCode] = useState(null);
    const [successfulReferrals, setSuccessfulReferrals] = useState(0);
    const companyConfig = useContext(CompanyConfigContext);
    const { toast } = useToast()

    useEffect(() => {
        getRefCode();
        getNumSuccessfulReferrals();
    }, []);

    const getRefCode = () => {
        axios.get("/get_my_referral_code/").then((response) => {
            setRefCode(response.data.code);
        });
    };

    const getNumSuccessfulReferrals = () => {
        axios.get("/get_num_successful_referrals/").then((response) => {
            setSuccessfulReferrals(response.data.num_successful_referrals);
        });
    };

    const getReferralUrl = () => {
        return window.location.origin + "/signup?ref_code=" + refCode;
    };

    return (
        <div className={"w-full flex flex-col overflow-y-auto"}>
            <div className={"grid grid-cols-2 py-2 pl-4 pr-2 border-b border-borderOpaque items-center"}>
                <p className={"text-sm text-contentPrimary font-bold flex justify-start"}>Invite a Friend</p>
                <div className={"flex justify-end"}>
                    <Button className={"h-8 w-8"} onClick={() => setOpenModal(false)} plain>
                        <XMarkIcon />
                    </Button>
                </div>
            </div>
            <div className={"px-4 pt-4 pb-6 gap-y-12 flex flex-col max-w-[612px]"}>
                <div className={"flex flex-col justify-start gap-y-3"}>
                    <p className={"text-contentPrimary text-sm font-normal font-inter leading-none"}>Every single time you invite a friend, {companyConfig.company_name} gets better at knowing how you rank.</p>
                    <p className={"text-sm font-inter font-normal text-contentSecondary"}>For every friend or classmate you refer with your unique code, as a welcome gift, they’ll get 100 more questions than usual!</p>
                </div>
                <div className={"flex flex-col gap-y-4"}>
                    <p className={"text-sm font-inter font-semibold leading-[14px] text-contentPrimary"}>Your referral URL</p>
                    <p className={"text-sm font-inter font-normal leading-[14px] text-contentPrimary"}>Share your unique URL to invite friends to {companyConfig.company_name} for free.</p>
                    <div className={"flex flex-row gap-x-3"}>
                        <Input className={"max-w-[412px]"} placeholder={getReferralUrl()} disabled={true} />
                        <Button color={"secondary"} className={"w-fit"} onClick={() => {
                            navigator.clipboard.writeText(getReferralUrl());
                            toast({
                                title: "URL Copied",
                                description: "Referral URL copied to clipboard",
                            })
                        }}>Copy</Button>
                    </div>
                </div>
                <div className={"flex flex-col text-contentTertiary text-xs font-inter leading-[18px] w-full"}>
                    <p className={"text-sm font-inter font-semibold leading-[14px] text-contentPrimary border-b border-borderOpaque pb-4"}>Your referral stats</p>
                    <div className={"flex flex-row w-full"}>
                        <div className={"flex flex-col justify-start"}>
                            <div className={"border-b border-borderOpaque pr-4 py-2"}>Clicks</div>
                            <div className={"border-b border-borderOpaque pr-4 py-2"}>Referrals</div>
                            <div className={"border-b border-borderOpaque pr-4 py-2"}>Access</div>
                        </div>
                        <div className={"flex flex-col grow"}>
                            <div className={"border-b border-borderOpaque py-2"}>Number of times your link has been clicked</div>
                            <div className={"border-b border-borderOpaque py-2"}>Number of people who signed up for {companyConfig.company_name} with your link</div>
                            <div className={"border-b border-borderOpaque py-2"}>Number of additional questions you have unlocked access to</div>
                        </div>
                        <div className={"flex flex-col text-contentSecondary items-end"}>
                            <div className={"border-b border-borderOpaque py-2 w-fit"}>
                                <div className={"px-1 leading-[18px] bg-stone-100 rounded text-gray-500 text-center"}>NA</div>
                            </div>
                            <div className={"border-b border-borderOpaque py-2 w-fit"}>
                                <div className={"px-1 leading-[18px] bg-stone-100 rounded text-gray-500 text-center"}>{successfulReferrals}</div>
                            </div>
                            <div className={"border-b border-borderOpaque py-2 w-fit"}>
                                <div className={"px-1 leading-[18px] bg-stone-100 rounded text-gray-500 text-center"}>NA</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReferralPage;
