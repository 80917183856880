import React, { useState, useEffect } from "react";
import { Input } from "../primitives/input";
import { Fieldset, Label } from "../primitives/fieldset";
import { Checkbox, CheckboxField, CheckboxGroup } from "../primitives/checkbox";
import {CompanyConfigContext} from "../../CompanyConfigContextProvider";
import {Button} from "../primitives/button";

interface UserInformationEntryProps {
    currentProfile?: {
        profile: ProfileInformation
    };
    updateData: (data: {
        first_name: string;
        last_name: string;
        mobile_number: string;
        enable_progress_reports: boolean;
        external_reference?: string;
    }) => void;
    submitCurrentStep: () => void;
    invalidPhoneNumber: boolean;
}

interface UserInformationState {
    first_name: string;
    last_name: string;
    mobile_number: string;
    enable_progress_reports: boolean;
}

const UserInformationEntry: React.FC<UserInformationEntryProps> = ({
                                                                       currentProfile,
                                                                       updateData,
                                                                       submitCurrentStep,
                                                                        invalidPhoneNumber,
                                                                   }) => {
    const [firstName, setFirstName] = useState<string>(
        currentProfile ? currentProfile.profile.first_name : ""
    );
    const [lastName, setLastName] = useState<string>(
        currentProfile ? currentProfile.profile.last_name : ""
    );
    const [mobileNumber, setMobileNumber] = useState<string>(
        currentProfile ? currentProfile.profile.mobile_number : ""
    );
    const [enableProgressReports, setEnableProgressReports] = useState<boolean>(true);
    const companyConfig = React.useContext(CompanyConfigContext);
    const onChange = async (newState: Partial<UserInformationState>) => {
        if (newState.first_name !== undefined) setFirstName(newState.first_name);
        if (newState.last_name !== undefined) setLastName(newState.last_name);
        if (newState.mobile_number !== undefined) setMobileNumber(newState.mobile_number);
        if (newState.enable_progress_reports !== undefined)
            setEnableProgressReports(newState.enable_progress_reports);
        if (companyConfig.collect_external_reference) {
            updateData({
                first_name: newState.first_name ?? firstName,
                last_name: newState.last_name ?? lastName,
                mobile_number: newState.mobile_number ?? mobileNumber,
                enable_progress_reports: newState.enable_progress_reports ?? enableProgressReports,
                external_reference: newState.mobile_number ?? mobileNumber,
            });
        } else {
            updateData({
                first_name: newState.first_name ?? firstName,
                last_name: newState.last_name ?? lastName,
                mobile_number: newState.mobile_number ?? mobileNumber,
                enable_progress_reports: newState.enable_progress_reports ?? enableProgressReports,
            });
        }
    };

    const handleProceed = () => {
        submitCurrentStep()
    }

    useEffect(() => {
        onChange({
            first_name: firstName,
            last_name: lastName,
            mobile_number: mobileNumber,
            enable_progress_reports: enableProgressReports,
        });
    }, []);

    return (
        <div className="justify-center w-full flex">
            <div className={'flex flex-col gap-10'}>
                <div className="flex flex-col">
                    <p className={'text-contentPrimary font-ppe-editorial-new-light text-[42px]'}>Tell us a little about you</p>
                    <p className="text-contentSecondary font-inter font-normal text-sm">
                        Personalize your {companyConfig.company_name} experience and make it your own.
                    </p>
                </div>
                <div className={'flex-col flex gap-6 w-[266px]'}>
                    <div className={"flex flex-col gap-2"}>
                        <p className="text-contentPrimary font-semibold leading-tight text-sm">First Name</p>
                        <div className={'h-9'}>
                            <Input
                                type="text"
                                value={firstName}
                                className={'h-fit'}
                                placeholder="So we know what to call you..."
                                onChange={(e) => onChange({first_name: e.target.value})}
                            />
                        </div>

                    </div>
                    <div className={"flex flex-col gap-2"}>
                        <p className="text-contentPrimary font-semibold leading-tight text-sm">Last Name</p>
                        <div className={'h-9'}>
                            <Input
                                type="text"
                                value={lastName}
                                placeholder="So we know what to call you..."
                                onChange={(e) => onChange({last_name: e.target.value})}
                            />
                        </div>
                    </div>
                    <div className={"flex flex-col gap-2"}>
                        <p className="text-contentPrimary font-semibold leading-tight text-sm">Mobile Number</p>
                        <div className={'h-9'}>
                            <Input
                                type="text"
                                value={mobileNumber}
                                placeholder="To send your progress reports to..."
                                onChange={(e) => onChange({mobile_number: e.target.value})}
                            />
                        </div>
                        {!invalidPhoneNumber ?
                            <p className={'text-contentTertiary text-sm font-inter leading-tight font-medium'}>If you have an Altus One account, ensure you use the same phone number here.</p>
                            :
                            <p className={'text-contentNegative text-sm font-inter leading-tight font-medium'}>Invalid
                                Mobile Number</p>
                        }

                    </div>
                    {!companyConfig.disable_progress_reports &&
                        <div className="mt-3 flex flex-row gap-x-2">
                            <Fieldset>
                                <CheckboxGroup>
                                    <CheckboxField>
                                        <Checkbox
                                            name="progress_reports"
                                            onChange={() =>
                                                onChange({ enable_progress_reports: !enableProgressReports })
                                            }
                                            defaultChecked
                                        />
                                        <Label>I would like to be sent progress reports</Label>
                                    </CheckboxField>
                                </CheckboxGroup>
                            </Fieldset>
                        </div>
                    }
                </div>
                <div className={'flex justify-start w-full'}>
                    <Button color={'primary'} onClick={handleProceed}>Continue</Button>
                </div>
            </div>
        </div>
    );
};

export default UserInformationEntry;
