import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import axios from "axios";
import UpdatePassword from "./UpdatePassword";
import { Button } from "../primitives/button";
import {WrenchIcon, XMarkIcon} from "@heroicons/react/16/solid";
import { Input } from "../primitives/input";
import { Listbox, ListboxLabel, ListboxOption } from "../primitives/listbox";
import { Field } from "../primitives/fieldset";
import { logout } from "../login/LoginActions";
import { useToast } from "../ui/use-toast"
import useRedirectToPurchasePage from "../../utils/useRedirectToPurchasePage";
import {useNavigate} from "react-router-dom";



interface ProfilePageProps {
    setOpenModal: (open: boolean) => void;
    logout: () => void;
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
});

const connector = connect(mapStateToProps, { logout });
type PropsFromRedux = ConnectedProps<typeof connector>;
interface AdditionalProps extends PropsFromRedux {}

const ProfilePage: React.FC<ProfilePageProps&AdditionalProps> = (props) => {
    const navigate = useNavigate();
    const { toast } = useToast()
    const [state, setState] = useState({
        remainingQuestionCredits: 0,
        subscriptionStatus: null,
        expirationDate: null,
        selected_school: null,
        school_list: [],
        year_of_exam: null,
        firstName: null,
        lastName: null,
        mobile: null,
        profile: null,
    });
    const [isEditing, setIsEditing] = useState(false);
    const [isMobileValid, setIsMobileValid] = useState(true);
    const redirectToPurchasePage = useRedirectToPurchasePage();


    useEffect(() => {
        getRemainingQuestionCredits();
        getUserSubscriptionDetails();
        getSchoolList();
        getUserProfile();
    }, []);

    const onLogoutClick = () => {
        props.logout(toast, navigate);
    };

    const updateField = (field, value) => {
        if (value !== null) {
            axios
                .post("/update_profile", {
                    [field]: value,
                })
                .then(() => {
                    // toastOnSuccess(`${field} updated successfully`);
                })
                .catch(() => {
                    // toastOnError(`Error updating ${field}`);
                });
        }
    };

    const updateUserProfile = () => {
        const { firstName, lastName, selected_school, year_of_exam, mobile } = state;

        const updateData = {
            first_name: firstName,
            last_name: lastName,
            mobile_number: mobile,
            selected_school,
            year_of_exam,
        };

        Object.entries(updateData).forEach(([field, value]) => updateField(field, value));
        toast({
            title: "Profile details updated",
            description: "Changes will become visible upon page refresh",
        })
    };

    const handleSave = () => {
        if (isMobileValid) {
            if (!areFieldsEmpty()) {
                updateUserProfile();
                setIsEditing(false);
            } else {
                toast({
                    title: "Error",
                    variant: "destructive",
                    description: "Please fill in all required fields.",
                })
            }
        } else {
            toast({
                title: "Error",
                variant: "destructive",
                description: "Please enter a valid phone number.",
            })

        }
    };

    const areFieldsEmpty = () => {
        const { firstName, lastName, mobile } = state;
        return (state.profile.first_name && !firstName) || (state.profile.last_name && !lastName) || (state.profile.mobile_number && !mobile);
    };

    const getSchoolList = () => {
        axios.get("/get_all_schools")
            .then((response) => {
                setState(prevState => ({ ...prevState, school_list: response.data.schools }))
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const onChange = (e) => {
        const { name, value } = e.target;
        if (name === 'mobile') {
            setState(prevState => ({ ...prevState, [name]: value }));
            validatePhoneNumber(value);
        } else {
            setState(prevState => ({ ...prevState, [name]: value }));
        }
    };

    const validatePhoneNumber = (mobile) => {
        const cleanedMobile = mobile.replace(/[^\d+]/g, '');

        const isValidLength = cleanedMobile.length >= 10 && cleanedMobile.length <= 15;

        const isValidPattern = /^(\+?\d{1,3})?(\d{10,12})$/.test(cleanedMobile);

        const countryCodePattern = /^\+?[1-9]\d{1,3}$/;

        let isCountryCodeValid = true;
        if (cleanedMobile.startsWith('+')) {
            const parts = cleanedMobile.split(/(\d{1,3})/);
            isCountryCodeValid = countryCodePattern.test(parts[1]);
        }

        if (isValidLength && isValidPattern && isCountryCodeValid) {
            setIsMobileValid(true);
        } else {
            setIsMobileValid(false);
        }
    };


    const onChangeSchool = (newSchool) => {
        setState(prevState => ({ ...prevState, selected_school: newSchool.selected_school }));
    };

    const onChangeYear = (year) => {
        setState(prevState => ({ ...prevState, year_of_exam: year }));
    };

    const getUserProfile = () => {
        axios.get("/my_profile")
            .then(response => {
                const { profile } = response.data;
                setState(prevState => ({
                    ...prevState,
                    profile: profile,
                    firstName: profile.first_name,
                    lastName: profile.last_name,
                    mobile: profile.mobile_number,
                    selected_school: profile.school_to_enter?.name,
                    year_of_exam: profile.year_of_exam
                }));
            }).catch(error => {
            console.log(error);
        });
    };

    const getRemainingQuestionCredits = () => {
        axios.get("/user_remaining_question_credits")
            .then(response => {
                setState(prevState => ({ ...prevState, remainingQuestionCredits: response.data.question_credits }));
            }).catch(error => {
            console.log(error);
        });
    };

    const goToPurchasePage = () => {
        redirectToPurchasePage()
    };

    const getUserSubscriptionDetails = () => {
        axios.get("/user_has_active_subscription_request")
            .then(response => {
                if (response.data.has_active_subscription === true) {
                    setState(prevState => ({ ...prevState, subscriptionStatus: true, expirationDate: response.data.expiry_date }));
                } else {
                    getRemainingQuestionCredits();
                    setState(prevState => ({ ...prevState, subscriptionStatus: false }));
                }
                getRemainingQuestionCredits();
            }).catch(error => {
            console.log(error);
        });
    };

    const thisAndNextFiveYears = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = 0; i < 5; i++) {
            years.push(currentYear + i);
        }
        return years;
    };

    const redoOnboarding = () => {
        axios.get("/reset_onboarding_status")
            .then(response => {
                console.log(response.data);
                navigate("/onboarding");
            }).catch(error => {
            console.log(error);
        });
    };

    return (
        <div className={"w-full flex flex-col overflow-y-auto"}>
            <div className={"grid grid-cols-2 py-2 pl-4 pr-2 border-b border-borderOpaque items-center"}>
                <p className={"text-sm text-contentPrimary font-bold flex justify-start"}>Account</p>
                <div className={"flex justify-end"}>
                    <Button className={"h-8 w-8"} onClick={()=>props.setOpenModal(false)} plain>
                        <XMarkIcon />
                    </Button>
                </div>
            </div>
            <div className={"px-4 pt-4 pb-6 gap-y-5 flex flex-col"}>
                <div className={"grid grid-cols-2 pb-5 border-b border-borderOpaque text-sm font-semibold font-inter leading-[14px] text-contentPrimary items-center"}>
                    <div className={"flex flex-col justify-start gap-y-3"}>
                        <p>Pl<a onClick={() => navigate('data_room')}>a</a><a onClick={() => redoOnboarding()}>n</a></p>
                        <p className={"font-bold text-base"}>{state.subscriptionStatus === false ? "Free" : "Ultra"}</p>
                    </div>
                    <div className={"flex justify-end"}>
                        <Button className={"h-8"} outline onClick={() => goToPurchasePage()}>Manage Plan</Button>
                    </div>
                </div>
                <div className={"flex flex-row"}>
                    <div className={"flex flex-col gap-y-5 grow"}>
                        <div className={"flex flex-col gap-y-3"}>
                            <p className={"text-sm font-inter font-semibold leading-[14px] text-contentPrimary"}>First Name</p>
                            <div className={"w-[240px] h-10"}><Input name="firstName" value={state.firstName} onChange={onChange} disabled={!isEditing}/></div>
                        </div>
                        <div className={"flex flex-col gap-y-3"}>
                            <p className={"text-sm font-inter font-semibold leading-[14px] text-contentPrimary"}>Last Name</p>
                            <div className={"w-[240px] h-10"}><Input name="lastName" value={state.lastName} onChange={onChange} disabled={!isEditing}/></div>
                        </div>
                        <div className={"flex flex-col gap-y-3"}>
                            <p className={"text-sm font-inter font-semibold leading-[14px] text-contentPrimary"}>Password</p>
                            <UpdatePassword />
                        </div>
                        <div className={"flex flex-col gap-y-3"}>
                            <p className={"text-sm font-inter font-semibold leading-[14px] text-contentPrimary"}>Phone Number</p>
                            <div className={"w-[240px] h-10"}><Input name="mobile" value={state.mobile} onChange={onChange}
                                                                disabled={!isEditing}/></div>
                        </div>
                        <div className={"flex flex-col gap-y-3 text-sm font-inter  leading-[14px]"}>
                            <p className={"text-contentPrimary font-semibold"}>Goal</p>
                            <p className={"text-stone-400 font-medium"}>What school are you aiming to get into?</p>
                            <div className={"w-[240px] h-10"}>
                                <Field>
                                <Listbox name="status" className={"w-[500px]"} placeholder={state.profile?.school_to_enter?.name} disabled={!isEditing}>
                                        {state.school_list.map((school) => {
                                            return <ListboxOption key={school.id} value={school?.name} onClick={() => onChangeSchool({ selected_school: school })}>
                                                <ListboxLabel>{school?.name}</ListboxLabel>
                                            </ListboxOption>
                                        })}
                                    </Listbox>
                                </Field>
                            </div>
                        </div>
                        <div className={"flex flex-col gap-y-3 text-sm font-inter  leading-[14px]"}>
                            <p className={"text-contentPrimary font-semibold"}>Exam Year</p>
                            <p className={"text-stone-400 font-medium"}>What year are you sitting this exam?</p>
                            <div className={"w-[240px] h-10"}>
                                <Field>
                                    <Listbox name="status" className={"w-[500px]"} placeholder={state.profile?.year_of_exam} disabled={!isEditing}>
                                        {thisAndNextFiveYears().map((year) => {
                                            return <ListboxOption key={year} value={year} onClick={() => onChangeYear(year)}>
                                                <ListboxLabel>{year}</ListboxLabel>
                                            </ListboxOption>
                                        })}
                                    </Listbox>
                                </Field>
                            </div>
                        </div>
                    </div>
                    <div className={"flex shrink flex-col"}>
                        <div className={"flex flex-row gap-x-3 justify-end"}>
                            {isEditing ?
                                <Button color={"secondary"} onClick={handleSave}>Save Changes</Button>
                                :
                                <Button color={"primary"} onClick={()=>setIsEditing(true)}><WrenchIcon className={"size-4"}/>Edit</Button>
                            }
                            <Button onClick={onLogoutClick}>Sign out</Button>
                        </div>
                    </div>
                </div>
                {/*{isEditing &&*/}
                {/*    <div className={"flex flex-col w-[160px] gap-y-4"}>*/}
                {/*        <Button color={"danger"} onClick={() => redoOnboarding()}>*/}
                {/*            Redo Onboarding*/}
                {/*        </Button>*/}
                {/*    </div>*/}
                {/*}*/}
            </div>
        </div>
    );
};

export default connector(ProfilePage);
