import React from "react";
import {ErrorBoundary} from "react-error-boundary";
import {BinaryStepLine} from "./BinaryStepLine";
import errorScreen from "./ErrorScreen";


interface AccuracyResultsProps {
    sectionResults: Record<string, SectionResults>;
}

export function AccuracyResults(props: AccuracyResultsProps) {

    return (
        <div className={"w-full h-full flex flex-col"}>
            {Object.keys(props.sectionResults).map((sectionName, index) => (
                <ErrorBoundary fallback={errorScreen()} key={index}>
                    <div className={"flex flex-col rounded-[10px] border border-borderOpaque break-inside-avoid-column"}>
                        <div
                            className={"rounded-t-[10px] bg-backgroundTertiary px-6 text-lg font-sf-pro text-contentPrimary leading-[28px] py-1"}>
                            Correct Answer Consistency for <b>{sectionName}</b>
                        </div>
                        <div className={"min-w-full py-6 h-[600px]"}>
                            <BinaryStepLine id={props.sectionResults[sectionName].id}/>
                        </div>
                    </div>
                </ErrorBoundary>
            ))}
        </div>
    );
}
