import React, { useEffect, useState } from "react";
import axios from "axios";
import { WrappedCheckoutForm } from "../../membership/CheckoutForm";
import { Button } from "../../primitives/button";
import {MinusIcon, XMarkIcon} from "@heroicons/react/16/solid";
import { CompanyConfigContext } from "../../../CompanyConfigContextProvider";
import {useToast} from "../../ui/use-toast";
import ProductSelectCard from "./ProductSelectCard";
import FreeProductCard from "../FreeProductCard";
import {PlusIcon} from "@heroicons/react/20/solid";
import AltusProductCard from "../AltusProductCard";

interface PurchasePageProps {
    onSelectProduct: (product: Product) => void;
}

const PurchasePage: React.FC<PurchasePageProps> = ({ onSelectProduct }) => {
    const [products, setProducts] = useState<Product[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
    const [displayCheckoutForm, setDisplayCheckoutForm] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const companyConfig = React.useContext(CompanyConfigContext);
    const { toast } = useToast()
    const [numberOfMonths, setNumberOfMonths] = useState(1);
    const [maxMonths, setMaxMonths] = useState(1);

    useEffect(() => {
        axios
            .get("/get_hatchery_products")
            .then((response) => {
                const productsData = response.data.products;
                setProducts(productsData);
                setSelectedProduct(productsData[0]);
                const maxMonths = Math.max(...productsData.map(product => Number(product.months)));
                setMaxMonths(maxMonths);
                setIsLoading(false);
            })
            .catch(() => {
                toast({
                    variant: "destructive",
                    title: "Error",
                    description: "Error loading products",
                })
            });
    }, []);

    const handleSelectProduct = (selectedProductId: string) => {
        const product = products.find((p) => p.id === selectedProductId);
        if (product) {
            setSelectedProduct(product);
            setDisplayCheckoutForm(false);
        }
    };

    const displayCheckoutFormLogic = () => {
        if (selectedProduct) {
            setDisplayCheckoutForm(true);
            onSelectProduct(selectedProduct);
        }
    };

    const returnToDashboard = () => {
        window.location.href = "/dashboard";
    };

    let checkout_form = null;
    if (displayCheckoutForm) {
        checkout_form = <WrappedCheckoutForm price_id={selectedProduct?.priceID} />;
    }

    if (isLoading) {
        return <div className={"purchase-container"} />;
    }

    const handleMonthIncrement = (increment: number) => {

        const newNumberOfMonths = numberOfMonths + increment;

        const matchingProduct = products.find((product) => product.months === String(newNumberOfMonths));

        if (matchingProduct) {
            setNumberOfMonths(newNumberOfMonths);
            handleSelectProduct(matchingProduct.id);
        } else {
            toast({
                variant: "destructive",
                title: "Invalid selection",
                description: "No product available for this number of months.",
            });
        }
    };

    return (
        <div className={"w-full flex flex-col items-center gap-y-12 h-full min-h-screen"}>
            <div className={"w-full grid px-8 py-6 grid-cols-2 items-center"}>
                <a href="/dashboard">
                    <div className={"size-12 flex items-center"}>
                        <img className={"size-fit"}
                             src={companyConfig.company_logo_navbar}
                             alt="Company Logo"/>
                    </div>
                </a>
                <div className={"justify-end flex"}>
                    <Button plain onClick={() => window.history.back()} className={"cursor-pointer"}>
                        <XMarkIcon/>
                    </Button>
                </div>
            </div>
            <div className={'flex flex-col gap-6'}>
                <div className={"flex flex-col gap-y-4 text-contentPrimary text-center"}>
                    <p className={"font-light font-ppe-editorial-new-light text-[40px] leading-10"}>Today is an
                        important
                        day.</p>
                    <p className={"font-inter font-medium text-xs"}>Access begins immediately after payment.</p>
                </div>
                <div className={'flex flex-row gap-4 items-center justify-center'}>
                    <p className={'text-contentSecondary text-xs font-medium font-inter'}>
                        Select # months
                    </p>
                    <div className={'border border-borderOpaque flex flex-row rounded-lg p-2 gap-6 items-center text-contentTertiary'}>
                        <Button outline disabled={numberOfMonths <= 1} onClick={() => handleMonthIncrement(-1)} className={'bg-backgroundPrimary'}><MinusIcon
                            className={'size-4'}/></Button>
                        <p className={'text-contentPrimary font-medium text-base'}>{numberOfMonths}</p>
                        <Button outline disabled={numberOfMonths >= maxMonths}  onClick={() => handleMonthIncrement(1)}
                                className={'bg-backgroundPrimary'}><PlusIcon className={'size-4'}/></Button>
                    </div>
                </div>
                <div className="flex flex-row gap-x-12 justify-center">
                    <FreeProductCard onClick={returnToDashboard}/>
                    <ProductSelectCard selectedProduct={selectedProduct} onClick={displayCheckoutFormLogic}/>
                    <AltusProductCard onClick={() => window.location.href = 'https://www.altusoneeducation.com.au/courses'} />
                </div>
                <div className="mt-4 h-full">{checkout_form}</div>
            </div>
        </div>
    );
};

export default PurchasePage;

