import React from 'react'

const PoweredByHatchery = () => {
    return (
        <>
            <a href={'https://www.insidehatchery.com/'}>
                <p className={'text-contentTertiary font-normal font-inter'}>
                    Powered by Hatchery
                </p>
            </a>
        </>
    )
}
export default PoweredByHatchery
