import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import { connect } from "react-redux";
import axios from "axios";
import { Button } from "../primitives/button";
import { CompanyConfigContext } from "../../CompanyConfigContextProvider";
import PrivacyPolicy from "../fixedelements/PrivacyPolicy";
import { Input } from "../primitives/input";
import { useToast } from "../ui/use-toast";
import AbsoluteLogo from "../fixedelements/AbsoluteLogo";

const ResendActivation: React.FC = () => {
    const [email, setEmail] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [status, setStatus] = useState<string>("");
    const navigate = useNavigate(); // Use useNavigate instead of useHistory
    const companyConfig = useContext(CompanyConfigContext);
    const { toast } = useToast();

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailError("");
        setEmail(e.target.value);
    };

    const onResendClick = async () => {
        setEmailError("");
        setStatus("");
        const lowerCaseEmail = email.toLowerCase();
        const userData = {
            email: lowerCaseEmail,
        };

        try {
            const response = await axios.post("/api/v1/check_account_activation/", userData);
            if (response.data.detail === "Account is active") {
                setStatus("already-active");
            } else {
                await axios.post("/api/v1/users/resend_activation/", userData);
                toast({
                    title: "Success",
                    description: "Activation email sent. Please check your email.",
                });
                navigate("/login?email=" + email); // Use navigate instead of history.push
            }
        } catch (error) {
            if (error.response && Object.prototype.hasOwnProperty.call(error.response.data, "email")) {
                setEmailError(error.response.data["email"]);
            } else {
                setStatus("error");
            }
        }
    };

    const errorAlert = (
        <div className="rounded-2xl bg-backgroundTertiary text-contentPrimary p-3 border border-negative my-2">
            <strong>Problem during activation email send </strong>
            <p>Please try again or contact service support for further help.</p>
        </div>
    );

    const successAlert = (
        <div className="rounded-2xl bg-backgroundTertiary text-contentPrimary p-3 border border-positive my-2">
            <strong>Email sent </strong>
            <p>We sent you an email with an activation link. Please check your email.</p>
            <p>Please try again or contact us if you do not receive it within a few minutes.</p>
            <p>
                Return to the <a className={"text-accent text-lg font-medium"} href="/">Login page</a>
            </p>
        </div>
    );

    const form = (
        <div className={"flex flex-col justify-center items-center gap-y-4"}>
            <div className={"max-w-[316px]"}>
                <p className="text-sm font-normal leading-[18px] text-stone-400 text-center text-wrap">
                    Your account is inactive. Please activate your account by clicking the activation link in the email.
                </p>
            </div>
            <div className={'w-[320px] h-12'}>
                <Input
                    className={`${emailError ? "is-invalid" : ""}`}
                    type="text"
                    name="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={onChange}
                    onKeyPress={(event) => {
                        if (event.key === "Enter") {
                            event.preventDefault();
                            event.stopPropagation();
                            onResendClick();
                        }
                    }}
                />
            </div>
            {emailError && <div className="">{emailError}</div>}
            <Button color={'primary'} className={'w-[320px] h-12'} onClick={onResendClick}>
                Send activation email
            </Button>
        </div>
    );

    let alert: React.ReactNode = null;
    if (status === "error") {
        alert = <>{errorAlert}</>;
    } else if (status === "success") {
        alert = <>{successAlert}</>;
    } else if (status === "already-active") {
        alert = (
            <div className="rounded-2xl bg-backgroundTertiary text-contentPrimary p-3 border border-info my-2">
                <strong>Account is already active</strong>
                <p>Your account is already activated. Please proceed to <a className={"text-accent text-lg font-medium"} href="/">Login page</a>.
                </p>
            </div>
        );
    }

    return (
        <div className="h-full flex flex-col items-center bg-hatcheryGrey">
            <AbsoluteLogo />
            <div className="flex justify-center items-center align-middle mx-20 h-full w-full">
                <div className="flex-col flex gap-y-3 items-center">
                    <div className="text-black text-[40px] font-light font-ppe-editorial-new-light leading-10 px-10 flex justify-center">
                        <p>Resend Activation Email</p>
                    </div>
                    <div>{alert}</div>
                    <div>{status !== "success" && form}</div>
                </div>
                <PrivacyPolicy />
            </div>
        </div>
    );
};

export default connect()(ResendActivation);
