import React, { useEffect } from "react";
import * as echarts from 'echarts';
import axios from "axios";

interface SectionResultsProps {
    id: number;
}

interface ExamAttempt {
    answered: boolean;
    correct: boolean;
}

export function BinaryStepLine(props: SectionResultsProps) {
    useEffect(() => {
        axios.get('/get_user_session_results', { params: { id: props.id } })
            .then(response => {

                const myChart = echarts.init(document.getElementById(String(props.id)+'stepline'));

                const data = response.data.map(attempt => attempt.correct ? 1 : 0);

                const options = {
                    // title: {
                    //     text: 'Step Line'
                    // },
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis: {
                        type: 'category',
                        data: Array.from({ length: data.length }, (_, i) => i + 1)
                    },
                    yAxis: {
                        type: 'category',
                        data: ['Incorrect', 'Correct']
                    },
                    series: [{
                        type: 'line',
                        data: data,
                        lineStyle: {
                            color: 'blue'
                        }
                    }]
                };

                myChart.setOption(options);

                // Cleanup on unmount
                return () => {
                    myChart.dispose();
                };
            })
            .catch((error) => {
                console.error(error);
                return "unable to find results";
            });
    }, [props]);

    return <div id={String(props.id)+'stepline'} className={"min-w-full min-h-full"} />;
}
