import React from "react";

interface ToggleTagProps {
    active?: boolean;
    disabled?: boolean;
    outline?: boolean;
    onClick?: () => void;
}

export function ToggleTag({ children, ...props }: { children: React.ReactNode } & ToggleTagProps) {
    const { active, disabled, outline } = props;

    return (
        <div onClick={props.onClick} className={`flex flex-row gap-x-2 px-3 items-center justify-center text-contentSecondary text-sm font-medium 
        font-inter rounded-md border ${outline ? 'border-borderOpaque' : 'border-transparent'} 
        ${active ? 'bg-backgroundTertiary' : 'bg-transparent'} h-full min-h-10
        ${disabled ? 'opacity-50 cursor-default' : 'opacity-100 cursor-pointer hover:bg-backgroundTertiary'} hover:text-contentPrimary`}>
            {children}
        </div>
    );
}