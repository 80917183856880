import React, {FC} from "react";
import Root from "./Root";
import {createBrowserRouter} from "react-router-dom";
import Dashboard from "./components/dashboard/Dashboard";
import axios from "axios";
import {PostHogProvider} from "posthog-js/react";
import posthog from "posthog-js";
import CompanyConfigContextProvider from "./CompanyConfigContextProvider";
import {Toaster} from "./components/ui/toaster";
import ProtectedRouteWrapper from "./ProtectedRouteWrapper";
import ExamStateMachine from "./components/exams/ExamStateMachine";
import {ErrorPage} from "./components/ErrorPage";
import {RouterProvider} from "react-router-dom";
import PurchaseStateMachine from "./components/purchase/PurchaseStateMachine";
import SessionViewer from "./components/sessionviewer/SessionViewer";
import ContentPortal from "./components/videos/ContentPortal";
import Vault from "./components/writingtool/Vault";
import ExamComplete from "./components/exams/ExamComplete";
import OnboardingStateMachine from "./components/onboarding/OnboardingStateMachine";
import ExamHistory from "./components/exams/ExamHistory";
import ExamResultsWrapper from "./components/exams/ExamResultsWrapper";
import Community from "./components/Community";
import CompanyPrivacyPolicy from "./components/CompanyPrivacyPolicy";
import Signup from "./components/account/Signup";
import PaymentSuccessful from "./components/membership/PaymentSuccessful";
import Login from "./components/login/Login";
import ResetPasswordConfirm from "./components/account/ResetPasswordConfirm";
import ResetPassword from "./components/account/ResetPassword";
import ActivateAccount from "./components/account/ActivateAccount";
import ResendActivation from "./components/account/ResendActivation";
import {SessionComplete} from "./components/sessionviewer/SessionComplete";
import {NavbarProvider} from "./NavbarProvider";
import {NavbarLayout} from "./layouts/NavbarLayout";
import DataRoom from "./components/dataroom/DataRoom";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import Sidebar from "./sidebar/Sidebar";
import WritingToolLayout from "./layouts/WritingToolLayout";
import LearningSessionHistory from "./components/dataroom/LearningSessionHistory";

console.log("window.location.origin: " + window.location.origin);

if (window.location.origin === "http://localhost:3000") {
    axios.defaults.baseURL = "http://127.0.0.1:8000";
} else if (window.location.origin === "https://altusone.insidehatchery.com") {
    axios.defaults.baseURL = "https://backend.altusone.insidehatchery.com/";
    posthog.init("phc_Edn0wHPLmUrirl4FMGEdsIf2CDHjSpzmKofUExc5h8y’", {
        api_host: "https://app.posthog.com",
        opt_in_site_apps: true,
    });
} else if (window.location.origin === "https://dmf6i20m4jzel.cloudfront.net") {
    axios.defaults.baseURL = "https://test.hatchery-tech.net/";
} else if (window.location.origin === "https://learn.insidehatchery.com") {
    axios.defaults.baseURL = "https://prod.hatchery-tech.net/";
    posthog.init("phc_utNyyx8yYhtudRcOYTD8FkLS51xhuLHo65H8LCrsixr", {
        api_host: "https://app.posthog.com",
        opt_in_site_apps: true,
    });
} else {
    axios.defaults.baseURL = window.location.origin.replace("3000", "8000");
}

export function getEnv() {
    if (window.location.origin === "http://localhost:3000") {
        return "local";
    }
    if (window.location.origin === "https://dmf6i20m4jzel.cloudfront.net") {
        return "dev";
    }
    if (window.location.origin === "https://learn.insidehatchery.com") {
        return "prod";
    }
    return "local";
}


const queryClient = new QueryClient()


const App: FC = () => {

    const router = createBrowserRouter([
        {
            path: "/",
            element: <NavbarLayout Sidebar={Sidebar}/>,
            errorElement: <ErrorPage/>,
            children: [
                {
                    path: "dashboard",
                    element: <ProtectedRouteWrapper component={Dashboard}/>,
                },
                {
                    path: "",
                    element: <ProtectedRouteWrapper component={Dashboard}/>,
                },
                {
                    path: "exams",
                    element: <ProtectedRouteWrapper component={ExamStateMachine}/>,
                },
                {
                    path: "content_portal",
                    element: <ProtectedRouteWrapper component={ContentPortal}/>,
                },
                {
                    path: "vault",
                    element: <ProtectedRouteWrapper component={Vault}/>,
                },
                {
                    path: "exam_history",
                    element: <ProtectedRouteWrapper component={ExamHistory}/>,
                },
                {
                    path: "community",
                    element: <ProtectedRouteWrapper component={Community}/>,
                },
                {
                    path: "company_privacy_policy",
                    element: <CompanyPrivacyPolicy/>,
                },
                {
                    path: "learning_session_history",
                    element: <LearningSessionHistory/>,
                },
            ],
        },
        {
            path: "exam_results",
            element: <NavbarLayout/>,
            children: [
                {
                    path: "",
                    element: <ProtectedRouteWrapper component={ExamResultsWrapper}/>,
                },
            ],
        },
        {
            path: "writing_tool",
            element: <WritingToolLayout/>,
            children: [
                {
                    path: "vault",
                    element: <ProtectedRouteWrapper component={Vault}/>,
                },
            ],
        },
        {
            path: "exam_complete",
            element: <ProtectedRouteWrapper component={ExamComplete}/>,
        },
        {
            path: "/onboarding",
            element: <ProtectedRouteWrapper component={OnboardingStateMachine}/>,
        },
        {
            path: "/signup",
            element: <Signup/>,
        },
        {
            path: "/login",
            element: <Login/>,
        },
        {
            path: "/session_complete",
            element: <SessionComplete/>,
        },
        {
            path: "/current_session",
            element: <ProtectedRouteWrapper component={SessionViewer}/>,
        },
        {
            path: "/resend_activation",
            element: <ResendActivation/>,
        },
        {
            path: "/activate/:uid/:token",
            element: <ActivateAccount/>,
        },
        {
            path: "/send_reset_password",
            element: <ResetPassword/>,
        },
        {
            path: "/reset_password/:uid/:token",
            element: <ResetPasswordConfirm/>,
        },
        {
            path: "/payment_success",
            element: <ProtectedRouteWrapper component={PaymentSuccessful}/>,
        },
        {
            path: "/purchase_page",
            element: <ProtectedRouteWrapper component={PurchaseStateMachine}/>,
        },
        {
            path: "/data_room",
            element: <ProtectedRouteWrapper component={DataRoom}/>,
        },
    ]);

    return (
        <Root>
            <QueryClientProvider client={queryClient}>
                <CompanyConfigContextProvider>
                    <PostHogProvider client={posthog}>
                        <NavbarProvider>
                            <div className="h-full w-full flex flex-column font-inter">
                                <Toaster/>
                                <div className="flex flex-col w-full">
                                    <div className="h-full w-full">
                                        <RouterProvider router={router}/>
                                    </div>
                                </div>
                            </div>
                        </NavbarProvider>
                    </PostHogProvider>
                </CompanyConfigContextProvider>
            </QueryClientProvider>
        </Root>
    );
};

export default App;
