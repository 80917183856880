import React from 'react'

const ErrorScreen = () => {
    return (
        <div className={"flex justify-center items-center"}>
            <p className={"p-2 text-center"}>
                Waiting for more students to take this exam before we can show you full results. Please check back later
                through your exam history.
            </p>
        </div>
    )
}
export default ErrorScreen
