

const initialState = {
  skills: []
};

export const skillReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SKILLS":
      return {
        ...state,
        skills: action.payload
      };
    default:
      return state;
  }
};
