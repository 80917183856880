import React, {useState} from "react";
import {ErrorBoundary} from "react-error-boundary";
import {ParentSize} from "@visx/responsive";
import CohortCadence from "./CohortCadence";
import errorScreen from "./ErrorScreen";


interface CadenceResultsProps {
    sectionResults: Record<string, SectionResults>;
    examResults: ExamResultsType;
}

export function CadenceResults(props: CadenceResultsProps) {
    const [isValidData, setIsValidData] = useState(true)

    function relativeExamSpeed (sectionResults: Record<string, SectionResults>){
        // this function returns whether the line of best fit for all combined sections has a positive (true) or negative gradient
        const allXValues: number[] = [];
        const allYValues: number[] = [];


        Object.keys(sectionResults).forEach((sectionName) => {
            const section = sectionResults[sectionName];
            if ((section.question_durations.filter(duration => duration !== null).length > 3)) {
                section.question_durations.forEach((duration, index) => {
                    allXValues.push(index);
                    allYValues.push(duration);
                });
            } else {
                setIsValidData(false)
            }
        });

        const sumXY = allXValues.reduce((acc, x, index) => acc + x * allYValues[index], 0);
        const sumX = allXValues.reduce((acc, x) => acc + x, 0);
        const sumY = allYValues.reduce((acc, y) => acc + y, 0);
        const sumXSquare = allXValues.reduce((acc, x) => acc + x * x, 0);
        const gradient = (allXValues.length * sumXY - sumX * sumY) / (allXValues.length * sumXSquare - sumX * sumX);
        // TODO: determine the threshold for a 'steady' pace
        return gradient > 0;

    }

    return (
        <div className={"flex flex-wrap gap-3 max-w-[900px] w-full h-full"}>
            <div
                className={"border-borderInverseOpaque border-[.5px] rounded-lg flex flex-col w-full"}>
                <div
                    className={"border-borderInverseOpaque bg-green-200 border-b-[.5px] text-contentPrimary rounded-t-lg px-6 text-lg font-sf-pro leading-[28px] py-1 text-left"}>
                    {isValidData ?
                        <p>
                            You
                            <b>&nbsp;{relativeExamSpeed(props.sectionResults) ? "sped up" : "slowed down"} </b>
                            as the exam progressed
                        </p>
                        :
                        <p className={"text-left"}>
                            Not enough questions completed to provide feedback.
                        </p>
                    }

                </div>
                <div className={"h-[400px] bg-emerald-50 rounded-b-lg"}>
                    {isValidData ?
                        <ErrorBoundary fallback={errorScreen()}>
                            <ParentSize>{({width, height}) => <CohortCadence width={width}
                                                                                      height={height}
                                                                                      sectionResults={props.sectionResults}
                                                                             examResults={props.examResults}
                            />}</ParentSize>
                        </ErrorBoundary>
                        :
                        <p className={"p-2 text-center"}>If you believe this error was given mistakenly, please contact support.</p>
                    }
                </div>
            </div>
        </div>
    );
}
