import React, {useEffect, useState} from "react";
import {ArrowLeftIcon, CheckIcon, XMarkIcon} from "@heroicons/react/16/solid";
import {Field} from "../primitives/fieldset";
import {Listbox, ListboxLabel, ListboxOption} from "../primitives/listbox";
import axios from "axios";
import {Button} from "../primitives/button";


interface QuestionResultsProps {
    sectionResults: Record<string, SectionResults>;
    sectionSwap: () => void;
}

interface ExamAttempt {
    answered: boolean;
    correct: boolean;
    answer: string;
    question: {
        question_text: string;
        correct_answer: string;
        correct_answer_image?: string;
        correct: boolean;
        solution: string;
        solution_image?: string;
    };
}


export function QuestionResults(props: QuestionResultsProps) {
    const [activeSessionReview, setActiveSessionReview] = useState<number>(null)
    const [sessionResults, setSessionResults] = useState<ExamAttempt[]>([]);
    const [sortBy, setSortBy] = useState<string>("Question Order");
    const [initialOrder, setInitialOrder] = useState<ExamAttempt[]>([]);


    const handleSortBy = (value: string) => {
        setSortBy(value);
    };

    const filterQuestions = (questions: ExamAttempt[]) => {
        switch (sortBy) {
            case "Incorrect":
                return questions.filter(question => !question.correct);
            case "Correct":
                return questions.filter(question => question.correct);
            case "Question Order":
            default:
                return initialOrder;
        }
    };

    const handleSection = (id: number, index: number) => {
        axios.get('/get_user_session_results', { params: { id: id } })
            .then(response => {
                setSessionResults(response.data);
                setActiveSessionReview(index);
            })
            .catch((error) => {
                console.error(error);
                return "unable to find results";
            });
    };

    const filteredSessionResults = filterQuestions(sessionResults);

    useEffect(() => {
        setInitialOrder([...sessionResults]);
    }, [sessionResults]);

    return (
        <div className={"w-full"}>
            <div className={"px-7 py-8 flex flex-col w-full"}>
                <div className={`flex flex-row gap-x-2`}>
                    <Button onClick={props.sectionSwap}>
                        <ArrowLeftIcon/> Go back
                    </Button>
                    <Field>
                        <Listbox name="section" placeholder={"Select a section to review"}
                                 value={Object.keys(props.sectionResults)[activeSessionReview]}
                                 onChange={(value) => setActiveSessionReview(Object.keys(props.sectionResults).indexOf(value))}>
                            {Object.keys(props.sectionResults).map((sectionName, index) => (
                                <ListboxOption key={index} value={sectionName}
                                               onClick={() => handleSection(props.sectionResults[sectionName].id, index)}>
                                    <ListboxLabel>{sectionName}</ListboxLabel>
                                </ListboxOption>
                            ))}
                        </Listbox>
                    </Field>
                    <Field>
                        <Listbox name="sort" value={sortBy} onChange={handleSortBy}>
                            <ListboxOption value="Question Order">
                                <ListboxLabel>Chronological Order</ListboxLabel>
                            </ListboxOption>
                            <ListboxOption value="Correct">
                                <ListboxLabel>Correct</ListboxLabel>
                            </ListboxOption>
                            <ListboxOption value="Incorrect">
                                <ListboxLabel>Incorrect</ListboxLabel>
                            </ListboxOption>
                        </Listbox>
                    </Field>
                </div>
            </div>
            <div className={"flex flex-col gap-y-6 w-full items-center"}>
                <div className={"flex flex-col gap-y-4 w-[696px] items-center"}>
                    <div className={"flex flex-col gap-y-3"}>
                        {filteredSessionResults.map((question, qIndex) => (
                            <div key={qIndex}
                                 className={"flex flex-col border border-borderOpaque rounded-2xl"}>
                                <div
                                    className={`w-full grid grid-cols-2 font-semibold text-md px-3 py-1 rounded-t-2xl items-center text-white ${question.correct ? 'bg-positive' : 'bg-negative'}`}>
                                    <p>Question {qIndex + 1}</p>
                                    <div className={"w-full flex justify-end"}>
                                        {question.correct ?
                                            <CheckIcon
                                                className={'size-5 rounded-full bg-white text-positive'}/>
                                            :
                                            <XMarkIcon
                                                className={'size-5 rounded-full bg-white text-negative'}/>
                                        }
                                    </div>
                                </div>
                                <div className={"flex flex-col px-3 py-2 gap-y-3"}>
                                    <div
                                        className={"text-xl text-center border-b pb-3 border-borderOpaque"}>
                                        <p dangerouslySetInnerHTML={{__html: question.question.question_text}}/>
                                    </div>
                                    <div className={"grid grid-cols-2 gap-x-4"}>
                                        <div className={"flex flex-col"}>
                                            <p className={"font-semibold"}>You answered:</p>
                                            <p dangerouslySetInnerHTML={{__html: question.answer}}/>
                                        </div>
                                        <div className={"flex flex-col justify-end"}>
                                            <p className={"font-semibold"}>
                                                The correct answer is:
                                            </p>
                                            <p dangerouslySetInnerHTML={{__html: question.question.correct_answer}}/>
                                        </div>
                                    </div>
                                    <div className={"flex flex-col mt-2"}>
                                        <p className={"font-semibold "}>Solution:</p>
                                        <p dangerouslySetInnerHTML={{__html: question.question.solution}}/>
                                        {question.question.solution_image &&
                                            <img alt={"solution img"} src={question.question.solution_image}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
