import React from "react";

function PrivacyPolicy() {

  return (
      <div className={"absolute bottom-6 flex justify-center"}>
          <a
              className={"text-accent font-sf-pro font-normal leading-[20px] cursor-pointer"}
              target="_blank"
              // href={
              //     "https://insidehatchery.notion.site/Privacy-Policy-8196adf791fb41919c6a925816056dda?pvs=4"
              // }
              onClick={() => (window.location.href = "/company_privacy_policy")}
              rel="noreferrer"
          >
              Privacy Policy
          </a>
      </div>
  );
}


export default PrivacyPolicy