import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom"; // Use useNavigate from react-router-dom
import { connect } from "react-redux";
import axios from "axios";
import { Input } from "../primitives/input";
import { Button } from "../primitives/button";
import { Link } from "../primitives/link";
import PrivacyPolicy from "../fixedelements/PrivacyPolicy";
import { CompanyConfigContext } from "../../CompanyConfigContextProvider";
import AbsoluteLogo from "../fixedelements/AbsoluteLogo";

const ResetPassword: React.FC = () => {
    const [email, setEmail] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [status, setStatus] = useState<string>("");
    const companyConfig = useContext(CompanyConfigContext);
    const navigate = useNavigate(); // Use useNavigate instead of useHistory

    useEffect(() => {
        document.body.style.backgroundColor = "#FFFAF1";
        return () => {
            document.body.style.backgroundColor = null;
        };
    }, []);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const onSendClick = () => {
        setEmailError("");

        const lowerCaseEmail = email.toLowerCase();
        const userData = {
            email: lowerCaseEmail,
        };

        axios
            .post("/api/v1/users/reset_password/", userData)
            .then(() => {
                setStatus("success");
                navigate(`/login?email=${email}&reset_sent=true`); // Use navigate instead of history.push
            })
            .catch((error) => {
                if (error.response && Object.prototype.hasOwnProperty.call(error.response.data, "email")) {
                    setEmailError(error.response.data["email"]);
                } else {
                    setStatus("error");
                }
            });
    };

    const errorAlert = (
        <div className="alert alert-danger">
            <strong>Problem during reset password email send</strong>
            Please try again or contact service support for further help.
        </div>
    );

    const form = (
        <div className={"flex flex-col gap-y-6 justify-center w-[320px]"}>
            {status === "error" && (
                <div className={"bg-red-800 rounded-[4px] text-white"}>
                    Your account is inactive. Please activate your account by clicking the activation link in the email.
                </div>
            )}
            <div className={"flex flex-col gap-y-2"}>
                <div className={"h-12 w-[320px]"}>
                    <Input
                        className={`${emailError ? "border-negative" : ""}`}
                        type="text"
                        name="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={onChange}
                        onKeyPress={(event) => {
                            if (event.key === "Enter") {
                                event.preventDefault();
                                onSendClick();
                            }
                        }}
                    />
                </div>
                {emailError && <div className="invalid-feedback">{emailError}</div>}
            </div>
            <Button className={"h-12"} color={"primary"} onClick={onSendClick} style={{ width: "100%" }}>
                Continue
            </Button>
        </div>
    );

    return (
        <div className="h-full flex flex-col items-center bg-hatcheryGrey">
            <AbsoluteLogo />
            <div className={"flex justify-center items-center align-middle mx-20 h-full w-full"}>
                <div className={"flex-col flex gap-y-6"}>
                    <div className={"flex flex-col gap-y-4 justify-center items-center"}>
                        <div className="text-center text-black text-[40px] font-light font-ppe-editorial-new-light leading-10 px-10">
                            Reset your password
                        </div>
                        <div className={"max-w-[316px]"}>
                            <p className="text-sm font-normal leading-[18px] text-stone-400 text-center text-wrap">
                                Enter your email address and we will send you instructions to reset your password.
                            </p>
                        </div>
                    </div>
                    <div className={"flex justify-center text-center text-sm p-2"}>{status !== "success" && form}</div>
                    <div className={"flex flex-row gap-x-1 justify-center"}>
                        <Link href="/login">
                            <p className={"text-accent font-sf-pro text-sm font-medium leading-[18px]"}>Return to log in</p>
                        </Link>
                    </div>
                </div>
                <PrivacyPolicy />
            </div>
        </div>
    );
};

export default connect()(ResetPassword);
