import React, {useEffect, useState} from "react";
import {Button} from "../primitives/button";
import axios from "axios";
import {Accordion, AccordionContent, AccordionItem, AccordionTrigger} from "../ui/accordion";
import {useNavigate} from "react-router-dom";


interface WritingResultsProps {
    index: number;
    essay_id: number;
}

type Annotation = {
    start: number,
    end: number,
    annotation_category: string,
    feedback: string,
    explanation: string,
    hidden: boolean,
}

const AnnotationCategories: AnnotationCategory[] = [
    {
        name: "Idea Development",
        color: "rgba(249, 139, 218, 1)",
    },
    {
        name: "Argument",
        color: "rgba(243, 32, 32, 1)",
    },
    {
        name: "Organisation",
        color: "rgba(0, 71, 255, 1)",
    },
    {
        name: "Expression",
        color: "rgba(252, 186, 18, 1)",
    },
    {
        name: "Evidence & Analysis",
        color: "rgba(51, 192, 48, 1)",
    }
]

export function WritingResults (props: WritingResultsProps) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const handleGoToEssay = () => {
        const essayId = props.essay_id;
        navigate(`/writing_tool/vault${essayId ? `?essay_id=${essayId}` : ''}`);
    };
    const [sortedAnnotations, setSortedAnnotations] = useState<Record<string, Annotation[]>>({
        "Idea Development": [],
        "Argument": [],
        "Organisation": [],
        "Expression": [],
        "Evidence & Analysis": [],
    });

    const getAnnotationsByEssayId = async (essayId) => {
        try {
            const response = await axios.get(`/api/v1/annotations/`, {
                params: {
                    essay_id: essayId
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching annotations:', error);
            throw error;
        }
    };

    useEffect(() => {
        getAnnotationsByEssayId(props.essay_id)
            .then(annotations => {
                if (annotations.length > 0) {
                    setLoading(false)
                }
                sortAnnotations(annotations);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, [props.essay_id]);

    const sortAnnotations = (annotations) => {
        const sorted = {
            "Idea Development": [],
            "Argument": [],
            "Organisation": [],
            "Expression": [],
            "Evidence & Analysis": [],
        };

        annotations.forEach(annotation => {
            if (annotation.annotation_category && sorted[annotation.annotation_category]) {
                sorted[annotation.annotation_category].push(annotation);
            }
        });
        setSortedAnnotations(sorted);
    };

    return (
        <div className={"flex flex-wrap gap-3 max-w-[900px] w-full h-full"}>
            <div className={"border-borderInverseOpaque border-[.5px] rounded-lg flex flex-col w-full"}>
                <div
                    className={"flex items-center flex-row border-borderInverseOpaque bg-pink-600/20 border-b-[.5px] text-contentPrimary rounded-t-lg px-6 text-lg font-sf-pro leading-[28px] py-1 text-left"}>
                    {loading ?
                        <div className={"flex grow"}>Your writing piece is currently being processed. Come back in a
                            couple minutes.</div>
                        :
                        <div className={"flex grow"}>Your essay #{props.index} feedback:</div>
                    }
                    <div className={"flex shrink max-h-6"}>
                    <Button onClick={handleGoToEssay}>Go to Writer</Button>
                    </div>
                </div>
                <div className={"h-full bg-pink-50 rounded-b-lg p-6"}>
                    {loading ? (
                        <div>Loading...</div>
                    ) : (
                        <Accordion type="single" collapsible className="w-full">
                            {AnnotationCategories.map(category => (
                                    <AccordionItem key={category.name} value={`${category.name}`}>
                                        <AccordionTrigger>
                                            <div key={category.name} className={"flex flex-col"}>
                                                <div className={"flex flex-row gap-x-2 leading-none font-inter text-base"}>
                                                    <div style={{backgroundColor: category.color}}
                                                         className={`w-[6px] h-[14px] rounded-full`}></div>
                                                    <p className={"text-contentPrimary font-semibold"}>{category.name}</p>
                                                    <p className={"text-contentTertiary font-medium"}>{sortedAnnotations[category.name].length} items</p>
                                                </div>
                                            </div>
                                        </AccordionTrigger>
                                        <AccordionContent>
                                            <ul className={"list-disc list-inside"}>
                                                {sortedAnnotations[category.name].map(annotation => (
                                                    <li key={annotation.start}>
                                                        {annotation.feedback}
                                                    </li>
                                                ))}
                                            </ul>
                                        </AccordionContent>
                                    </AccordionItem>
                                )
                            )}
                        </Accordion>
                    )}
                </div>
            </div>
        </div>
    );
}
